<template>
    <v-sheet class="d-flex">
        <v-sheet class="pr-7 pt-4">
            <v-icon size="20" color="blue lighten-2">mdi-arrow-right-bottom</v-icon>
        </v-sheet>
        <!-- 댓글 목록 -->
        <v-sheet width="100%">
            <!-- 목록 -->
            <v-sheet
                v-for="item in comment_list" :key="item.id"
                class="pt-3 pb-4 my-1"
                style="border-top:1px solid #eee;"
            >
                <!-- 블라인드됨 -->
                <v-sheet 
                    v-if="item.status=='블라인드됨'"
                    class="py-3"
                    style="position:relative"
                >
                    <p class="text-caption text-center my-2">해당 댓글은 관리자에 의해 블라인드 처리되었습니다.</p>
                    <v-btn
                        v-if="$store.state.type=='관리자'"
                        style="position:absolute; top:4px; right:4px;"
                        class="rounded-10"
                        color="#23D2E2"
                        small
                        dark
                        depressed
                        @click="unblind_subcomment(item)"
                    >   
                        블라인드 해제
                    </v-btn>
                </v-sheet>

                <!-- 공개 -->
                <v-sheet v-else>
                    <!-- 상단 -->
                    <v-sheet
                        class="d-flex align-center"
                        :class="$vuetify.breakpoint.xs? 'mb-0' : 'mb-2'"
                    >
                        <!-- 프로필 -->
                        <Avatar :user="item.user_id"/>

                        <!-- 닉네임 -->
                        <p
                            class="mx-2 my-0 font-weight-bold"
                            style="font-size:13px; line-height:18px !important;"
                        >
                            {{item.nickname}}
                        </p>

                        <!-- 작성자일 시 -->
                        <v-icon
                            v-if="item.user_id == writer"
                            size="18"
                            class="ml-1 mr-2 mb-2px"
                            color="blue"
                        >
                            mdi-check-circle
                        </v-icon>

                        <!-- 댓글 작성일 -->
                        <font
                            v-if="!$vuetify.breakpoint.xs"
                            class="mr-2"
                            style="font-size:13px; color:#ADAFCA; line-height:18px;"
                        >
                            {{ dayjs(item.created).format(dayjs().isSame(item.created, 'day') ? 'a hh:mm' : 'YYYY. MM. DD').replace('am', '오전').replace('pm', '오후') }}
                        </font>

                        <!-- 댓글 삭제하기 -->
                        <v-sheet
                            v-if="item.user_id==$store.state.user_id || $store.state.type=='관리자'"
                            title="댓글 삭제하기"
                            width="18"
                            height="18"
                            class="mx-1"
                            style="cursor:pointer;"
                            @click="delete_subcomment(item)"
                        >
                            <v-img contain src="@/assets/board/delete.svg"></v-img>
                        </v-sheet>

                        <!-- 댓글 수정하기 -->
                        <v-sheet
                            v-if="item.user_id==$store.state.user_id"
                            title="댓글 수정하기"
                            width="18"
                            height="18"
                            class="mx-1"
                            style="cursor:pointer;"
                            @click="item.isUpdating = !item.isUpdating"
                        >
                            <v-img contain src="@/assets/board/update.svg"></v-img>
                        </v-sheet>

                        <!-- 블라인드 처리 -->
                        <v-sheet
                            v-if="$store.state.type=='관리자'"
                            title="댓글 블라인드"
                            width="18"
                            height="18"
                            class="mx-1"
                            style="cursor:pointer;"
                            @click="blind_subcomment(item)"
                        >
                            <v-img contain src="@/assets/board/blind.svg"></v-img>
                        </v-sheet>

                        <!-- 댓글 신고하기 -->
                        <v-icon
                            v-if="item.user_id!=$store.state.user_id"
                            v-ripple="false"
                            title="댓글 신고하기"
                            size="21"
                            class="mx-1 pt-1px"
                            color="grey"
                            style="background-color:none; cursor:pointer;"
                            @click="report_comment_open(item)"
                        >
                            mdi-alert-circle
                        </v-icon>

                        <v-spacer></v-spacer>

                        <!-- 좋아요, 싫어요 -->
                        <v-sheet
                            v-if="!$vuetify.breakpoint.xs"
                            class="d-flex justify-center"
                        >
                            <!-- 좋아요 -->
                            <v-btn text class="mr-1 px-2" @click="subcomment_like(item)">
                                <v-img width="26" height="26" contain src="@/assets/board/like.png"></v-img>
                                <span style="font-size:12px;">
                                    {{item.like_count}}
                                </span>
                            </v-btn>

                            <!-- 싫어요 -->
                            <v-btn text class="mr-1 px-2" @click="subcomment_dislike(item)">
                                <v-img width="26" height="26" contain src="@/assets/board/dislike.png"></v-img>
                                <span style="font-size:12px;">
                                    {{item.dislike_count}}
                                </span>
                            </v-btn>
                        </v-sheet>
                    </v-sheet>

                    <!-- 좋아요, 싫어요 (Mobile) -->
                    <v-sheet
                        v-if="$vuetify.breakpoint.xs"
                        class="d-flex mx-3"
                    >
                        <!-- 댓글 작성일 -->
                        <p class="mt-2 mb-0 mr-1" style="font-size:9px; color:#ADAFCA; line-height:20px;">
                            {{ dayjs(item.created).format(dayjs().isSame(item.created, 'day') ? 'a hh:mm' : 'YYYY. MM. DD').replace('am', '오전').replace('pm', '오후') }}
                        </p>

                        <v-spacer></v-spacer>

                        <!-- 좋아요 -->
                        <v-btn text small class="mr-1 px-2" @click="subcomment_like(item)">
                            <v-img width="18" height="18" contain src="@/assets/board/like.png"></v-img>
                            <span style="font-size:12px;">
                                {{item.like_count}}
                            </span>
                        </v-btn>

                        <!-- 싫어요 -->
                        <v-btn text small class="mr-1 px-2" @click="subcomment_dislike(item)">
                            <v-img width="18" height="18" contain src="@/assets/board/dislike.png"></v-img>
                            <span style="font-size:12px;">
                                {{item.dislike_count}}
                            </span>
                        </v-btn>
                    </v-sheet>

                    <!-- 댓글 내용 -->
                    <div
                        v-if="$store.state.is_logined && !item.isUpdating && item.status == '공개'"
                        class="pa-3"
                        style="white-space:pre-line; word-break: break-all; font-size:13px;"
                        v-html="$linkify(item.content)"
                    ></div>

                    <div
                        v-if="$store.state.is_logined && !item.isUpdating && item.status == '드로우'"
                        class="pa-3"
                        style="white-space:pre-line; word-break: break-all; font-size:13px;"
                    >
                        <font v-if="item.content == 'C'">C 커먼 카드가 나왔습니다, 재미 없네.. +50p 획득</font>
                        <font v-if="item.content == 'U'">U 언커먼 카드가 나왔습니다, 이런... +70p 획득</font>
                        <font v-if="item.content == 'R'">R 레어 카드가 나왔습니다, 다음을 기대해보죠. +90p 획득</font>
                        <font v-if="item.content == 'RR'" class="font-weight-medium">RR 더블레어 카드가 나왔습니다, 살짝 아쉽네요. +95p 획득</font>
                        <font v-if="item.content == 'RRR'" class="font-weight-medium" style="color:#4f8a6a;">RRR 트리플레어 카드가 나왔습니다! 나쁘지 않네요. +100p 획득</font>
                        <font v-if="item.content == 'SR'" class="font-weight-medium" style="color:#337ea9;">SR 슈퍼레어 카드가 나왔습니다! 대단해요! +150p 획득</font>
                        <font v-if="item.content == 'HR'" class="font-weight-medium" style="color:#cf75a5;">HR 하이퍼레어 카드가 나왔습니다! 축하드립니다! +200p 획득</font>
                        <font v-if="item.content == 'UR'" class="font-weight-medium" style="color:#cb912f;">말도 안돼! UR 울트라레어 카드가 나왔습니다! +500p 획득</font>
                    </div>

                    <!-- 수정 시 -->
                    <div class="mt-4">
                        <!-- 내용 -->
                        <v-sheet v-if="item.isUpdating" outlined class="d-flex justify-center py-1">
                            <v-textarea
                                hide-details
                                flat
                                solo
                                v-model="item.content"
                                style="font-size:13px;"
                                placeholder="평가 내용을 입력해주세요"
                            ></v-textarea>
                        </v-sheet>

                        <!-- 수정, 취소 버튼 -->
                        <div v-if="item.isUpdating" class="mt-2 mb-4 d-flex justify-end">
                            <v-btn
                                class="rounded-10"
                                width="90"
                                height="35"
                                dark
                                depressed
                                color="primary"
                                @click="subcomment_update_submit(item)"
                            >
                                수정
                            </v-btn>
                            <v-btn
                                class="rounded-10 ml-2"
                                width="90"
                                height="35"
                                dark
                                depressed
                                color="primary"
                                @click="item.isUpdating = false; load()"
                            >
                                취소
                            </v-btn>
                        </div>
                    </div>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- 대댓글 신고하기 Dialog -->
        <v-dialog v-model="dialog.reportComment" width="800" content-class="rounded-15">
            <v-sheet class="py-8 px-12" color="#F8F7FB">
                <!-- 제목 -->
                <p
                    class="text-center font-weight-bold mb-8"
                    style="font-size:20px;"
                >
                    댓글 신고하기
                </p>

                <!-- 신고 댓글 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:110px;">신고 댓글</span>
                        <v-sheet class="text-truncate" style="width:440px;">
                            {{reportComment.reported_content}}
                        </v-sheet>
                    </div>
                </v-sheet>

                <!-- 신고 대상자 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:95px;">신고 대상자</span>
                        {{reportComment.writer}}
                    </div>
                </v-sheet>

                <!-- 신고 항목 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:230px;">신고 항목</span>
                        <v-select
                            style="max-width:220px;"
                            placeholder="신고 항목"
                            dense
                            hide-details
                            solo
                            flat
                            :items="select_list.report"
                            v-model="reportComment.category"
                        >
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 신고 내용 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">신고 내용</span>
                    <v-textarea
                        class="no_under"
                        rows="4"
                        hide-details
                        v-model="reportComment.content"
                        placeholder="내용을 입력해주세요"
                    ></v-textarea>
                </v-sheet>

                <!-- 버튼 -->
                <div class="mt-6 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="#615DFA"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="report_comment_submit()"
                    >
                        신고
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="white"
                        class="font-weight-bold rounded-10"
                        @click="dialog.reportComment=false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
import Avatar from "@/components/Avatar"
import GradeCheck from '@/components/GradeCheck.js'
import PointModule from '@/components/PointModule.js'
import BanModule from '@/components/BanModule.js'

export default {
    props: [
        "comment_id",
        "board_name",
        "title",
        "writer"
    ],

    components: {
        Avatar
    },

    data: () => ({
        comment_list: [],

        reportComment: {
            reported_content: "",
            id: 0,
            writer: "",
            category: "",
            content: ""
        },

        select_list: {
            report: [
                "욕설/비방",
                "도배",
                "홍보/사업성",
                "음란성",
                "분란조장",
                "시세조작",
                "기타"
            ]
        },

        dialog: {
            reportComment: false
        },
    }),

    mounted(){
        // 대댓글 목록
        this.load()
    },

    methods: {
        // 대댓글 목록
        load(){
            this.$http.post('/api/board/read/subcomment/list', {
                params: {
                    comment_id:this.comment_id
                }
            }).then((res) => {
                this.comment_list = res.data
                this.$emit("updated")
            })
        },

        // 대댓글 삭제
        delete_subcomment(item){
            if(confirm("정말 해당 댓글을 삭제하시겠습니까?")){
                this.$http.post('/api/board/read/subcomment/delete', {
                    params: {
                        id: item.id
                    }
                }).then(() => {
                    alert('댓글이 삭제되었습니다.')

                    GradeCheck.compare(this.$store.state.user_id, 'comment', -1)
                    .then(() => {
                        // 포인트 적립 취소
                        if(this.$route.query.board_group=='커뮤니티' && this.board_name=='인증/카드자랑'){
                            PointModule.amountCheck('카드자랑', '댓글').then((res) => {
                                PointModule.Update(this.$store.state.user_id, -res.data[0].amount)
                                PointModule.Record(this.$store.state.user_id, '카드자랑', '댓글', this.title, -res.data[0].amount)
                            })
                        }else if(this.$route.query.board_group=='공략/배틀'){
                            PointModule.amountCheck('공략게시판', '댓글').then((res) => {
                                PointModule.Update(this.$store.state.user_id, -res.data[0].amount)
                                PointModule.Record(this.$store.state.user_id, '공략게시판', '댓글', this.title, -res.data[0].amount)
                            })
                        }else{
                            PointModule.amountCheck('일반게시판', '댓글').then((res) => {
                                PointModule.Update(this.$store.state.user_id, -res.data[0].amount)
                                PointModule.Record(this.$store.state.user_id, '일반게시판', '댓글', this.title, -res.data[0].amount)
                            })
                        }
                    })

                    // 댓글 목록
                    this.load()
                })
            }
        },

        // 대댓글 수정
        subcomment_update_submit(item){
            this.$http.post('/api/board/read/subcomment/update', {
                params: {
                    id :item.id,
                    content :item.content
                }
            }).then(() => {
                // 댓글 목록
                this.load()
            })
        },

        // 댓글 블라인드 처리
        blind_subcomment(item){
            if(confirm("정말 해당 댓글을 블라인드 처리하시겠습니까?")){
                this.$http.post('/api/board/read/subcomment/blind', {
                    params: {
                        id : item.id
                    }
                }).then(() => {
                    alert('해당 댓글이 블라인드 처리되었습니다')

                    // 댓글 목록
                    this.load()
                })
            }
        },

        // 댓글 블라인드 해제
        unblind_subcomment(item){
            if(confirm("정말 해당 댓글을 블라인드 해제하시겠습니까?")){
                this.$http.post('/api/board/read/subcomment/unblind', {
                    params: {
                        id : item.id
                    }
                }).then(() => {
                    alert('해당 댓글이 블라인드 해제되었습니다')
                    
                    // 댓글 목록
                    this.load()
                })
            }
        },

        // 댓글 추천
        async subcomment_like(item){
            let is_banned = await BanModule.ban_check(this.$store.state.user_id)

            if(is_banned){
                alert("밴 유저는 댓글 추천이 제한됩니다.")
                return
            }

            if(!this.$store.state.is_logined){
                alert("댓글 추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.user_id == item.user_id){
                alert('자신의 댓글에 추천을 할 수 없습니다.')
            }else{
                this.$http.post('/api/board/read/subcomment/like/list', {
                    params: {
                        sub_comment_id: item.id,
                        user_id: this.$store.state.user_id
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("이미 추천한 댓글입니다.")
                    }else{
                        this.$http.post('/api/board/read/subcomment/like', {
                            params: {
                                sub_comment_id: item.id,
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            // 댓글 목록
                            this.load()

                            // 댓글 작성자에게 좋아요 알림
                            this.$http.post('/api/alarm/insert', {
                                params: {
                                    user_id: item.user_id,
                                    sender: this.$store.state.user_id,
                                    type: "comment_liked",
                                    link: this.$route.fullPath
                                }
                            }).then(res => {
                                console.log(res)
                            })
                        })
                    }
                })
            }
        },

        // 댓글 비추천
        async subcomment_dislike(item){
            let is_banned = await BanModule.ban_check(this.$store.state.user_id)

            if(is_banned){
                alert("밴 유저는 댓글 비추천이 제한됩니다.")
                return
            }
            
            if(!this.$store.state.is_logined){
                alert("댓글 비추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.user_id == item.user_id){
                alert('자신의 댓글에 비추천을 할 수 없습니다.')
            }else{
                this.$http.post('/api/board/read/subcomment/dislike/list', {
                    params: {
                        sub_comment_id: item.id,
                        user_id: this.$store.state.user_id
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("이미 비추천한 댓글입니다.")
                    }else{
                        this.$http.post('/api/board/read/subcomment/dislike', {
                            params: {
                                sub_comment_id: item.id,
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            // 댓글 목록
                            this.load()

                            // 댓글 작성자에게 싫어요 알림
                            this.$http.post('/api/alarm/insert', {
                                params: {
                                    user_id: item.user_id,
                                    sender: this.$store.state.user_id,
                                    type: "comment_disliked",
                                    link: this.$route.fullPath
                                }
                            }).then(res => {
                                console.log(res)
                            })
                        })
                    }
                })
            }
        },

        // 대댓글 신고하기 열기
        report_comment_open(item){
            if(!this.$store.state.is_logined){
                alert("신고하기는 로그인 후 이용가능합니다")
            }else {
                this.dialog.reportComment = true
                this.reportComment.id = item.id
                this.reportComment.writer = item.user_id
                this.reportComment.reported_content = item.content
            }
        },

        // 대댓글 신고하기 제출
        report_comment_submit(){
            if(this.reportComment.category == ""){
                alert("신고 항목을 선택해주세요")
            }else if(this.reportComment.content == ""){
                alert("신고 내용을 입력해주세요")
            }else{
                this.$http.post('/api/admin/user/reported/insert', {
                    params: {
                        reporter_user_id: this.$store.state.user_id,
                        reported_user_id: this.reportComment.writer,
                        type: "board_subcomment",
                        reported_id: this.reportComment.id,
                        category: this.reportComment.category,
                        content: this.reportComment.content,
                        link: this.$route.fullPath+"&reported_subcomment_id="+this.reportComment.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        // DB 알림
                        this.$dbAlarm(
                            "신고 - 댓글",
                            this.nickname,
                            this.user_id
                        )
                        
                        alert("신고가 접수되었습니다.")
                        this.dialog.reportComment = false
                    }
                })
            }
        },
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>