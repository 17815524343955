<template>
    <div>
        <!-- 상단 1단 (제목 & 썸네일 스위치) -->
        <v-sheet
            color="transparent"
            class="d-flex align-center justify-space-between rounded-10 py-3 px-3"
        >
            <!-- 제목 -->
            <p
                class="mt-1 mb-0 font-weight-bold"
                style="font-size:16px; letter-spacing:-0.5px; cursor:pointer;"
                @click="loadFirst()"
            >
                {{$route.query.board_name}}
            </p>

            <!-- 썸네일 스위치 -->
            <v-switch
                v-if="$route.query.board_name != '유튜브 게시판'"
                class="font-weight-bold mt-0 custom-switch"
                v-model="thumbnail_switch"
                inset
                :ripple="false"
                hide-details
                :label="thumbnail_switch? '썸네일 ON':'썸네일 OFF'"
            ></v-switch>
        </v-sheet>

        <!-- 상단 2단 (버튼) -->
        <v-sheet
            class="d-flex align-center mobile mb-2 px-3"
            color="#f8f7fb"
        >
            <!-- 카테고리 -->
            <v-autocomplete
                style="height:33px; width:100px;"
                class="rounded-10 mr-2 white"
                dense
                outlined
                hide-details
                placeholder="카테고리"
                :items="select_list.category"
                v-model="category"
                @change="category_selected"
            >
                <template v-slot:append>
                    <v-icon
                        class="pt-2px"
                        color="#424361"
                        size="20"
                    >
                        mdi-chevron-down
                    </v-icon>
                </template>
            </v-autocomplete>
            <v-spacer></v-spacer>

            <!-- 인기글 -->
            <v-btn
                color="#615DFA"
                dark
                depressed
                class="rounded-10 mr-1"
                style="font-size:12px;"
                width="47"
                height="33"
                x-small
                @click="hot()"
            >
                인기글
            </v-btn>

            <!-- 내글 -->
            <v-btn
                color="#ADAFCA"
                dark
                depressed
                class="rounded-10 mr-1"
                style="font-size:12px;"
                width="47"
                height="33"
                x-small
                @click="myPost()"
            >
                내글
            </v-btn>

            <!-- 내댓글 -->
            <v-btn
                color="#ADAFCA"
                dark
                depressed
                class="rounded-10 mr-1"
                style="font-size:12px;"
                width="47"
                height="33"
                x-small
                @click="myComment()"
            >
                내댓글
            </v-btn>

            <!-- 검색 -->
            <v-btn
                color="#23D2E2"
                dark
                depressed
                class="rounded-10 mr-1"
                style="font-size:16px;"
                width="38"
                height="33"
                x-small
                @click="search_switch = !search_switch; keyword = ''"
            >
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <!-- 작성하기 -->
            <v-btn
                color="#615DFA"
                dark
                depressed
                class="rounded-10"
                style="font-size:16px;"
                width="38"
                height="33"
                x-small
                @click="write()"
            >
                <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
        </v-sheet>

        <BannerView
            v-if="$store.state.ads_banner.community"
            type="community"
            width="100%"
            :height="46/360*100+'vw'"
            :round="'rounded-0'"
        />

        <!-- 검색 (열고닫기) -->
        <v-sheet
            v-if="search_switch"
            color="#1DA1F2"
            height="60"
            class="d-flex align-center justify-center px-2 mobile"
            style="position:relative;"
        >
            <!-- 검색 유형 선택 -->
            <v-sheet width="100" class="rounded-10 mr-1 mobile">
                <v-select
                    style="height:33px; width:100px;"
                    class="rounded-10"
                    dense
                    solo
                    flat
                    hide-details
                    :items="select_list.search_type"
                    item-text="name"
                    item-value="value"
                    v-model="search_type"
                >
                    <template v-slot:append>
                        <v-icon
                            class="mt-1"
                            color="#424361"
                            size="20"
                        >
                            mdi-chevron-down
                        </v-icon>
                    </template>
                </v-select>
            </v-sheet>

            <!-- 검색 입력란 -->
            <div
                class="custom_background"
            >
                <v-text-field
                    style="height:38px;"
                    class="rounded-10 mr-2"
                    hide-details
                    dense
                    solo
                    flat
                    placeholder="검색어"
                    v-model="keyword"
                    @keyup.enter="search()"
                ></v-text-field>
            </div>
            <v-btn
                icon
                dark
                depressed
                class="rounded-10 font-weight-bold mr-3"
                @click="search()"
            >
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-icon
                style="position:absolute; top:4px; right:4px;"
                size="18"
                color="white"
                @click="search_switch = !search_switch; keyword = ''"
            >
                mdi-close
            </v-icon>
        </v-sheet>

        <!-- 목록 -->
        <v-sheet
            class="mt-2 pb-4 mb-6"
        >
            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="d-flex mx-auto my-10"
                size="60"
            ></v-progress-circular>

            <!-- 일반 게시판 -->
            <v-data-table
                v-if="!loading && $route.query.board_name != '유튜브 게시판'"
                class="flexible_table mt-1"
                :items="list"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event"
                hide-default-footer
                no-data-text="게시글이 존재하지 않습니다."
            >
                <template v-slot:item="{ item }">
                    <!-- 공지 -->
                    <tr
                        v-if="item.type == '공지'"
                        class="pa-0 ma-0"
                        style="cursor:pointer; border-bottom:1px solid #eee; width:100%;" 
                        :class="item.type=='베스트글'? 'indigo lighten-5':''" 
                        @click="read(item)"
                    >
                        <td
                            class="py-0 px-3"
                            colspan="2"
                            style="border-bottom:none; width:100%; height:36px;"
                        >
                            <v-sheet 
                                color="transparent"
                            >
                                <!-- 카테고리 & 제목 -->
                                <v-sheet
                                    v-if="item.status=='공개'"
                                    class="d-flex align-center text-truncate"
                                    color="transparent"
                                >
                                    <v-sheet
                                        outlined
                                        class="rounded-15 d-flex align-center justify-center mr-1"
                                        style="border:1px solid #615DFA; color:#615DFA;"
                                        width="40"
                                    >
                                        공지
                                    </v-sheet>
                                    <span>{{item.title}}</span>
                                </v-sheet>
                            </v-sheet>
                        </td>
                    </tr>

                    <!-- 일반글 -->
                    <tr
                        v-if="item.type != '공지'"
                        class="pa-0 ma-0"
                        style="cursor:pointer; border-bottom:1px solid #eee; width:100%;" 
                        :class="item.type=='베스트글'? 'indigo lighten-5':''" 
                        @click="read(item)"
                    >
                        <td
                            class="py-1 pl-3 pr-0"
                            style="border-bottom:none; width:100%;"
                        >
                            <v-sheet
                                color="transparent"
                            >
                                <!-- 카테고리 & 제목 -->
                                <v-sheet
                                    v-if="item.status=='공개'"
                                    style="max-height:36px; line-height:18px;"
                                    class="custom-truncate-mobile mt-3px"
                                    color="transparent"
                                >
                                    <span v-if="item.category" class="mr-1 cyan--text">{{item.category}}</span>
                                    <span>{{item.title}}</span>

                                    <v-icon v-if="isNewItem(item)" color="#F8468D" style="margin-left:3px; margin-bottom:3px; width:14px;" small>mdi-alpha-n-circle</v-icon>
                                </v-sheet>

                                <!-- 블라인드 글 -->
                                <v-sheet color="transparent" v-if="item.status=='블라인드됨'">
                                    <font color="grey">해당 게시글은 운영진에 의해 블라인드 처리된 글입니다.</font>
                                </v-sheet>

                                <!-- 작성 정보 -->
                                <v-sheet
                                    class="d-flex align-center text-truncate"
                                    color="transparent"
                                >
                                    <!-- 프로필 -->
                                    <Profile
                                        :user="item.user_id"
                                        :color="'grey'"
                                    />

                                    <!-- 작성일 & 조회수 & 좋아요 -->
                                    <p class="ml-1 mb-0 grey--text text-caption text-truncate" style="width:60%;">
                                        <span style="letter-spacing:-0.5px;" class="grey--text mr-1">{{ dayjs(item.created).format(dayjs().isSame(item.created, 'day') ? 'a hh:mm' : 'YYYY. MM. DD').replace('am', '오전').replace('pm', '오후') }}</span>
                                        <v-icon x-small color="grey" class="ml-1 mb-1px mr-2px">mdi-eye-outline</v-icon>
                                        <span :style="item.view_count > 500? 'color:#615DFA':'color:#a49eaa;'">{{item.view_count}}</span>
                                        <v-icon x-small color="grey" class="ml-1 mb-1px mr-1">mdi-comment-processing-outline</v-icon>{{item.comment_count + item.sub_comment_count}}
                                        <v-icon x-small :color="item.like_count>=5? '#615df7':'grey'" class="mb-1px" style="margin-right:-2px;">mdi-heart-outline</v-icon>
                                        <span :style="item.like_count>=5? 'color:#615df7':'color:#9E9E9E'">
                                            {{item.like_count}}
                                        </span>
                                    </p>

                                    <v-icon
                                        style="position:absolute; right:12px;"
                                        v-if="item.image!='HI' && !thumbnail_switch"
                                        size="14"
                                        color="grey"
                                        class="ml-1 mr-2px"
                                    >
                                        mdi-image-outline
                                    </v-icon>
                                </v-sheet>
                            </v-sheet>
                        </td>
                        <td
                            v-if="thumbnail_switch"
                            class="py-1 px-1"
                            style="width:48px;  border-bottom:none;"
                        >
                            <div class="d-flex justify-end" style="width:48px;">
                                <!-- 이미지 -->
                                <v-sheet height="48" width="48" color="transparent">
                                    <v-img
                                        height="48"
                                        width="48"
                                        :src="item.image=='HI'? '':item.image"
                                    />

                                    <!-- <v-img
                                        height="48"
                                        width="48"
                                        src="@/assets/default.png"
                                    /> -->
                                </v-sheet>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <!-- 유튜브 게시판 -->
            <v-row
                v-if="!loading && $route.query.board_name == '유튜브 게시판'"
                class="mt-2"
            >
                <v-col
                    v-for="(item, index) in currentPageList" :key="index"
                    cols="12"
                    class="px-5 mb-2"
                    style="cursor:pointer; border-bottom:1px solid #eee;"
                    @click="read(item)"
                >
                    <!-- 공지 -->
                    <table>
                        <tr 
                            v-if="item.type == '공지'"
                            class="pa-0 ma-0 text-truncate" 
                            style="cursor:pointer; width:100%;" 
                            @click="read(item)"
                        >
                            <!-- 공지 & 제목 -->
                            <div
                                class="d-flex align-center text-truncate mb-2px"
                                style="width:100%;"
                            >
                                <!-- 공지 -->
                                <v-sheet
                                    outlined
                                    class="rounded-15 d-flex align-center justify-center mr-1"
                                    style="border:1px solid #615DFA; color:#615DFA; font-size:14px;"
                                    width="40"
                                >
                                    공지
                                </v-sheet>

                                <!-- 제목 -->
                                {{item.title}}
                            </div>
                        </tr>
                    </table>

                    <!-- 유튜브 썸네일 -->
                    <v-img
                        v-if="item.type != '공지'"
                        class="rounded-10"
                        style="aspect-ratio: 16 / 9"
                        :src="item.image=='HI'? '':item.image"
                    />

                    <v-sheet v-if="item.type != '공지'" class="d-flex" color="transparent">
                        <v-sheet color="transparent">
                            <v-sheet color="transparent" class="text-truncate pa-1">
                                <v-sheet color="transparent" v-if="item.status=='공개'">
                                    <font v-if="item.category" class="mr-1 cyan--text">{{item.category}}</font>
                                    <font :class="item.type=='공지'? 'font-weight-bold deep-orange--text':''">{{item.title}}</font>
                                </v-sheet>
                                <v-sheet color="transparent" v-if="item.status=='블라인드됨'">
                                    <font color="grey">해당 게시글은 운영진에 의해 블라인드 처리된 글입니다.</font>
                                </v-sheet>
                            </v-sheet>
                            <v-sheet class="d-flex align-center mx-1" color="transparent">
                                <!-- 프로필 -->
                                <Profile v-if="item.user_id" :user="item.user_id" :color="'grey'"/>
                                <v-spacer></v-spacer>
                                <font class="ml-2 grey--text" style="font-size:10px;">
                                    {{ dayjs(item.created).format(dayjs().isSame(item.created, 'day') ? 'a hh:mm' : 'YYYY. MM. DD').replace('am', '오전').replace('pm', '오후') }}
                                </font>
                                <font class="ml-2 grey--text" style="font-size:10px;"><v-icon x-small color="grey" class="mr-1 pb-2px">mdi-eye-outline</v-icon>{{item.view_count}}</font>
                                <font class="ml-2 grey--text" style="font-size:10px;"><v-icon x-small color="grey" class="mr-1 pb-2px">mdi-heart-outline</v-icon>{{item.like_count}}</font>
                                <font class="ml-2 grey--text" style="font-size:10px;"><v-icon x-small color="grey" class="mr-1 pb-2px">mdi-comment-processing-outline</v-icon>{{item.comment_count + item.sub_comment_count}}</font>
                            </v-sheet>
                        </v-sheet>
                    </v-sheet>
                </v-col>
            </v-row>

            <!-- 페이징 -->
            <v-pagination
                class="mt-3"
                v-model="page"
                :length="pageCount"
                :total-visible="5"
            ></v-pagination>

            <BannerView
                v-if="$store.state.ads_banner.community"
                class="mt-3"
                type="community"
                width="100%"
                :height="46/360*100+'vw'"
                :round="'rounded-0'"
            />
        </v-sheet>
    </div>
</template>
<script>
import Profile from "@/components/Profile"
import BannerView from '@/components/BannerView'

export default {
    components: {
        Profile,
        BannerView
    },

    data: () => ({
        // 검색
        select_list: {
            search_type: ["전체", "글쓴이", "제목", "내용"],
            category: []
        },
        search_type: "전체",
        keyword: "",

        category: "",
        
        // 목록
        list: [],

        // 페이징
        page: 1,
        pageCount: 0,
        itemsPerPage: 12,
        
        // 유튜브 게시판
        currentPageList: [],

        // 인기글
        isHot: false,

        loading: false,

        thumbnail_switch: true,
        search_switch: false
    }),

    mounted() {
        if(this.$route.query.category){
            this.category = this.$route.query.category
        }

        // 첫 목록 불러오기
        this.load()

        // 카테고리 목록 불러오기
        this.loadCategory()

        if(this.$route.query.ishot){
            this.isHot = true
            this.hot()
        }

        if(this.$route.query.mypost){
            this.myPost()
        }

        if(this.$route.query.mycomment){
            this.myComment()
        }

        if(this.$route.query.keyword){
            this.search_type = this.$route.query.search_type
            this.keyword = this.$route.query.keyword
            this.search()
        }

    },

    watch: {
        page(){
            this.$router.replace({ query: { ...this.$route.query, page: this.page }})
            this.currentPageList = this.list.slice((this.page-1)*12,(this.page)*12)
        }
    },

    methods: {
        isNewItem(item) {
            return new Date() - new Date(item.created) < 24 * 60 * 60 * 1000
        },
        
        // 게시판 이름 선택시
        loadFirst(){
            if(this.keyword == "")
            {
                this.$router.push('/board/list?board_group='+this.$route.query.board_group+'&board_name='+this.$route.query.board_name)
            }
            else{
                this.search_type = "전체"
                this.keyword = ""
                this.load()
            }
        },

        // 카테고리가 선택됨
        category_selected(item){
            this.$router.push('/board/list?board_group='+this.$route.query.board_group+'&board_name='+this.$route.query.board_name+'&category='+item)
        },

        // 내글 목록
        async myPost(){
            this.search_type = "글쓴이"
            this.keyword = this.$store.state.nickname
            await this.search()
            this.search_type = "전체"
            this.keyword = ""
        },

        // 내댓글 목록
        myComment(){
            this.loading = true
            this.$http.post('/api/board/list/search/myComment', {
                params: {
                    board_name: this.$route.query.board_name,
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                this.list = res.data

                // 유튜브게시판 용
                this.currentPageList = res.data.slice(0,12)
                this.pageCount = Math.ceil(res.data.length/12)

                // 썸네일 추가
                this.matchThumbnail()

                this.loading = false
            })
        },

        // 카테고리 목록 불러오기
        loadCategory(){
            this.$http.post('/api/board/list/category', {
                params: {
                    board_name : this.$route.query.board_name
                }
            }).then(res => {
                this.select_list.category = res.data.map(e => e.title)
            })
        },

        // 첫 목록
        async load(){
            this.loading = true
            this.isHot = false
            if(!this.$route.query.category){
                await this.$http.post('/api/board/list', {
                    params: {
                        board_name: this.$route.query.board_name
                    }
                }).then((res) => {
                    // 주소 page 불러오기
                    if(this.$route.query.page){
                        this.page = parseInt(this.$route.query.page)
                    }

                    this.list = res.data

                    // 유튜브게시판 용
                    this.currentPageList = res.data.slice(0,12)
                    this.pageCount = Math.ceil(res.data.length/12)

                    // 썸네일 추가
                    this.matchThumbnail()
                    this.loading = false
                })
            }else {
                await this.$http.post('/api/board/list/category/search', {
                    params: {
                        board_name: this.$route.query.board_name,
                        category: this.$route.query.category
                    }
                }).then((res) => {
                    // 주소 page 불러오기
                    if(this.$route.query.page){
                        this.page = parseInt(this.$route.query.page)
                    }
                    
                    this.list = res.data

                    // 유튜브게시판 용
                    this.currentPageList = res.data.slice(0,12)
                    this.pageCount = Math.ceil(res.data.length/12)

                    // 썸네일 추가
                    this.matchThumbnail()
                    this.loading = false
                })
            }
        },

        // 인기글 목록
        hot(){
            this.loading = true
            this.$http.post('/api/board/list/hot', {
                params: {
                    board_name: this.$route.query.board_name,
                    category: this.$route.query.category
                }
            }).then((res) => {
                this.list = res.data

                // 썸네일 추가
                this.matchThumbnail()

                // 유튜브게시판 용
                this.currentPageList = res.data.slice(0,12)
                this.pageCount = Math.ceil(res.data.length/12)

                // 인기글
                this.isHot = true

                this.loading = false
            })
        },

        // 검색
        search(){
            this.loading = true
            if(!this.$route.query.category){
                this.$http.post('/api/board/list/search', {
                    params: {
                        board_name: this.$route.query.board_name,
                        search_type: this.search_type,
                        keyword: this.keyword
                    }
                }).then((res) => {
                    this.list = res.data

                    // 유튜브게시판 용
                    this.currentPageList = res.data.slice(0,12)
                    this.pageCount = Math.ceil(res.data.length/12)

                    // 썸네일 추가
                    this.matchThumbnail()
                    this.loading = false
                })
            }else{
                this.$http.post('/api/board/list/searchInCategory', {
                    params: {
                        board_name: this.$route.query.board_name,
                        category: this.$route.query.category,
                        search_type: this.search_type,
                        keyword: this.keyword
                    }
                }).then((res) => {
                    this.list = res.data

                    // 유튜브게시판 용
                    this.currentPageList = res.data.slice(0,12)
                    this.pageCount = Math.ceil(res.data.length/12)

                    // 썸네일 추가
                    this.matchThumbnail()
                    this.loading = false
                })
            }
        },

        // 썸네일 추가
        matchThumbnail(){
            if(this.$route.query.board_name == "유튜브 게시판"){
                this.list.forEach(e => {
                    const match = e.content.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
                    if (match) {
                        e.image = "https://img.youtube.com/vi/" + match.pop() + "/0.jpg"
                    }
                })
            }else{
                const imgReg = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
                const imgGalleryReg = /<imagegallery[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
                const customImgReg = /<customimage[^>]*src=["']?([^>"']+)["']?[^>]*>/i;
                const cardReg = /<card[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
                
                this.list.forEach(e => {
                    if(e.content.match(imgReg)){
                        e.image = e.content.match(imgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if(e.content.match(customImgReg)){
                        e.image = e.content.match(customImgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if(e.content.match(imgGalleryReg)){
                        e.image = e.content.match(imgGalleryReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if(e.content.match(cardReg)){
                        e.image = e.content.match(cardReg)[0].replace(/.*src="([^"]*)".*/, '$1').replace('&amp;', '&')
                    }
                })
            }
        },

        // 게시글로 이동
        read(item){
            if(item.status=='블라인드됨' && this.$store.state.type!='관리자'){
                alert("블라인드 처리된 글은 읽을 수 없습니다.")
            }
            else{
                this.$router.push("/board/read?board_group="+this.$route.query.board_group+"&board_name="+this.$route.query.board_name+"&id="+item.id)
            }
        },

        // 글쓰기
        write(){
            if(!this.$store.state.is_logined){
                alert("글쓰기는 로그인 후에만 가능합니다.")
            }
            else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 밴 기간동안 글쓰기가 제한됩니다.')
            }
            else if(this.$store.state.type!='관리자' && this.$route.query.board_name=='당첨 / 구매 공지게시판'){
                alert('당첨 / 구매 공지게시판은 관리자만 글쓰기가 가능합니다.')
            }
            else{
                this.$router.push("/board/write?board_group="+this.$route.query.board_group+"&board_name="+this.$route.query.board_name)
            }
        },
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

/* 검색 keyword */
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 12px;
}

/* 검색 type */
::v-deep .v-select input {
    margin-left:4px;
    font-size: 12px;
    padding-bottom: 6px;
}

/* 입력란 공통 (label) */
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}

/* Custom Truncate */
.custom-truncate-mobile{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* 페이징 */
::v-deep .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}


/* 모바일 카테고리 */

::v-deep .mobile .v-select {
    min-height: 33px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-select fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 33px;
    min-height: 33px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-select .v-select__selections {
    min-height: 33px !important;
    height: 33px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* Mobile > 카테고리 > 내부 텍스트 간격 최종 조정 */
::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    padding-left: -4px !important;
    font-size: 12px !important;
}

/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

/* read import */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}
/* ---- */

::v-deep .mobile .v-select .v-select__slot {
    font-size: 12px; /* 글꼴 크기 조절 */
    height: 33px;
    padding-bottom: 2px;
    margin-left:-2px;
}

::v-deep .v-input--switch .v-label {
    font-size:12px !important;
}

::v-deep .no-outline {
    border: none !important;
}

::v-deep .custom_background .v-text-field .v-input__control .v-input__slot {
    background: #1da1f2;
}

/* v-text-field 글자색 변경 (성공) */
::v-deep .custom_background .v-text-field .v-input__control .v-input__slot input[type="text"] {
    font-size: 16px !important;
    color: white !important;
}

/* 표준 ::placeholder 스타일을 별도로 정의 */
::v-deep .custom_background .v-text-field .v-input__control .v-input__slot input[type="text"]::placeholder {
    color: white !important;
    opacity: 0.8 !important;
}

/* Internet Explorer 10-11 */
::v-deep .custom_background .v-text-field .v-input__control .v-input__slot input[type="text"]:-ms-input-placeholder {
    color: white !important;
}

/* Microsoft Edge */
::v-deep .custom_background .v-text-field .v-input__control .v-input__slot input[type="text"]::-ms-input-placeholder {
    color: white !important;
}

/* Mozilla Firefox 4 to 18 */
::v-deep .custom_background .v-text-field .v-input__control .v-input__slot input[type="text"]::-moz-placeholder {
    color: white !important;
    opacity: 0.8 !important;
}

/* Mozilla Firefox 19+ */
::v-deep .custom_background .v-text-field .v-input__control .v-input__slot input[type="text"]::-moz-placeholder {
    color: white !important;
    opacity: 0.8 !important;
}

/* WebKit 기반 브라우저 (Safari, Chrome 등) */
::v-deep .custom_background .v-text-field .v-input__control .v-input__slot input[type="text"]::-webkit-input-placeholder {
    color: white !important;
}



/*/~ v-switch 배경 (성공) ~/
::v-deep .custom-switch .v-input--selection-controls__input .v-input--switch__track {
    background-color: white;
}

/~ v-switch 동그라미 (성공) ~/
::v-deep .custom-switch .v-input--selection-controls__input .v-input--switch__thumb {
    background-color: #00b6c6;
}*/


/* ::v-deep .v-input--switch .v-input--switch__track {
    background: white;
    height: 20px;
    width: 35px;
}

::v-deep .v-input--switch .v-input--switch__thumb {
    height: 14px;
    width: 14px;
} */

/* ::v-deep .v-input--switch .v-input--selection-controls__ripple {
    height: 20px;
    width: 35px;
}

::v-deep .v-input--switch .v-input--selection-controls__input {
    height: 20px;
    width: 35px;
} */
</style>