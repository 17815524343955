import { render, staticRenderFns } from "./Mobile.vue?vue&type=template&id=5b3cde52&scoped=true"
import script from "./Mobile.vue?vue&type=script&lang=js"
export * from "./Mobile.vue?vue&type=script&lang=js"
import style0 from "./Mobile.vue?vue&type=style&index=0&id=5b3cde52&prod&scoped=true&lang=css"
import style1 from "./Mobile.vue?vue&type=style&index=1&id=5b3cde52&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b3cde52",
  null
  
)

export default component.exports