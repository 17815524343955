<template>
    <v-sheet outlined class="rounded-10">
        <!-- 에디터 상단 -->
        <div>
            <!-- 기본 기능 -->
            <div
                v-if="editor"
                class="menubar d-flex align-center px-1"
            >
                <!-- 글자 크기 -->
                <v-sheet width="100" class="mx-1 fontSizeSelect">
                    <v-select
                        style="font-size:12px"
                        :menu-props="{offsetY: true, maxHeight:500 }"
                        height="30" dense outlined hide-details 
                        :items="font_size_select_list"
                        v-model="selected_font_size"
                        @change="editor.chain().focus().setFontSize(selected_font_size).run()"
                    ></v-select>
                </v-sheet>

                <!-- Bold -->
                <v-btn text x-small height="30" title="굵게" @click="editor.chain().focus().toggleBold().run()">
                    <v-icon small :class="{ 'blue--text': editor.isActive('bold') }">mdi-format-bold</v-icon>
                </v-btn>

                <!-- 인용구 -->
                <v-btn text x-small height="30" title="인용구" @click="editor.chain().focus().toggleBlockquote().run()">
                    <v-icon small :class="{ 'blue--text': editor.isActive('blockquote') }">mdi-format-quote-close</v-icon>
                </v-btn>

                <!-- 하이퍼링크 -->
                <v-btn text x-small height="30" title="링크" @click="setLink">
                    <v-icon small :class="{ 'blue--text': editor.isActive('link') }">mdi-link-variant</v-icon>
                </v-btn>

                <!-- 구분선 -->
                <v-sheet class="transparent" @click="editor.chain().focus().setHorizontalRule().run()">
                    <v-btn text x-small height="30" title="구분선">
                        <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                </v-sheet>

                <!-- Undo -->
                <v-btn text x-small height="30" @click="editor.chain().focus().undo().run()">
                    <v-img
                        contain width="8" height="12"
                        :src="require('@/assets/tiptap/undo.svg')"
                        title="실행취소"
                    />
                </v-btn>

                <!-- Redo -->
                <v-btn text x-small height="30" @click="editor.chain().focus().redo().run()">
                    <v-img
                        contain width="8" height="12"
                        :src="require('@/assets/tiptap/redo.svg')"
                        title="다시실행"
                    />
                </v-btn>
            </div>

            <!-- 2번쨰줄 -->
            <v-sheet class="menubar2 d-flex align-center pl-1">
                <!-- 이미지 -->
                <v-btn
                    title="이미지"
                    class="menubar__button"
                    x-small
                    height="30"
                    text
                    :class="options.supportImage ? '' : 'is-disabled'"
                    @click="imageDialog2 = true"
                >
                    <v-icon small color="blue">mdi-image-plus</v-icon>
                </v-btn>

                <!-- 동영상 -->
                <v-btn
                    title="유튜브"
                    class="menubar__button"
                    x-small
                    height="30"
                    text
                    :class="options.supportVideo ? '' : 'is-disabled'"
                    @click="videoDialog = true"
                >
                    <v-icon small color="red">mdi-youtube</v-icon>
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn text small height="30" style="padding:0 4px;" @click="dialog.cardList = true">
                    <v-icon small color="#615DF7" class="mr-1">mdi-cards-playing</v-icon>
                    <span style="letter-spacing:0.4px">카드</span>
                </v-btn>
                <v-btn text small height="30" style="padding:0 4px;" @click="dialog.card_id_search = true">
                    <v-icon small color="#615DF7" class="mr-1">mdi-cards-playing</v-icon>
                    <span style="letter-spacing:0.4px">카드ID</span>
                </v-btn>
                <v-btn text small height="30" style="padding:0 4px;" @click="dialog.collection = true">
                    <v-icon small color="blue" class="mr-1">mdi-cards-playing-outline</v-icon>
                    <span style="letter-spacing:0.4px">콜렉션</span>
                </v-btn>
                <v-btn text small height="30" style="padding:0 4px;" @click="dialog.deck_recipe = true">
                    <v-icon small color="#23d2e2" class="mr-1">mdi-cards-outline</v-icon>
                    <span style="letter-spacing:0.4px">덱</span>
                </v-btn>
                <v-btn text small height="30" style="padding:0 4px;" class="mr-1" @click="dialog.challenge_book = true">
                    <v-icon small color="#23d2e2" class="mr-1">mdi-progress-star-four-points</v-icon>
                    <span style="letter-spacing:0.4px">챌린지북</span>
                </v-btn>
            </v-sheet>
        </div>

        <!-- 본문 -->
        <v-sheet 
            class="rounded-10"
            @drop.prevent="onDrop" 
            @dragover="checkDrop($event)" 
            @paste="checkPaste($event)"
            style="max-height:240px; overflow-y: auto"
            @keydown="handleKeydown"
        >
            <!-- style="max-height:240px; overflow-y: auto" (추가된 부분) -->
            <!-- <editor-content
                class="px-4 py-8"
                style="min-height:120px; font-size:11pt;"
                :editor="editor"
            /> -->
            <editor-content
                class="px-4 py-8"
                style="font-size:11pt;"
                :editor="editor"
            />
        </v-sheet>

        <!-- 카드목록 (dialog) -->
        <v-dialog
            v-model="dialog.cardList"
            width="1040"
            content-class="rounded-15"
        >
            <v-sheet>
                <CardList
                    :isWrite="true"
                    :is_collection="collection"
                    :is_deck="deck"
                    :nation="card_nation"
                    :set_name="set_name"
                    @goToDetail="goToDetail"
                    :key="componentKey"
                />
            </v-sheet>
        </v-dialog>

        <!-- 카드상세 (dialog) -->
        <v-dialog
            v-model="dialog.cardDetail"
            width="1040"
            content-class="rounded-15"
        >
            <v-sheet class="pa-4">
                <CardDetail
                    :id="cardDetail_id"
                    :isBoardContent="false"
                    @CardSelected="CardSelected"
                    @Close="dialog.cardDetail = false"
                />
            </v-sheet>
        </v-dialog>

        <!-- Dialog2 (이미지 리사이즈) -->
        <v-dialog
            v-model="imageDialog2"
            width="auto"
            content-class="rounded-10"
        >
            <v-sheet
                class="rounded-15 px-10 pt-6 pb-4"
                color="#F8F7FB"
            >
                <!-- 제목 -->
                <p class="mb-4 font-weight-bold text-center" style="font-size:20px;">
                    이미지 넣기
                </p>

                <clipper-basic
                    :src="url"
                    ref="clipper"
                    class="my-clipper"
                    :init-width="100"
                    :init-height="100"
                >
                    <v-sheet
                        slot="placeholder"
                        class="placeholder text-center grey--text py-6 rounded-10"
                        style="background:transparent;"
                        outlined
                    >
                        이미지가 없습니다
                    </v-sheet>
                </clipper-basic>

                <!-- 파일 입력 -->
                <v-file-input
                    class="rounded-10 mt-4"
                    outlined
                    clear-icon
                    show-size
                    color="primary"
                    prepend-icon=""
                    prepend-inner-icon="mdi-folder-open-outline"
                    placeholder="파일을 선택해주세요."
                    accept="image/*"
                    v-model="selectedFile"
                    @change="image_onFileChange"
                ></v-file-input>

                <!-- 정렬 -->
                <v-sheet class="d-flex justify-space-between mb-4" color="transparent">
                    <v-btn
                        width="60"
                        class="rounded-10"
                        depressed
                        x-small
                        :dark="image_align2=='justify-start'"
                        :class="image_align2=='justify-start'?'blue':''"
                        @click="image_align2='justify-start'"
                    >
                        왼쪽
                    </v-btn>
                    <v-btn
                        width="60"
                        class="rounded-10"
                        depressed
                        x-small
                        :dark="image_align2=='justify-center'"
                        :class="image_align2=='justify-center'?'blue':''"
                        @click="image_align2='justify-center'"
                    >
                        가운데
                    </v-btn>
                    <v-btn
                        width="60"
                        class="rounded-10"
                        depressed
                        x-small
                        :dark="image_align2=='justify-end'"
                        :class="image_align2=='justify-end'?'blue':''"
                        @click="image_align2='justify-end'"
                    >
                        오른쪽
                    </v-btn>
                </v-sheet>

                <!-- 제출 -->
                <v-btn
                    height="42"
                    dark
                    depressed
                    color="#615DFA"
                    class="font-weight-bold rounded-10 d-block px-10 mx-auto"
                    @click="insertImage2()"
                >
                    이미지 넣기
                </v-btn>
            </v-sheet>
        </v-dialog>

        <!-- 이미지 (dialog) -->
        <v-dialog
            v-model="imageDialog"
            width="auto"
            content-class="rounded-10"
        >
            <v-sheet
                class="rounded-15 px-12 pt-8 pb-4"
                color="#F8F7FB"
            >
                <!-- 제목 -->
                <p
                    class="mb-6 font-weight-bold text-center"
                    style="font-size:24px;"
                >
                    이미지 넣기
                </p>

                <!-- 편집 / 미리보기 -->
                <clipper-basic
                    :src="url"
                    ref="clipper"
                    class="my-clipper"
                    :init-width="100"
                    :init-height="100"
                >
                    <v-sheet
                        slot="placeholder"
                        class="placeholder text-center grey--text py-6 rounded-10"
                        style="background:transparent;"
                        outlined
                    >
                        이미지가 없습니다
                    </v-sheet>
                </clipper-basic>

                <!-- 파일 입력 -->
                <v-file-input
                    class="rounded-10 mt-4"
                    outlined
                    clear-icon
                    show-size
                    color="primary"
                    prepend-icon=""
                    prepend-inner-icon="mdi-folder-open-outline"
                    placeholder="여기를 클릭 후 파일을 선택해주세요."
                    accept="image/*"
                    v-model="selectedFile"
                    @change="image_onFileChange"
                ></v-file-input>

                <!-- 정렬 -->
                <v-sheet class="d-flex justify-space-between mb-4" color="transparent">
                    <v-btn
                        width="60"
                        x-small
                        class="rounded-10"
                        depressed
                        :dark="image_align=='left'"
                        :class="image_align=='left'?'primary':''"
                        @click="image_align='left'"
                    >
                        왼쪽
                    </v-btn>
                    <v-btn
                        width="60"
                        x-small
                        class="rounded-10"
                        depressed
                        :dark="image_align=='center'"
                        :class="image_align=='center'?'primary':''"
                        @click="image_align='center'"
                    >
                        가운데
                    </v-btn>
                    <v-btn
                        width="60"
                        x-small
                        class="rounded-10"
                        depressed
                        :dark="image_align=='right'"
                        :class="image_align=='right'?'primary':''"
                        @click="image_align='right'"
                    >
                        오른쪽
                    </v-btn>
                </v-sheet>

                <!-- 제출 -->
                <v-btn
                    height="42"
                    dark
                    depressed
                    color="#615DFA"
                    class="font-weight-bold rounded-10 d-block px-10 mx-auto"
                    @click="insertImage()"
                >
                    이미지 넣기
                </v-btn>
            </v-sheet>
        </v-dialog>

        <!-- 동영상 (dialog) -->
        <v-dialog
            v-model="videoDialog"
            width="auto"
            content-class="rounded-10"
        >
            <v-sheet
                class="rounded-15 pt-4 pb-6 px-6"
                color="#F8F7FB"
            >
                <!-- 제목 -->
                <p
                    class="mb-4 font-weight-bold text-center"
                    style="font-size:16px;"
                >
                    유튜브 넣기
                </p>

                <!-- 링크 입력 -->
                <v-text-field
                    class="rounded-10 mb-4"
                    dense
                    color="red"
                    outlined
                    hide-details
                    single-line
                    label="링크를 입력해주세요"
                    v-model="videoURL"
                ></v-text-field>

                <!-- 제출 -->
                <v-btn
                    height="40"
                    width="100%"
                    class="rounded-10"
                    color="red"
                    depressed
                    dark
                    @click="insertVideo()"
                >
                    유튜브 넣기
                </v-btn>
            </v-sheet>
        </v-dialog>

        <v-dialog
            v-model="dialog.collection"
            width="auto"
            content-class="rounded-xl"
        >
            <v-sheet
                class="pa-4 px-6"
            >
                <p class="text-h6 mb-6 text-center font-weight-bold">내 콜렉션 불러오기</p>

                <v-select
                    label="발매국가"
                    placeholder="발매국가를 선택해주세요"
                    persistent-placeholder
                    class="rounded-15"
                    outlined
                    :items="select_list.card_nation"
                    item-text="name"
                    item-value="value"
                    v-model="card_nation"
                >
                    <template v-slot:selection="{ item }">
                        <v-sheet width="100%" class="pl-4 font-weight-bold">{{item.name}}</v-sheet>
                    </template>
                    <template v-slot:append>
                        <v-icon color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-autocomplete
                    height="56"
                    label="제품명 선택"
                    placeholder="제품명을 선택해주세요"
                    persistent-placeholder
                    class="rounded-15"
                    outlined
                    :items="set_list"
                    v-model="set_name"
                >
                    <template v-slot:append>
                        <v-icon color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-autocomplete>

                <v-sheet class="d-flex justify-center">
                    <v-btn
                        width="94"
                        height="34"
                        class="rounded-10 px-6 mr-2"
                        color="#615dfa"
                        dark
                        large
                        depressed
                        @click="collectionOpenList()"
                    >
                        불러오기
                    </v-btn>
                    <v-btn
                        width="94"
                        height="34"
                        class="rounded-10 px-6"
                        color="#adafca"
                        dark
                        large
                        depressed
                        @click="dialog.collection = false"
                    >
                        취소
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 덱 레시피 불러오기 (dialog) -->
        <v-dialog
            v-model="dialog.deck_recipe"
            width="880"
            content-class="rounded-15"
        >
            <v-sheet
                style="overflow-x:hidden;"
            >
                <DeckRecipeList
                    @DeckSelected="DeckSelected"
                    :key="componentKey"
                />
            </v-sheet>
        </v-dialog>

        <!-- 챌린지북 불러오기 (dialog) -->
        <v-dialog
            v-model="dialog.challenge_book"
            content-class="rounded-15"
        >
            <v-sheet
                style="overflow-x:hidden;"
                class="rounded-15 pa-2 pb-4"
            >
                <ChallengeBookList
                    @ChallengeSelected="ChallengeSelected"
                    :key="componentKey"
                />
            </v-sheet>
        </v-dialog>

        <!-- 카드 ID로 불러오기 (dialog) -->
        <v-dialog
            v-model="dialog.card_id_search"
            width="340"
            content-class="rounded-10"
        >
            <v-sheet class="rounded-15 px-5 pt-4 pb-4" color="#F8F7FB">
                <!-- 제목 -->
                <p class="mb-4 font-weight-bold text-center" style="font-size:20px;">
                    카드 ID로 불러오기
                </p>

                <!-- 파일 입력 -->
                <v-text-field
                    class="rounded-10 mt-4"
                    outlined
                    dense
                    color="primary"
                    placeholder="카드 ID를 입력해주세요"
                    v-model="card_by_id_card_id"
                ></v-text-field>

                <!-- 정렬 -->
                <v-sheet class="d-flex justify-space-between mb-4" color="transparent">
                    <v-btn
                        width="31%"
                        class="rounded-10"
                        depressed
                        :dark="card_by_id_nation=='kr'"
                        :class="card_by_id_nation=='kr'?'blue':''"
                        @click="card_by_id_nation='kr'"
                    >
                        한글 카드
                    </v-btn>
                    <v-btn
                        width="31%"
                        class="rounded-10"
                        depressed
                        :dark="card_by_id_nation=='en'"
                        :class="card_by_id_nation=='en'?'blue':''"
                        @click="card_by_id_nation='en'"
                    >
                        영어 카드
                    </v-btn>
                    <v-btn
                        width="31%"
                        class="rounded-10"
                        depressed
                        :dark="card_by_id_nation=='jp'"
                        :class="card_by_id_nation=='jp'?'blue':''"
                        @click="card_by_id_nation='jp'"
                    >
                        일어 카드
                    </v-btn>
                </v-sheet>

                <!-- 제출 -->
                <v-btn
                    height="36"
                    dark
                    depressed
                    color="#615DFA"
                    class="font-weight-bold rounded-10 d-block px-8 mx-auto"
                    @click="insert_card_by_id()"
                >
                    카드 넣기
                </v-btn>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import FontSize from '@tobiasafischer/tiptap-extension-font-size'
import FontFamily from '@tiptap/extension-font-family'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import Card from './extensions/Card.js'
import CardList from '@/components/card/List'
import CardDetail from '@/components/card/detailForTrade/Detail'
// import Iframe from './extensions/Iframe.js'
import Youtube from '@tiptap/extension-youtube'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import CharacterCount from '@tiptap/extension-character-count'

import ImageGallery from './extensions/ImageGallery.js'
import CustomImage from './extensions/resizable_image.js'

import DeckRecipe from './extensions/DeckRecipe.js'
import DeckRecipeList from './extensions/DeckRecipeList'

import ChallengeBook from './extensions/ChallengeBook.js'
import ChallengeBookList from './extensions/ChallengeBookList'

export default {
    components: {
        EditorContent,
        CardList,
        CardDetail,
        BubbleMenu,
        DeckRecipeList,
        ChallengeBookList
    },

    props: {
        options: Object
    },

    data: () => ({
        select_list: {
            card_nation: [
                {name: "국내판", value: "kr"},
                {name: "북미판", value: "en"},
                {name: "일본판", value: "jp"}
            ],

            set: {
                en: [],
                kr: [],
                jp: []
            },
        },

        image_align: "left",

        card_nation: "kr",
        set_name: "",

        editor: null,
        cardDialog: false,
        cardTab: null,
        cardDetail_id:"",
        cardImage: "",

        imageDialog: false,
        imageDialog2: false,
        videoDialog: false,
        imageTab: null,
        fileURL: null,
        videoURL: null,
        selectedFile: null,
        limit: 280,

        // 글자 크기
        font_size_select_list: ["8pt", "9pt", "10pt", "11pt", "12pt", "14pt", "18pt", "24pt", "32pt"],
        selected_font_size: "11pt",

        // 글자 색상
        dialog: {
            color_picker: false,
            cardList:false,
            cardDetail: false,
            collection: false,
            deck_recipe: false,
            challenge_book: false,
            card_id_search: false
        },
        color: '#000000',
		menu: false,

        // 서브 메뉴
        submenu: {
            card: false,
            table: false,
            text_align: false,
            list: false,
        },

        image_align: "left",
        image_align2: "justify-center",
        url: "",

        componentKey: 0,

        collection: false,
        deck: false,

        card_by_id_card_id: "",
        card_by_id_nation: "kr"
    }),

    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit,
                Underline,
                Link,
                ImageGallery,
                Image.configure({
                    inline: true
                }),
                CustomImage,
                TextAlign.configure({
                    types: ['heading', 'paragraph']
                }),
                TextStyle,
                Color,
                FontFamily,
                FontSize,
                Card,
                DeckRecipe,
                ChallengeBook,
                // Iframe,
                Youtube.configure({
                    inline: false,
                    width: 480,
                    height: 320,
                }),
                Table.configure({
                    resizable: true
                }),
                TableRow,
                TableHeader,
                TableCell,
                CharacterCount,
            ],
            content: this.options.content,
            editable:true,
            autofocus: false,
            onUpdate: () => {
                this.options.content = this.editor.getHTML()
                this.$emit('emit_count', this.editor.storage.characterCount.characters())
            },
        })

        this.editor.on('selectionUpdate', ({ editor }) => {
            if(editor.getAttributes('textStyle').fontSize==undefined){
                this.selected_font_size = '11pt'
            }else{
                this.selected_font_size = editor.getAttributes('textStyle').fontSize
            }

            // Focus 변경시 글자색상 인식
            if(editor.getAttributes('textStyle').color==undefined){
                this.color = '#000000'
            }else{
                this.color = editor.getAttributes('textStyle').color
            }
        })

        this.loadSetsList()

        if(this.$route.query.challenge_id){
            this.editor.commands.insertContent(`<ChallengeBook id='`+this.$route.query.challenge_id+`' user_id='`+this.$store.state.user_id+`' style='display:inline-block'></ChallengeBook><p></p>`)
            this.editor.commands.focus()
            this.dialog.challenge_book = false
        }
    },

    // 글자 색상
    computed: {
        swatchStyle() {
            const { color, menu } = this
            return {
                backgroundColor: color,
                cursor: 'pointer',
                height: '20px',
                width: '20px',
                borderRadius: menu ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out'
            }
        },

        // 제품명
        set_list(){
            switch(this.card_nation){
                case 'kr' : return this.select_list.set.kr
                case 'en' : return this.select_list.set.en
                case 'jp' : return this.select_list.set.jp
            }
        }
    },

    beforeUnmount() {
        this.editor.destroy()
    },

    methods: {
        handleKeydown(event) {
            if (event.key === 'Tab') {
                event.preventDefault();
                this.editor.chain().focus().insertContent('\t').run();
            }
        },

        // 이미지 입력 (Copy & Paste)
        checkPaste(e){
            if(e.clipboardData.files.length){
                this.uploadFile(e.clipboardData.files[0])
            }
        },

        // 이미지 입력 (Drag & Drop (기본 이벤트 차단))
        checkDrop(e) {
            e.preventDefault()
        },

        // 이미지 입력 (Drag & Drop)
        async onDrop(e){
            let files = e.dataTransfer.files
            for(let i=0; i<files.length; i++){
                await this.uploadFile(files[i])
            }
        },

        // 이미지 입력 > 업로드 (복사붙여넣기)
        uploadFile(file) {
            console.log(file)
            let formData = new FormData()
            formData.append('image', file)

            // 이미지 업로드
            this.$http.post('/api/board/write/image/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                // 파일 주소 입력
                let url = '/upload/board_image/'+res.data

                // 이미지 갤러리
                this.editor.commands.insertContent(
                    `
                        <ImageGallery 
                            src='`+url+`'
                            style='display:inline-block'
                        ></ImageGallery><p></p>
                    `
                )
                this.editor.commands.focus()

                // 닫기
                this.imageDialog = false
            }).catch((err) => {
                console.log('FAILURE!!'+ err)
            })
        },

        // 이미지 입력 > 파일 선택
        image_onFileChange: function () {
            this.url = URL.createObjectURL(this.selectedFile)
        },

        // 이미지 입력 > 제출 (이미지 갤러리)
        insertImage: _.debounce(function() {
            const canvas = this.$refs.clipper.clip()
            var imgDataUrl = canvas.toDataURL("image/webp", 0.5)
            var binaryData = atob(imgDataUrl.split(',')[1])
            var array = []
            for (var i = 0; i < binaryData.length; i++) {
                array.push(binaryData.charCodeAt(i))
            }
            var file = new File([new Uint8Array(array)], "filename.png", {type: 'image/png'})
            if(file.name == 'image.png'){
                this.editor.chain().focus().undo().run()
            }
            let formData = new FormData()
            formData.append('image', file)

            // 이미지 업로드
            this.$http.post('/api/board/write/image/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                let url = '/upload/board_image/'+res.data

                let align_class = ""
                if(this.image_align == "left"){
                    align_class = "d-flex justify-start"
                }
                else if(this.image_align == "center"){
                    align_class = "d-flex justify-center"
                }
                else if(this.image_align == "right"){
                    align_class = "d-flex justify-end"
                }

                // 이미지 갤러리
                this.editor.commands.insertContent(
                    `
                        <ImageGallery 
                            src='`+url+`'
                            width='`+canvas.width+`'
                            height='`+canvas.height+`'
                            align_class='`+align_class+`'
                            style='display:inline-block;'
                        ></ImageGallery><p></p>
                    `
                )
                this.editor.commands.focus()

                // 닫기
                this.imageDialog = false
            }).catch((err) => {
                console.log('FAILURE!!'+ err)
            })
        }, 100),

        // 이미지 입력 > 제출 (리사이즈)
        insertImage2: _.debounce(function() {
            const canvas = this.$refs.clipper.clip()
            var imgDataUrl = canvas.toDataURL("image/webp", 0.5)
            var binaryData = atob(imgDataUrl.split(',')[1])
            var array = []
            for (var i = 0; i < binaryData.length; i++) {
                array.push(binaryData.charCodeAt(i));
            }
            var file = new File([new Uint8Array(array)], "filename.png", {type: 'image/png'})

            if(file.name=='image.png'){
                this.editor.chain().focus().undo().run()
            }
            let formData = new FormData()
            formData.append('image', file)
 
            // 이미지 업로드
            this.$http.post('/api/board/write/image/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                let url = '/upload/board_image/'+res.data
                this.editor.commands.insertContent(`<CustomImage src='`+url+`' align='`+this.image_align2+`'></CustomImage><p></p>`)
                this.editor.commands.focus()
                this.imageDialog2 = false
            }).catch((err) => {
                console.log('FAILURE!!'+ err)
            });
        }, 10),

        // 동영상 입력
        insertVideo() {
            if (this.videoURL) {
                this.editor.chain().focus().setYoutubeVideo({
                    src: this.videoURL.replace("/shorts/", "/embed/"),
                    width: 640,
                    height: 480,
                }).focus().run()
                this.videoDialog = false
            }
        },

        // 카드 입력 > 카드상세 이동
        goToDetail(id){
            this.cardDetail_id = id
            this.dialog.cardDetail = true
            this.componentKey++
        },

        // 카드 입력
        CardSelected(card, nation) {
            let id = card.en.id
            let src = ""
            if(nation == "kr"){
                src = card.kr.image
            }else if(nation == "en"){
                src = card.en.image
            }else if(nation == "jp"){
                src = card.jp.image
            }

            this.editor.commands.insertContent(`<Card src='/upload/card_`+nation+`_image/`+src+`' id='`+id+`' nation='`+nation+`' style='display:inline-block'></Card><p></p>`)
            this.editor.commands.focus()

            this.dialog.cardList = false
            this.dialog.cardDetail = false
        },

        // 제품명 목록 불러오기
        loadSetsList(){
            // 한글
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.set.kr = res.data.map(a => a.name)
            })

            // 영어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.set.en = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "jp"
                }
            }).then(res => { 
                this.select_list.set.jp = res.data.map(a => a.name)
            })
        },

        insert_card_by_id() {
            this.$http.post('/api/card/select/image', {
                params: {
                    id: this.card_by_id_card_id,
                    nation: this.card_by_id_nation
                }
            }).then((res) => {
                if(!res.data.length){
                    alert("입력하신 카드 ID에 해당하는 카드가 없습니다")
                }else{
                    this.editor.commands.insertContent(`<Card src='/upload/card_`+this.card_by_id_nation+`_image/`+res.data[0].image+`' id='`+this.card_by_id_card_id+`' nation='`+this.card_by_id_nation+`' style='display:inline-block'></Card><p></p>`)
                    this.editor.commands.focus()
                    this.dialog.card_by_id = false
                }
            })
        },

        // 내 콜렉션에서 불러오기
        collectionOpenList(){
            this.componentKey++
            this.dialog.cardList = true
            this.collection = true
        },

        // 링크 추가
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)
            if (url === null) {
                return
            }
            if (url === '') {
                this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
                return
            }
            this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
        },

        // 덱레시피 입력
        DeckSelected(id) {
            this.editor.commands.insertContent(`<DeckRecipe id='`+id+`' style='display:inline-block'></DeckRecipe><p></p>`)
            this.editor.commands.focus()
            this.dialog.deck_recipe = false
        },

        // 챌린지북 입력
        ChallengeSelected(id) {
            this.editor.commands.insertContent(`<ChallengeBook id='`+id+`' user_id='`+this.$store.state.user_id+`' style='display:inline-block'></ChallengeBook><p></p>`)
            this.editor.commands.focus()
            this.dialog.challenge_book = false
        },
    }
}
</script>
<!-- 에디터 상단 -->
<style>
.menubar{
    height:45px;
    background-color: #fbfbfb;
    border-bottom:1px solid #e0e0e0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.menubar2{
    height:45px !important;
    background-color: #fbfbfb !important;
    border-bottom:1px solid #e0e0e0 !important;
}
</style>

<!-- 에디터 내부 -->
<style lang="scss">
.ProseMirror:focus {
    outline: none;
}

.ProseMirror {
    > * + * {
        margin-top: 0.75em;
    }

    // # list 오류 수정
    ul, ol {
        padding: 0 1rem;

        // 추가된 스타일
        list-style-position: outside;
        margin-left: 1em;
    }

    // # list 오류 수정
    li {
        text-indent: -1em;
        padding-left: 1em;
    }

    h1, h2, h3, h4, h5, h6 {
        line-height: 1.1;
    }

    // 줄바꿈 간격 해결
    p{
        font-size:0.9rem;
        line-height:1.4rem;
        margin:0
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;

        // 이미지 테두리
        // &.ProseMirror-selectednode {
        //     outline: 3px solid #68cef8;
        // }
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0D0D0D, 0.1);
        margin: 2rem 0;
    }

    // .iframe-wrapper {
    //     width: 640px;
    //     height: 360px;

    //     iframe {
    //         position: relative;
    //         width: 640px;
    //         height: 360px;
    //     }
    // }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        overflow: hidden;

        td, th {
            min-width: 1em;
            border: 1px solid #ccc;
            padding: 8px;
            margin:0;
            vertical-align: top;
            box-sizing: border-box;
            position: relative;
            > * {
                margin-bottom: 0 !important;
            }
        }

        th {
            font-weight: bold;
            text-align: center;
            background:#efefef !important;
        }

        .selectedCell:after {
            z-index: 2;
            position: absolute;
            content: "";
            left: 0; right: 0; top: 0; bottom: 0;
            background: rgba(200, 200, 255, 0.4);
            pointer-events: none;
        }

        .column-resize-handle {
            position: absolute;
            right: -2px; top: 0; bottom: 0;
            width: 4px;
            z-index: 20;
            background-color: #adf;
            pointer-events: none;
        }
    }

    .tableWrapper {
        margin: 1em 0;
        overflow-x: auto;
    }

    // 카드
    .Card {
        position:relative;
        display:inline-block;
    }
}
</style>

<!-- 글자 크기 V-Select 관련 -->
<style scoped>
::v-deep .v-select{
    font-size:10pt;
    font-weight:bold;
}

.fontSizeSelect ::v-deep .v-select__selections{
    line-height: 1;
}

.fontSizeSelect ::v-deep .v-select .v-icon{
    margin-bottom:9px;
    font-size:15pt;
}

.fontSizeSelect ::v-deep .v-input__slot{
    min-height:10px !important;
}
</style>

<!-- 이미지 편집 관련 -->
<style scoped>
.my-clipper {
    width:100%;
}

.placeholder {
    width:100%;
}
</style>