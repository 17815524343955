<template>
    <v-sheet>
        <!-- 목록 -->
        <v-sheet
            v-for="item in comment_list" :key="item.id"
            class="px-3 pt-3 pb-4 my-1"
            style="border-top:1px solid #eee;"
        >
            <!-- 블라인드됨 -->
            <v-sheet 
                v-if="item.status=='블라인드됨'"
                class="py-3"
                style="position:relative"
            >
                <p class="text-caption text-center my-2">해당 댓글은 관리자에 의해 블라인드 처리되었습니다.</p>
                <v-btn
                    v-if="$store.state.type=='관리자'"
                    style="position:absolute; top:4px; right:4px;"
                    class="rounded-10"
                    color="#23D2E2"
                    small
                    dark
                    depressed
                    @click="unblind_comment(item)"
                >   
                    블라인드 해제
                </v-btn>
            </v-sheet>

            <!-- 삭제 -->
            <v-sheet 
                v-else-if="item.status=='삭제'"
                class="py-3"
                style="position:relative"
            >
                <!-- 상단 -->
                <v-sheet
                    class="d-flex align-center"
                    :class="$vuetify.breakpoint.xs? 'mb-0' : 'mb-2'"
                >
                    <!-- 프로필 -->
                    <Avatar :user="item.user_id"/>

                    <!-- 닉네임 -->
                    <p
                        class="mx-2 my-0 font-weight-bold"
                        style="font-size:13px; line-height:18px !important;"
                    >
                        {{item.nickname}}
                    </p>

                    <!-- 작성자일 시 -->
                    <v-icon
                        v-if="item.user_id == writer"
                        size="18"
                        class="ml-1 mr-2 mb-2px"
                        color="blue"
                    >
                        mdi-check-circle
                    </v-icon>

                    <!-- 댓글 작성일 -->
                    <font
                        v-if="!$vuetify.breakpoint.xs"
                        class="mr-2"
                        style="font-size:13px; color:#ADAFCA; line-height:18px;"
                    >
                        {{ dayjs(item.created).format(dayjs().isSame(item.created, 'day') ? 'a hh:mm' : 'YYYY. MM. DD').replace('am', '오전').replace('pm', '오후') }}
                    </font>
                </v-sheet>
                <p class="text-caption ml-2 my-2 mt-6 grey--text">삭제된 코멘트입니다</p>

                <!-- 대댓글 목록 -->
                <SubCommentList
                    v-if="item.sub_comment_count"
                    :comment_id="item.id"
                    :writer="writer"
                    :key="componentKey"
                />
            </v-sheet>

            <!-- 공개 (드로우 포함) -->
            <v-sheet v-else>
                <!-- 상단 -->
                <v-sheet
                    class="d-flex align-center"
                    :class="$vuetify.breakpoint.xs? 'mb-0' : 'mb-2'"
                >
                    <!-- 프로필 -->
                    <Avatar :user="item.user_id"/>

                    <!-- 닉네임 -->
                    <p
                        class="mx-2 my-0 font-weight-bold"
                        style="font-size:13px; line-height:18px !important;"
                    >
                        {{item.nickname}}
                    </p>

                    <!-- 작성자일 시 -->
                    <v-icon
                        v-if="item.user_id == writer"
                        size="18"
                        class="ml-1 mr-2 mb-2px"
                        color="blue"
                    >
                        mdi-check-circle
                    </v-icon>

                    <!-- 댓글 작성일 -->
                    <font
                        v-if="!$vuetify.breakpoint.xs"
                        class="mr-2"
                        style="font-size:13px; color:#ADAFCA; line-height:18px;"
                    >
                        {{ dayjs(item.created).format(dayjs().isSame(item.created, 'day') ? 'a hh:mm' : 'YYYY. MM. DD').replace('am', '오전').replace('pm', '오후') }}
                    </font>

                    <!-- 댓글 삭제하기 -->
                    <v-sheet
                        v-if="item.user_id==$store.state.user_id || $store.state.type=='관리자'"
                        title="댓글 삭제하기"
                        width="18"
                        height="18"
                        class="mx-1"
                        style="cursor:pointer;"
                        @click="delete_comment(item)"
                    >
                        <v-img contain src="@/assets/board/delete.svg"></v-img>
                    </v-sheet>

                    <!-- 댓글 수정하기 -->
                    <v-sheet
                        v-if="item.user_id==$store.state.user_id"
                        title="댓글 수정하기"
                        width="18"
                        height="18"
                        class="mx-1"
                        style="cursor:pointer;"
                        @click="item.isUpdating = !item.isUpdating"
                    >
                        <v-img contain src="@/assets/board/update.svg"></v-img>
                    </v-sheet>

                    <!-- 블라인드 처리 -->
                    <v-sheet
                        v-if="$store.state.type=='관리자'"
                        title="댓글 블라인드"
                        width="18"
                        height="18"
                        class="mx-1"
                        style="cursor:pointer;"
                        @click="blind_comment(item)"
                    >
                        <v-img contain src="@/assets/board/blind.svg"></v-img>
                    </v-sheet>

                    <!-- 댓글 신고하기 -->
                    <v-icon
                        v-if="item.user_id!=$store.state.user_id"
                        v-ripple="false"
                        title="댓글 신고하기"
                        size="21"
                        class="mx-1 pt-1px"
                        color="grey"
                        style="background-color:none; cursor:pointer;"
                        @click="report_comment_open(item)"
                    >
                        mdi-alert-circle
                    </v-icon>

                    <v-spacer></v-spacer>

                    <!-- 좋아요, 싫어요 -->
                    <v-sheet
                        v-if="!$vuetify.breakpoint.xs"
                        class="d-flex justify-center"
                    >
                        <!-- 좋아요 -->
                        <v-btn text class="mr-1 px-2" @click="comment_like(item)">
                            <v-img width="26" height="26" contain src="@/assets/board/like.png"></v-img>
                            <span style="font-size:12px;">
                                {{item.like_count}}
                            </span>
                        </v-btn>

                        <!-- 싫어요 -->
                        <v-btn text class="mr-1 px-2" @click="comment_dislike(item)">
                            <v-img width="26" height="26" contain src="@/assets/board/dislike.png"></v-img>
                            <span style="font-size:12px;">
                                {{item.dislike_count}}
                            </span>
                        </v-btn>
                    </v-sheet>
                </v-sheet>

                <!-- 좋아요, 싫어요 (Mobile) -->
                <v-sheet
                    v-if="$vuetify.breakpoint.xs"
                    class="d-flex mx-3"
                >
                    <!-- 댓글 작성일 -->
                    <p class="mt-2 mb-0 mr-1" style="font-size:9px; color:#ADAFCA; line-height:20px;">
                        {{new Date(item.created).toLocaleString()}}
                    </p>

                    <v-spacer></v-spacer>

                    <!-- 좋아요 -->
                    <v-btn text small class="mr-1 px-2" @click="comment_like(item)">
                        <v-img width="18" height="18" contain src="@/assets/board/like.png"></v-img>
                        <span style="font-size:12px;">
                            {{item.like_count}}
                        </span>
                    </v-btn>

                    <!-- 싫어요 -->
                    <v-btn text small class="mr-1 px-2" @click="comment_dislike(item)">
                        <v-img width="18" height="18" contain src="@/assets/board/dislike.png"></v-img>
                        <span style="font-size:12px;">
                            {{item.dislike_count}}
                        </span>
                    </v-btn>
                </v-sheet>

                <!-- 댓글 내용 -->
                <div
                    v-if="$store.state.is_logined && !item.isUpdating && item.status == '공개'"
                    class="pa-3"
                    style="white-space:pre-line; word-break: break-all; font-size:13px;"
                    v-html="$linkify(item.content)"
                ></div>

                <div
                    v-if="$store.state.is_logined && !item.isUpdating && item.status == '드로우'"
                    class="pa-3"
                    style="white-space:pre-line; word-break: break-all; font-size:13px;"
                >
                    <font v-if="item.content == 'C'">C 커먼 카드가 나왔습니다, 재미 없네.. +50p 획득</font>
                    <font v-if="item.content == 'U'">U 언커먼 카드가 나왔습니다, 이런... +70p 획득</font>
                    <font v-if="item.content == 'R'">R 레어 카드가 나왔습니다, 다음을 기대해보죠. +90p 획득</font>
                    <font v-if="item.content == 'RR'" class="font-weight-medium">RR 더블레어 카드가 나왔습니다, 살짝 아쉽네요. +95p 획득</font>
                    <font v-if="item.content == 'RRR'" class="font-weight-medium" style="color:#4f8a6a;">RRR 트리플레어 카드가 나왔습니다! 나쁘지 않네요. +100p 획득</font>
                    <font v-if="item.content == 'SR'" class="font-weight-medium" style="color:#337ea9;">SR 슈퍼레어 카드가 나왔습니다! 대단해요! +150p 획득</font>
                    <font v-if="item.content == 'HR'" class="font-weight-medium" style="color:#cf75a5;">HR 하이퍼레어 카드가 나왔습니다! 축하드립니다! +200p 획득</font>
                    <font v-if="item.content == 'UR'" class="font-weight-medium" style="color:#cb912f;">말도 안돼! UR 울트라레어 카드가 나왔습니다! +500p 획득</font>
                </div>

                <!-- 수정 시 -->
                <div class="mt-4">
                    <!-- 내용 -->
                    <v-sheet v-if="item.isUpdating" outlined class="d-flex justify-center py-1">
                        <v-textarea
                            hide-details
                            flat
                            solo
                            v-model="item.content"
                            style="font-size:13px;"
                            placeholder="평가 내용을 입력해주세요"
                        ></v-textarea>
                    </v-sheet>

                    <!-- 수정, 취소 버튼 -->
                    <div v-if="item.isUpdating" class="mt-2 mb-4 d-flex justify-end">
                        <v-btn
                            class="rounded-10"
                            width="90"
                            height="35"
                            dark
                            depressed
                            color="primary"
                            @click="comment_update_submit(item)"
                        >
                            수정
                        </v-btn>
                        <v-btn
                            class="rounded-10 ml-2"
                            width="90"
                            height="35"
                            dark
                            depressed
                            color="primary"
                            @click="item.isUpdating = false; load()"
                        >
                            취소
                        </v-btn>
                    </div>
                </div>

                <div class="d-flex justify-end">
                    <v-btn
                        x-small
                        depressed
                        text
                        @click="item.isSubWriting = !item.isSubWriting"
                    >
                        답글
                    </v-btn>
                </div>

                <!-- 대댓글 목록 -->
                <SubCommentList
                    v-if="item.sub_comment_count"
                    :comment_id="item.id"
                    :writer="writer"
                    :key="componentKey"
                />

                <!-- 대댓글 쓰기 -->
                <v-sheet
                    v-if="item.isSubWriting"
                    class="mt-2"
                >
                    <!-- 입력칸 -->
                    <div class="d-flex">
                        <v-sheet class="mr-7">
                            <v-icon size="20">mdi-arrow-right-bottom</v-icon>
                        </v-sheet>
                        <v-sheet outlined class="py-1" width="100%">
                            <v-textarea
                                style="font-size:13px;"
                                hide-details 
                                flat
                                solo
                                v-model="subComment_content"
                                :placeholder="ads.text"
                            ></v-textarea>
                        </v-sheet>
                    </div>

                    <!-- 댓글 입력 Submit -->
                    <v-sheet class="d-flex mt-4 pb-1">
                        <v-spacer></v-spacer>
                        <v-btn
                            width="120"
                            height="35"
                            class="rounded-10"
                            dark
                            depressed
                            color="#23D2E2"
                            @click="subComment_write(item)"
                        >
                            대댓글 쓰기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>
        
        <!-- 댓글 쓰기 -->
        <v-sheet class="px-2">
            <!-- 입력칸 -->
            <v-sheet outlined class="py-1">
                <v-textarea
                    style="font-size:13px;"
                    hide-details 
                    flat
                    solo
                    v-model="comment_content"
                    :placeholder="ads.text"
                ></v-textarea>
            </v-sheet>

            <!-- 댓글 입력 Submit -->
            <v-sheet class="d-flex mt-4 pb-1">
                <v-spacer></v-spacer>
                <v-btn
                    width="90"
                    height="35"
                    class="rounded-10"
                    dark
                    depressed
                    color="#23D2E2"
                    @click="comment_write()"
                >
                    댓글 쓰기
                </v-btn>
            </v-sheet>
        </v-sheet>

        <!-- 댓글 신고하기 Dialog -->
        <v-dialog v-model="dialog.reportComment" width="800" content-class="rounded-15">
            <v-sheet class="py-8 px-12" color="#F8F7FB">
                <!-- 제목 -->
                <p
                    class="text-center font-weight-bold mb-8"
                    style="font-size:20px;"
                >
                    댓글 신고하기
                </p>

                <!-- 신고 댓글 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:110px;">신고 댓글</span>
                        <v-sheet class="text-truncate" style="width:440px;">
                            {{reportComment.reported_content}}
                        </v-sheet>
                    </div>
                </v-sheet>

                <!-- 신고 대상자 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:95px;">신고 대상자</span>
                        {{reportComment.writer}}
                    </div>
                </v-sheet>

                <!-- 신고 항목 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:230px;">신고 항목</span>
                        <v-select
                            style="max-width:220px;"
                            placeholder="신고 항목"
                            dense
                            hide-details
                            solo
                            flat
                            :items="select_list.report"
                            v-model="reportComment.category"
                        >
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 신고 내용 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">신고 내용</span>
                    <v-textarea
                        class="no_under"
                        rows="4"
                        hide-details
                        v-model="reportComment.content"
                        placeholder="내용을 입력해주세요"
                    ></v-textarea>
                </v-sheet>

                <!-- 버튼 -->
                <div class="mt-6 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="#615DFA"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="report_comment_submit()"
                    >
                        신고
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="white"
                        class="font-weight-bold rounded-10"
                        @click="dialog.reportComment=false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
import Avatar from "@/components/Avatar"
import GradeCheck from '@/components/GradeCheck.js'
import PointModule from '@/components/PointModule.js'
import BanModule from '@/components/BanModule.js'
import SubCommentList from './comment/subcomment'

export default {
    props: [
        "board_name",
        "title",
        "writer"
    ],

    components: {
        Avatar,
        SubCommentList
    },

    data: () => ({
        comment_content: "",
        comment_list: [],

        subComment_content: "",

        ads: {
            text: ""
        },

        reportComment: {
            reported_content: "",
            id: 0,
            writer: "",
            category: "",
            content: ""
        },

        select_list: {
            report: [
                "욕설/비방",
                "도배",
                "홍보/사업성",
                "음란성",
                "분란조장",
                "시세조작",
                "기타"
            ]
        },

        dialog: {
            reportComment: false
        },

        componentKey: 0,

        mypoint: 0
    }),

    mounted(){
        // 댓글 목록
        this.load()

        // 광고 문구 가져오기
        this.loadAds()

        // 드로우할 포인트 조회
        this.$http.post('/api/user/select/specific', {
            params: {
                user_id: this.$store.state.user_id
            }
        }).then((res) => {
            this.mypoint = res.data[0].point
        })
    },

    methods: {
        // 댓글 목록
        load(){
            this.$http.post('/api/board/read/comment/list', {
                params: {
                    post_id:this.$route.query.id
                }
            }).then((res) => {
                this.comment_list = res.data
                this.$emit("updated")
            })
        },

        // 광고 문구 가져오기
        loadAds(){
            this.$http.post('/api/admin/board/ads/import').then((res) => {
                this.ads = res.data[0]
            })
        },

        // 댓글 달기
        async comment_write(){
            let is_banned = await BanModule.ban_check(this.$store.state.user_id)

            if(is_banned){
                alert("밴 유저는 밴 기간동안 댓글 달기가 제한됩니다.")
                return
            }

            if(!this.$store.state.is_logined){
                alert("댓글 달기는 로그인 이후 가능합니다.")
            }else{
                if(this.comment_content == "")
                {
                    alert("댓글 내용을 입력해주세요")
                }
                // 드로우
                if(this.comment_content == "/드로우" && this.board_name == "자유 게시판")
                {
                    if(confirm("덱을 드로우 하려면 100p가 차감됩니다"))
                    {
                        if(this.mypoint < 100)
                        {
                            alert("포인트가 부족하여 덱을 드로우 할 수 없습니다")
                        }
                        else
                        {
                            PointModule.Update(this.$store.state.user_id, -100)
                            PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, -100)


                            let randomNumber = Math.floor(Math.random() * 100)

                            if(randomNumber <= 30)
                            {
                                this.comment_content = "C"
                                PointModule.Update(this.$store.state.user_id, 50)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 50)
                            }
                            else if(randomNumber >= 31 && randomNumber <= 51)
                            {
                                this.comment_content = "U"
                                PointModule.Update(this.$store.state.user_id, 70)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 70)
                            }
                            else if(randomNumber >= 52 && randomNumber <= 66)
                            {
                                this.comment_content = "R"
                                PointModule.Update(this.$store.state.user_id, 90)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 90)
                            }
                            else if(randomNumber >= 67 && randomNumber <= 81)
                            {
                                this.comment_content = "RR"
                                PointModule.Update(this.$store.state.user_id, 95)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 95)
                            }
                            else if(randomNumber >= 82 && randomNumber <= 91)
                            {
                                this.comment_content = "RRR"
                                PointModule.Update(this.$store.state.user_id, 100)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 100)
                            }
                            else if(randomNumber >= 92 && randomNumber <= 96)
                            {
                                this.comment_content = "SR"
                                PointModule.Update(this.$store.state.user_id, 150)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 150)
                            }
                            else if(randomNumber >= 97 && randomNumber <= 98)
                            {
                                this.comment_content = "HR"
                                PointModule.Update(this.$store.state.user_id, 200)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 200)
                            }
                            else if(randomNumber == 99)
                            {
                                this.comment_content = "UR"
                                PointModule.Update(this.$store.state.user_id, 500)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 500)
                            }

                            GradeCheck.compare(this.$store.state.user_id, 'comment', 1)
                            .then(() => {
                                // 댓글 입력
                                this.$http.post('/api/board/read/comment/insert/draw', {
                                    params: {
                                        post_id: this.$route.query.id,
                                        user_id: this.$store.state.user_id,
                                        content: this.comment_content
                                    }
                                }).then((res) => {
                                    // 포인트 적립
                                    if(this.$route.query.board_group=='커뮤니티' && this.board_name=='인증/카드자랑'){
                                        PointModule.amountCheck('카드자랑', '댓글').then((res) => {
                                            PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                            PointModule.Record(this.$store.state.user_id, '카드자랑', '댓글', this.title, res.data[0].amount)
                                        })
                                    }else if(this.$route.query.board_group=='공략/배틀'){
                                        PointModule.amountCheck('공략게시판', '댓글').then((res) => {
                                            PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                            PointModule.Record(this.$store.state.user_id, '공략게시판', '댓글', this.title, res.data[0].amount)
                                        })
                                    }else{
                                        PointModule.amountCheck('일반게시판', '댓글').then((res) => {
                                            PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                            PointModule.Record(this.$store.state.user_id, '일반게시판', '댓글', this.title, res.data[0].amount)
                                        })
                                    }

                                    this.comment_content = ""

                                    // DB 알림
                                    // this.$dbAlarm(
                                    //     "커뮤니티 - 댓글",
                                    //     this.$store.state.nickname,
                                    //     this.$store.state.user_id
                                    // )

                                    // 댓글 목록
                                    this.load()

                                    if(this.writer!=this.$store.state.user_id){
                                        // 글작성자에게 댓글 알림
                                        this.$http.post('/api/alarm/insert', {
                                            params: {
                                                user_id: this.writer,
                                                sender: this.$store.state.user_id,
                                                type: "comment",
                                                link: this.$route.fullPath
                                            }
                                        }).then(res => {
                                            console.log(res);
                                        })
                                    }       
                                })
                            })
                        }
                    }
                }

                // 일반 댓글
                else
                {
                    GradeCheck.compare(this.$store.state.user_id, 'comment', 1)
                    .then(() => {
                        // 댓글 입력
                        this.$http.post('/api/board/read/comment/insert', {
                            params: {
                                post_id: this.$route.query.id,
                                user_id: this.$store.state.user_id,
                                content: this.comment_content
                            }
                        }).then((res) => {
                            // 포인트 적립
                            if(this.$route.query.board_group=='커뮤니티' && this.board_name=='인증/카드자랑'){
                                PointModule.amountCheck('카드자랑', '댓글').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '카드자랑', '댓글', this.title, res.data[0].amount)
                                })
                            }else if(this.$route.query.board_group=='공략/배틀'){
                                PointModule.amountCheck('공략게시판', '댓글').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '공략게시판', '댓글', this.title, res.data[0].amount)
                                })
                            }else{
                                PointModule.amountCheck('일반게시판', '댓글').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '일반게시판', '댓글', this.title, res.data[0].amount)
                                })
                            }

                            this.comment_content = ""

                            // DB 알림
                            // this.$dbAlarm(
                            //     "커뮤니티 - 댓글",
                            //     this.$store.state.nickname,
                            //     this.$store.state.user_id
                            // )

                            // 댓글 목록
                            this.load()

                            if(this.writer!=this.$store.state.user_id){
                                // 글작성자에게 댓글 알림
                                this.$http.post('/api/alarm/insert', {
                                    params: {
                                        user_id: this.writer,
                                        sender: this.$store.state.user_id,
                                        type: "comment",
                                        link: this.$route.fullPath
                                    }
                                }).then(res => {
                                    console.log(res);
                                })
                            }       
                        })
                    })
                }
            }
        },

        // 댓글 삭제
        delete_comment(item){
            if(confirm("정말 해당 댓글을 삭제하시겠습니까?")){
                this.$http.post("/api/board/read/comment/is_subcommented", {
                    params: {
                        comment_id: item.id
                    }
                }).then((res) => {
                    if(!res.data.length){
                        this.$http.post('/api/board/read/comment/delete', {
                            params: {
                                id: item.id
                            }
                        }).then(() => {
                            alert('댓글이 삭제되었습니다.')

                            GradeCheck.compare(this.$store.state.user_id, 'comment', -1)
                            .then(() => {
                                // 포인트 적립 취소
                                if(this.$route.query.board_group=='커뮤니티' && this.board_name=='인증/카드자랑'){
                                    PointModule.amountCheck('카드자랑', '댓글').then((res) => {
                                        PointModule.Update(this.$store.state.user_id, -res.data[0].amount)
                                        PointModule.Record(this.$store.state.user_id, '카드자랑', '댓글', this.title, -res.data[0].amount)
                                    })
                                }else if(this.$route.query.board_group=='공략/배틀'){
                                    PointModule.amountCheck('공략게시판', '댓글').then((res) => {
                                        PointModule.Update(this.$store.state.user_id, -res.data[0].amount)
                                        PointModule.Record(this.$store.state.user_id, '공략게시판', '댓글', this.title, -res.data[0].amount)
                                    })
                                }else{
                                    PointModule.amountCheck('일반게시판', '댓글').then((res) => {
                                        PointModule.Update(this.$store.state.user_id, -res.data[0].amount)
                                        PointModule.Record(this.$store.state.user_id, '일반게시판', '댓글', this.title, -res.data[0].amount)
                                    })
                                }
                            })

                            // 댓글 목록
                            this.load()
                        })
                    }else{
                        this.$http.post("/api/board/read/comment/update/status/delete", {
                            params: {
                                id: item.id
                            }
                        }).then((res2) => {
                            console.log(res2)

                            alert('댓글이 삭제처리되었습니다.')

                            GradeCheck.compare(this.$store.state.user_id, 'comment', -1)
                            .then(() => {
                                // 포인트 적립 취소
                                if(this.$route.query.board_group=='커뮤니티' && this.board_name=='인증/카드자랑'){
                                    PointModule.amountCheck('카드자랑', '댓글').then((res) => {
                                        PointModule.Update(this.$store.state.user_id, -res.data[0].amount)
                                        PointModule.Record(this.$store.state.user_id, '카드자랑', '댓글', this.title, -res.data[0].amount)
                                    })
                                }else if(this.$route.query.board_group=='공략/배틀'){
                                    PointModule.amountCheck('공략게시판', '댓글').then((res) => {
                                        PointModule.Update(this.$store.state.user_id, -res.data[0].amount)
                                        PointModule.Record(this.$store.state.user_id, '공략게시판', '댓글', this.title, -res.data[0].amount)
                                    })
                                }else{
                                    PointModule.amountCheck('일반게시판', '댓글').then((res) => {
                                        PointModule.Update(this.$store.state.user_id, -res.data[0].amount)
                                        PointModule.Record(this.$store.state.user_id, '일반게시판', '댓글', this.title, -res.data[0].amount)
                                    })
                                }
                            })

                            // 댓글 목록
                            this.load()
                        })
                    }
                })
            }
        },

        // 댓글 수정
        comment_update_submit(item){
            this.$http.post('/api/board/read/comment/update', {
                params: {
                    id :item.id,
                    content :item.content
                }
            }).then(() => {
                // DB 알림
                // this.$dbAlarm(
                //     "커뮤니티 - 댓글",
                //     this.$store.state.nickname,
                //     this.$store.state.user_id
                // )

                // 댓글 목록
                this.load()
            })
        },

        // 댓글 블라인드 처리
        blind_comment(item){
            if(confirm("정말 해당 댓글을 블라인드 처리하시겠습니까?")){
                this.$http.post('/api/board/read/comment/blind', {
                    params: {
                        id : item.id
                    }
                }).then(() => {
                    alert('해당 댓글이 블라인드 처리되었습니다')

                    // 댓글 목록
                    this.load()
                })
            }
        },

        // 댓글 블라인드 해제
        unblind_comment(item){
            if(confirm("정말 해당 댓글을 블라인드 해제하시겠습니까?")){
                this.$http.post('/api/board/read/comment/unblind', {
                    params: {
                        id : item.id
                    }
                }).then(() => {
                    alert('해당 댓글이 블라인드 해제되었습니다')
                    
                    // 댓글 목록
                    this.load()
                })
            }
        },

        // 댓글 신고하기 열기
        report_comment_open(item){
            if(!this.$store.state.is_logined){
                alert("신고하기는 로그인 후 이용가능합니다")
            }else {
                this.dialog.reportComment = true
                this.reportComment.id = item.id
                this.reportComment.writer = item.user_id
                this.reportComment.reported_content = item.content
            }
        },

        // 댓글 신고하기 제출
        report_comment_submit(){
            if(this.reportComment.category == ""){
                alert("신고 항목을 선택해주세요")
            }else if(this.reportComment.content == ""){
                alert("신고 내용을 입력해주세요")
            }else{
                this.$http.post('/api/admin/user/reported/insert', {
                    params: {
                        reporter_user_id: this.$store.state.user_id,
                        reported_user_id: this.reportComment.writer,
                        type: "board_comment",
                        reported_id: this.reportComment.id,
                        category: this.reportComment.category,
                        content: this.reportComment.content,
                        link: this.$route.fullPath+"&reported_comment_id="+this.reportComment.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        // DB 알림
                        // this.$dbAlarm(
                        //     "신고 - 댓글",
                        //     this.nickname,
                        //     this.user_id
                        // )

                        alert("신고가 접수되었습니다.")
                        this.dialog.reportComment = false
                    }
                })
            }
        },

        // 댓글 추천
        async comment_like(item){
            let is_banned = await BanModule.ban_check(this.$store.state.user_id)

            if(is_banned){
                alert("밴 유저는 댓글 추천이 제한됩니다.")
                return
            }

            if(!this.$store.state.is_logined){
                alert("댓글 추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.user_id == item.user_id){
                alert('자신의 댓글에 추천을 할 수 없습니다.')
            }else{
                this.$http.post('/api/board/read/comment/like/list', {
                    params: {
                        comment_id: item.id,
                        user_id: this.$store.state.user_id
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("이미 추천한 댓글입니다.")
                    }else{
                        this.$http.post('/api/board/read/comment/like', {
                            params: {
                                comment_id: item.id,
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            // 댓글 목록
                            this.load()

                            // 댓글 작성자에게 좋아요 알림
                            this.$http.post('/api/alarm/insert', {
                                params: {
                                    user_id: item.user_id,
                                    sender: this.$store.state.user_id,
                                    type: "comment_liked",
                                    link: this.$route.fullPath
                                }
                            }).then(res => {
                                console.log(res)
                            })
                        })
                    }
                })
            }
        },

        // 댓글 비추천
        async comment_dislike(item){
            let is_banned = await BanModule.ban_check(this.$store.state.user_id)

            if(is_banned){
                alert("밴 유저는 댓글 비추천이 제한됩니다.")
                return
            }

            if(!this.$store.state.is_logined){
                alert("댓글 비추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.user_id == item.user_id){
                alert('자신의 댓글에 비추천을 할 수 없습니다.')
            }else{
                this.$http.post('/api/board/read/comment/dislike/list', {
                    params: {
                        comment_id: item.id,
                        user_id: this.$store.state.user_id
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("이미 비추천한 댓글입니다.")
                    }else{
                        this.$http.post('/api/board/read/comment/dislike', {
                            params: {
                                comment_id: item.id,
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            // 댓글 목록
                            this.load()

                            // 댓글 작성자에게 싫어요 알림
                            this.$http.post('/api/alarm/insert', {
                                params: {
                                    user_id: item.user_id,
                                    sender: this.$store.state.user_id,
                                    type: "comment_disliked",
                                    link: this.$route.fullPath
                                }
                            }).then(res => {
                                console.log(res)
                            })
                        })
                    }
                })
            }
        },

        // 대댓글 달기
        async subComment_write(item){
            let is_banned = await BanModule.ban_check(this.$store.state.user_id)

            if(is_banned){
                alert("밴 유저는 밴 기간동안 댓글 달기가 제한됩니다.")
                return
            }
            
            if(!this.$store.state.is_logined){
                alert("댓글 달기는 로그인 이후 가능합니다.")
            }else{
                if(this.subComment_content == "")
                {
                    alert("댓글 내용을 입력해주세요")
                }
                // 드로우
                if(this.subComment_content == "/드로우" && this.board_name == "자유 게시판")
                {
                    if(confirm("덱을 드로우 하려면 100p가 차감됩니다"))
                    {
                        if(this.mypoint < 100)
                        {
                            alert("포인트가 부족하여 덱을 드로우 할 수 없습니다")
                        }
                        else
                        {
                            PointModule.Update(this.$store.state.user_id, -100)
                            PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, -100)


                            let randomNumber = Math.floor(Math.random() * 100)

                            if(randomNumber <= 30)
                            {
                                this.subComment_content = "C"
                                PointModule.Update(this.$store.state.user_id, 50)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 50)
                            }
                            else if(randomNumber >= 31 && randomNumber <= 51)
                            {
                                this.subComment_content = "U"
                                PointModule.Update(this.$store.state.user_id, 70)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 70)
                            }
                            else if(randomNumber >= 52 && randomNumber <= 66)
                            {
                                this.subComment_content = "R"
                                PointModule.Update(this.$store.state.user_id, 90)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 90)
                            }
                            else if(randomNumber >= 67 && randomNumber <= 81)
                            {
                                this.subComment_content = "RR"
                                PointModule.Update(this.$store.state.user_id, 95)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 95)
                            }
                            else if(randomNumber >= 82 && randomNumber <= 91)
                            {
                                this.subComment_content = "RRR"
                                PointModule.Update(this.$store.state.user_id, 100)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 100)
                            }
                            else if(randomNumber >= 92 && randomNumber <= 96)
                            {
                                this.subComment_content = "SR"
                                PointModule.Update(this.$store.state.user_id, 150)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 150)
                            }
                            else if(randomNumber >= 97 && randomNumber <= 98)
                            {
                                this.subComment_content = "HR"
                                PointModule.Update(this.$store.state.user_id, 200)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 200)
                            }
                            else if(randomNumber == 99)
                            {
                                this.subComment_content = "UR"
                                PointModule.Update(this.$store.state.user_id, 500)
                                PointModule.Record(this.$store.state.user_id, '자유게시판', '댓글 드로우', this.title, 500)
                            }

                            GradeCheck.compare(this.$store.state.user_id, 'comment', 1)
                            .then(() => {
                                // 댓글 입력
                                this.$http.post('/api/board/read/subcomment/insert/draw', {
                                    params: {
                                        post_id: this.$route.query.id,
                                        comment_id: item.id,
                                        user_id: this.$store.state.user_id,
                                        content: this.subComment_content
                                    }
                                }).then((res) => {
                                    // 포인트 적립
                                    if(this.$route.query.board_group=='커뮤니티' && this.board_name=='인증/카드자랑'){
                                        PointModule.amountCheck('카드자랑', '댓글').then((res) => {
                                            PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                            PointModule.Record(this.$store.state.user_id, '카드자랑', '댓글', this.title, res.data[0].amount)
                                        })
                                    }else if(this.$route.query.board_group=='공략/배틀'){
                                        PointModule.amountCheck('공략게시판', '댓글').then((res) => {
                                            PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                            PointModule.Record(this.$store.state.user_id, '공략게시판', '댓글', this.title, res.data[0].amount)
                                        })
                                    }else{
                                        PointModule.amountCheck('일반게시판', '댓글').then((res) => {
                                            PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                            PointModule.Record(this.$store.state.user_id, '일반게시판', '댓글', this.title, res.data[0].amount)
                                        })
                                    }

                                    this.subComment_content = ""

                                    // DB 알림
                                    // this.$dbAlarm(
                                    //     "커뮤니티 - 댓글",
                                    //     this.$store.state.nickname,
                                    //     this.$store.state.user_id
                                    // )

                                    // 댓글 목록
                                    this.load()

                                    // 대댓글 목록 새로고침
                                    this.componentKey++

                                    if(item.user_id != this.$store.state.user_id){
                                        // 댓글작성자에게 대댓글 알림
                                        this.$http.post('/api/alarm/insert', {
                                            params: {
                                                user_id: item.user_id,
                                                sender: this.$store.state.user_id,
                                                type: "sub_comment",
                                                link: this.$route.fullPath
                                            }
                                        }).then(res => {
                                            console.log(res)
                                        })
                                    }    
                                })
                            })
                        }
                    }
                }

                // 일반 댓글
                else
                {
                    GradeCheck.compare(this.$store.state.user_id, 'comment', 1)
                    .then(() => {
                        // 댓글 입력
                        this.$http.post('/api/board/read/subcomment/insert', {
                            params: {
                                post_id: this.$route.query.id,
                                comment_id: item.id,
                                user_id: this.$store.state.user_id,
                                content: this.subComment_content
                            }
                        }).then((res) => {
                            // 포인트 적립
                            if(this.$route.query.board_group=='커뮤니티' && this.board_name=='인증/카드자랑'){
                                PointModule.amountCheck('카드자랑', '댓글').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '카드자랑', '댓글', this.title, res.data[0].amount)
                                })
                            }else if(this.$route.query.board_group=='공략/배틀'){
                                PointModule.amountCheck('공략게시판', '댓글').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '공략게시판', '댓글', this.title, res.data[0].amount)
                                })
                            }else{
                                PointModule.amountCheck('일반게시판', '댓글').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '일반게시판', '댓글', this.title, res.data[0].amount)
                                })
                            }

                            this.subComment_content = ""

                            // DB 알림
                            // this.$dbAlarm(
                            //     "커뮤니티 - 댓글",
                            //     this.$store.state.nickname,
                            //     this.$store.state.user_id
                            // )

                            // 댓글 목록
                            this.load()

                            // 대댓글 목록 새로고침
                            this.componentKey++

                            if(item.user_id != this.$store.state.user_id){
                                // 댓글작성자에게 대댓글 알림
                                this.$http.post('/api/alarm/insert', {
                                    params: {
                                        user_id: item.user_id,
                                        sender: this.$store.state.user_id,
                                        type: "sub_comment",
                                        link: this.$route.fullPath
                                    }
                                }).then(res => {
                                    console.log(res)
                                })
                            }    
                        })
                    })
                }
            }
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>