<template>
    <div
        class="d-flex align-center rounded-10 py-2"
        style="position:relative;"
    >
        <!-- 제목 -->
        <div
            class="ml-4"
        >
            <!-- 콜렉션북 -->
            <div
                v-if="!$route.path.startsWith('/collection/challengebook')"
            >
                <p
                    class="mt-2 mb-1 font-weight-bold"
                    style="font-size:26px; cursor:pointer;"
                    @click="$route.query.user_id? $router.push('/collection/dashboard?user_id='+$route.query.user_id) : $router.push('/collection/dashboard')"
                >
                    콜렉션북
                </p>
                <p
                    v-if="!$route.path.startsWith('/collection/collect')"
                    style="font-size:11px;"
                >
                    콜렉팅 항목을 등록하고 공유할 수 있습니다.
                </p>
                <div
                    v-if="$route.path.startsWith('/collection/collect')"
                    style="height:20px;"
                ></div>
            </div>

            <!-- 챌린지북 -->
            <div
                v-if="$route.path.startsWith('/collection/challengebook')"
            >
                <p
                    class="mt-2 mb-1 font-weight-bold"
                    style="font-size:26px; cursor:pointer;"
                    @click="$route.query.user_id? $router.push('/collection/challengebook/list?user_id='+$route.query.user_id) : $router.push('/collection/challengebook/list')"
                >
                    챌린지북
                </p>
                <p style="font-size:11px;">
                    특별한 수집라인을 설정하고 콜렉팅에 도전해보세요
                </p>
            </div>
        </div>

        <!-- 공개유무 switch -->
        <v-switch
            v-if="!$route.path.startsWith('/collection/collect') && !$route.path.startsWith('/collection/challengebook')"
            style="position:absolute; top:0px; left:120px;"
            class="ml-4 pt-2 font-weight-bold"
            v-model="open"
            inset
            :disabled="user_id != $store.state.user_id"
            :label="open? '공개':'비공개'"
            @change="openChanged()"
        ></v-switch>

        <v-spacer></v-spacer>

        <!-- 유저 -->
        <v-sheet
            v-if="!($route.path.startsWith('/collection/collect') || $route.path.startsWith('/collection/challengebook'))"
            height="61"
            class="mt-1 mr-3 pl-4 pr-6 rounded-15 d-flex justify-space-between align-center"
        >
            <Avatar
                v-if="user_id"
                :user="user_id"
                :color="'black'"
            />
            <p
                class="mb-0"
                style="font-size:12px; color:#424361; font-weight:bold; margin-left:12px;"
            >
                {{user_profile.nickname}}
            </p>
        </v-sheet>

        <!-- 목록 -->
        <v-sheet
            v-if="$route.path == '/collection/challengebook/read'"
            class="mt-1 pt-1 mr-2 pl-4 pr-6 rounded-15 d-flex justify-space-between"
        >
            <v-btn
                height="56"
                class="px-0"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                @click="$router.go(-1)"
            >
                <v-icon>mdi-chevron-left</v-icon>
                뒤로
            </v-btn>
        </v-sheet>

        <!-- 메뉴 -->
        <v-sheet class="mt-1 pt-1 px-7 rounded-15 d-flex justify-space-between">
            <v-btn
                height="56"
                class="px-0 mr-8"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="$route.path.startsWith('/collection/challengebook')? 'primary':''"
                :style="$route.path.startsWith('/collection/challengebook')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid #FFFFFF;'"
                :dark="$route.path.startsWith('/collection/challengebook')"
                @click="$route.query.user_id? $router.push('/collection/challengebook/list?user_id='+$route.query.user_id) : $router.push('/collection/challengebook/list')"
            >
                챌린지북
            </v-btn>
            <v-btn
                height="56"
                class="px-0 mr-8"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="$route.path.startsWith('/collection/dashboard')? 'primary':''"
                :style="$route.path.startsWith('/collection/dashboard')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid #FFFFFF;'"
                :dark="$route.path.startsWith('/collection/dashboard')"
                @click="$route.query.user_id? $router.push('/collection/dashboard?user_id='+$route.query.user_id) : $router.push('/collection/dashboard')"
            >
                집계현황
            </v-btn>
            <v-btn
                height="56"
                class="px-0"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="$route.path.startsWith('/collection/list')? 'primary':''"
                :style="$route.path.startsWith('/collection/list')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid #FFFFFF;'"
                :dark="$route.path.startsWith('/collection/list')"
                @click="$route.query.user_id? $router.push('/collection/list?user_id='+$route.query.user_id) : $router.push('/collection/list')"
            >
                콜렉션북
            </v-btn>
        </v-sheet>

        <!-- 메뉴 -->
        <v-sheet
            v-if="$route.path.startsWith('/collection/collect')"
            class="ml-3 mt-1 pt-1 px-9 rounded-15 d-flex justify-space-between"
        >
            <v-btn
                height="56"
                class="px-0 mr-8"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="card_nation == 'kr'? 'primary':''"
                :style="card_nation == 'kr'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                :dark="card_nation == 'kr'"
                @click="changeCardNation('kr')"
            >
                국내판
            </v-btn>
            <v-btn
                height="56"
                class="px-0 mr-8"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="card_nation == 'en'? 'primary':''"
                :style="card_nation == 'en'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                :dark="card_nation == 'en'"
                @click="changeCardNation('en')"
            >
                북미판
            </v-btn>
            <v-btn
                height="56"
                class="px-0"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="card_nation == 'jp'? 'primary':''"
                :style="card_nation == 'jp'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                :dark="card_nation == 'jp'"
                @click="changeCardNation('jp')"
            >
                일본판
            </v-btn>
        </v-sheet>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import Avatar from '@/components/Avatar'

export default {
    components: {
        Avatar
    },

    data: () => ({
        open: null,
        card_nation: "kr",
        user_id: "",
        user_profile: {},
    }),

    mounted(){
        if(this.$route.query.user_id){
            this.user_id = this.$route.query.user_id
            this.open = true
        }else{
            this.user_id = this.$store.state.user_id
            this.open = this.$store.state.collection_show
        }

        // 집계현황(제품명 목록)에서 클릭한 발매국가 가져와서 적용하기 
        if(this.$route.path.startsWith('/collection/collect')){
            this.card_nation = this.$route.query.nation
        }

        this.importUserProfile()
    },

    methods: {
        ...mapMutations(['update_collection_show']),

        // 유저 정보 가져오기
        importUserProfile(){
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.user_id
                }
            }).then(res => {
                this.user_profile = res.data[0]

                if(this.user_profile.type=='사용자'){
                    if(this.user_profile.grade==0){
                        this.user_icon =  'new'
                    }else{
                        this.user_icon =  this.user_profile.grade
                    }
                }else if(this.user_profile.type=='밴 유저'){
                    this.user_icon =  'banned'
                }else if(this.user_profile.type=='관리자'){
                    this.user_icon =  'admin'
                }else if(this.user_profile.type=='매니저'){
                    this.user_icon =  'manager'
                }else if(this.user_profile.type=='운영진'){
                    this.user_icon =  'board_manager'
                }else if(this.user_profile.type=='테스터'){
                    this.user_icon =  'tester'
                }
            })
        },

        changeCardNation(card_nation){
            if(!this.$route.path.startsWith('/collection/list'))
            {
                this.$router.push('/collection/list?nation='+card_nation)
            }
            else
            {
                this.card_nation = card_nation
                this.$emit("nationUpdated", card_nation)
            }
        },

        openChanged(){
            this.$emit("openChanged")
            this.update_collection_show(this.open)

            this.$http.post("/api/user/update/collection_show", {
                params: {
                    user_id: this.$store.state.user_id,
                    collection_show: this.open
                }
            }).then((res) => {
                console.log(res)
            })
        }
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>