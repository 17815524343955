<template>
    <v-sheet class="pa-2" height="100vh" style="overflow:auto;">
        <!-- 검색 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mobile" style="overflow:auto;"
        >
            <!-- 기본 조건 -->
            <v-sheet
                class="px-5 py-3"
            >
                <div class="d-flex mb-2">
                    <!-- SUPERTYPE -->
                    <v-select
                        height="34"
                        style="width:50%;"
                        class="rounded-10"
                        label="카드종류"
                        dense
                        outlined
                        hide-details
                        :items="select_list.supertype"
                        item-text="name"
                        item-value="value"
                        v-model="keyword.supertype"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-1">{{item.name}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- TEXT_TYPE -->
                    <v-select
                        height="34"
                        style="width:50%;"
                        class="ml-2 rounded-10"
                        label="검색범위"
                        dense
                        outlined
                        hide-details
                        placeholder="전체"
                        persistent-placeholder
                        :items="select_list.text_type"
                        v-model="keyword.text_type"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-1">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </div>
                <div class="d-flex">
                    <!-- TEXT -->
                    <v-text-field
                        ref="searchInput"
                        height="34"
                        class="rounded-10"
                        outlined
                        hide-details
                        dense
                        placeholder="한글/영어/일어 모두 검색 가능합니다."
                        persistent-placeholder
                        v-model="keyword.text"
                        append-icon="mdi-magnify"
                        @keyup.enter="submit(false)"
                    >
                        <template v-slot:append>
                            <v-icon size="20" color="#424361">mdi-magnify</v-icon>
                        </template>
                    </v-text-field>

                    <!-- 검색 버튼 -->
                    <v-btn
                        depressed
                        dark
                        x-small
                        class="ml-2 rounded-10 mr-1"
                        style="font-size:13px;"
                        color="primary"
                        width="41"
                        height="34"
                        @click="submit(false)"
                    >
                        검색
                    </v-btn>

                    <!-- 상세검색 -->
                    <v-btn
                        width="41"
                        height="34"
                        class="rounded-10"
                        style="font-size:12px; font-weight:400;"
                        color="primary"
                        depressed
                        dark
                        x-small
                        @click="switchDetail=!switchDetail"
                    >
                        {{switchDetail? '접기':'상세'}}
                    </v-btn>
                </div>
            </v-sheet>
        </div>

        <!-- 상세조건 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile && switchDetail"
            class="px-3 mx-2 mobile"
        >
            <v-sheet>
                <div class="d-flex mb-2">
                    <!-- 제품명 -->
                    <v-select
                        height="34"
                        style="width:34%;"
                        class="rounded-10"
                        label="제품명"
                        dense
                        outlined
                        hide-details
                        :items="select_list.set.nation"
                        v-model="keyword.set.nation"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- 제품명 -->
                    <v-autocomplete
                        height="34"
                        style="width:66%;"
                        class="ml-2 rounded-10"
                        label="제품명"
                        dense
                        outlined
                        hide-details
                        placeholder="선택"
                        persistent-placeholder
                        :items="set_list"
                        v-model="keyword.set.name"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                </div>

                <div class="d-flex mb-2">
                    <!-- 시리즈 -->
                    <v-select
                        height="34"
                        style="width:34%;"
                        class="rounded-10"
                        label="시리즈"
                        dense
                        outlined
                        hide-details
                        :items="select_list.series.nation"
                        v-model="keyword.series.nation"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- 시리즈 -->
                    <v-autocomplete
                        height="34"
                        style="width:66%;"
                        class="ml-2 rounded-10"
                        label="시리즈"
                        dense
                        outlined
                        hide-details
                        placeholder="선택"
                        persistent-placeholder
                        :items="series_list"
                        v-model="keyword.series.name"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                </div>

                <div class="d-flex mb-2">
                    <!-- 희귀도 -->
                    <v-autocomplete
                        height="34"
                        style="width:34%;"
                        class="rounded-10"
                        label="희귀도"
                        dense
                        outlined
                        multiple
                        hide-details
                        :items="select_list.rarity.select"
                        v-model="keyword.rarity.nation"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-1 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>

                    <!-- 희귀도 -->
                    <v-autocomplete
                        height="34"
                        style="width:66%;"
                        class="ml-2 rounded-10"
                        label="희귀도"
                        dense
                        outlined
                        hide-details
                        placeholder="선택"
                        persistent-placeholder
                        :items="computed_rarity_list"
                        v-model="keyword.rarity.name"
                        :menu-props="{ offsetY: true }"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-9px text-truncate">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                </div>
            </v-sheet>
        </div>

        <!-- 목록방식 & 순서 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mobile d-flex align-center px-5 py-1"
            style="background:#F8F7FB; overflow:auto;"
        >
            <!-- 순서 -->
            <v-select
                height="34"
                dense outlined hide-details
                class="rounded-10 mr-2 mt-1"
                :menu-props="{ offsetY: true }"
                :items="select_list.sequence"
                v-model="sequence"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-3">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <v-spacer></v-spacer>

            <!-- 목록 방식 (card) -->
            <v-btn
                width="45"
                height="34"
                x-small
                dark
                depressed
                class="rounded-10 mr-1"
                style="cursor:pointer;"
                :color="list_type=='card'? '#23D2E2':'#DFDFDF'"
                @click="list_type='card'"
            >
                <v-icon
                >
                    mdi-image
                </v-icon>
            </v-btn>

            <!-- 목록 방식 (list) -->
            <v-btn
                width="45"
                height="34"
                x-small
                dark
                depressed
                class="rounded-10"
                style="cursor:pointer;"
                :color="list_type=='list'? '#23D2E2':'#DFDFDF'"
                @click="list_type='list'"
            >
                <v-icon>
                    mdi-menu
                </v-icon>
            </v-btn>
        </div>

        <!-- 목록 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mt-2"
        >
            <!-- 검색중 -->
            <v-sheet
                v-if="!list_ready"
                height="240"
                class="pa-4 rounded-15 d-flex justify-center align-center"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-sheet>

            <!-- 검색 완료시 -->
            <v-sheet
                v-if="list_ready"
                class="pa-5 pb-16"
            >
                <!-- 검색 키워드 표시 -->
                <v-sheet>
                    <p
                        style="font-weight:600; font-size:13px; width:100%;"
                        v-html="replaceEnglishToIcon(search_keyword)"
                        class="mb-0 text-truncate"
                    ></p>
                    <p style="font-size:13px;">
                        <font v-if="keyword.nation == 'kr'" style="color:#23D2E2" class="mr-2">한글</font>
                        <font v-else-if="keyword.nation == 'jp'" style="color:#23D2E2" class="mr-2">일본</font>
                        <font v-else style="color:#23D2E2" class="mr-2">영어</font>
                        <font style="font-size:10px;">조건에 맞는 카드가 {{$toComma(list.length)}}개 검색 되었습니다</font>
                    </p>
                </v-sheet>

                <!-- 목록 방식 (card) -->
                <v-row no-gutters v-if="currentPageList.length && list_type=='card'">
                    <v-col class="pa-2" cols="6" v-for="item in currentPageList" :key="item.id">
                        <v-card width="140" style="cursor:pointer" class="mx-auto rounded-10">
                            <!-- 한글 이미지가 없을 경우 -->
                            <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="192">
                                <!-- 영어 이미지가 있을 경우 -->
                                <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                    <img width="140" height="192" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                </div>
                                <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                    <img width="140" height="192" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                </div>
                                <!-- 셋 다 이미지가 없는 경우 -->
                                <div v-else>
                                    <v-img width="140" height="192" src="@/assets/default.png"></v-img>
                                </div>
                            </v-sheet>
                            <!-- 한글 이미지가 있을 경우 -->
                            <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="192">
                                <!-- 한글 이미지 표시 -->
                                <img width="140" height="192" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                            </v-sheet>
                        </v-card>

                        <!-- 리본 -->
                        <v-sheet class="mt-2 d-flex justify-center">
                            <v-btn
                                x-small
                                dark
                                depressed
                                width="42"
                                height="36"
                                style="font-size:10px; line-height:10px; font-weight:400;"
                                class="rounded-10"
                                color="#615DFA"
                                @click="$emit('CardSelected', item, 'kr')"
                            >
                                <div>
                                    한글<br/>
                                    카드
                                </div>
                            </v-btn>
                            <v-btn
                                x-small
                                dark
                                depressed
                                width="42"
                                height="36"
                                style="font-size:10px; line-height:10px; font-weight:400;"
                                class="mx-1 rounded-10"
                                color="#615DFA"
                                @click="$emit('CardSelected', item, 'en')"
                            >
                                <div>
                                    영어<br/>
                                    카드
                                </div>
                            </v-btn>
                            <v-btn
                                x-small
                                dark
                                depressed
                                width="42"
                                height="36"
                                style="font-size:10px; line-height:10px; font-weight:400;"
                                class="rounded-10"
                                color="#615DFA"
                                @click="$emit('CardSelected', item, 'jp')"
                            >
                                <div>
                                    일어<br/>
                                    카드
                                </div>
                            </v-btn>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 목록 방식 (list) -->
                <v-row no-gutters v-if="currentPageList.length && list_type=='list'">
                    <v-col class="py-1" cols="12" v-for="item in currentPageList" :key="item.id">
                        <v-sheet outlined class="px-4 py-3 d-flex align-center rounded-15">
                            <v-card width="86" style="cursor:pointer">
                                <!-- 한글 이미지가 없을 경우 -->
                                <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="120">
                                    <!-- 영어 이미지가 있을 경우 -->
                                    <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                        <img width="86" height="120" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                    </div>
                                    <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                    <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                        <img width="86" height="120" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                    </div>
                                    <!-- 셋 다 이미지가 없는 경우 -->
                                    <div v-else>
                                        <v-img width="86" height="120" src="@/assets/default.png"></v-img>
                                    </div>
                                </v-sheet>
                                <!-- 한글 이미지가 있을 경우 -->
                                <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="120">
                                    <!-- 한글 이미지 표시 -->
                                    <img width="86" height="120" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                </v-sheet>
                            </v-card>

                            <v-sheet class="ml-2">
                                <!-- 카드이름 -->
                                <div class="mt-2px">
                                    <!-- 영어 -->
                                    <p
                                        v-if="search_nation=='en' && item.name_en"
                                        style="font-size:13px; font-weight:600; width:100%;"
                                        class="mb-1 pb-2px text-truncate"
                                    >
                                        {{item.name_en}}
                                    </p>

                                    <!-- 한글 -->
                                    <p
                                        v-if="search_nation=='kr' && item.name_kr"
                                        style="font-size:13px; font-weight:600; width:100%;"
                                        class="mb-1 pb-2px text-truncate"
                                    >
                                        {{item.name_kr}}
                                    </p>

                                    <!-- 일어 -->
                                    <p
                                        v-if="search_nation=='jp' && item.name_jp"
                                        style="font-size:13px; font-weight:600; width:100%;"
                                        class="mb-1 pb-2px text-truncate"
                                    >
                                        {{item.name_jp}}
                                    </p>
                                </div>

                                <!-- 레귤레이션, 넘버, 토탈넘버 & 희귀도 -->
                                <div class="d-flex">
                                    <!-- 레귤레이션 -->
                                    <p
                                        v-if="item.regulationMark"
                                        style="font-size:10px; font-weight:300;"
                                        class="mb-0"
                                    >
                                        <v-icon size="12">
                                            mdi-alpha-{{item.regulationMark.toLowerCase()}}-box-outline
                                        </v-icon>
                                    </p>

                                    <!-- 넘버, 토탈넘버 -->
                                    <div class="mr-2">
                                        <!-- 영어 -->
                                        <p
                                            v-if="search_nation=='en' && item.number_en && item.printedTotal_en"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.number_en}} / {{item.printedTotal_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="search_nation=='kr' && item.number_kr && item.printedTotal_kr"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.number_kr}} / {{item.printedTotal_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="search_nation=='jp' && item.number_jp && item.printedTotal_jp"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.number_jp}} / {{item.printedTotal_jp}}
                                        </p>
                                    </div>

                                    <!-- 희귀도 -->
                                    <div>
                                        <!-- 영어 -->
                                        <p
                                            v-if="search_nation=='en' && item.rarity_en"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.rarity_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="search_nation=='kr' && item.rarity_kr"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.rarity_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="search_nation=='jp' && item.rarity_jp"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            {{item.rarity_jp}}
                                        </p>
                                    </div>
                                </div>

                                <!-- 제품명 -->
                                <div>
                                    <!-- 영어 -->
                                    <p
                                        v-if="search_nation=='en' && item.set_en"
                                        style="font-size:10px; font-weight:300; height:26px; width:190px; line-height:13px; overflow:hidden; word-wrap:break-word;"
                                        class="mb-1"
                                    >
                                        {{item.set_en}}
                                    </p>

                                    <!-- 한글 -->
                                    <p
                                        v-if="search_nation=='kr' && item.set_kr"
                                        style="font-size:10px; font-weight:300; height:26px; width:190px; line-height:13px; overflow:hidden; word-wrap:break-word;"
                                        class="mb-1"
                                    >
                                        {{item.set_kr}}
                                    </p>

                                    <!-- 일어 -->
                                    <p
                                        v-if="search_nation=='jp' && item.set_jp"
                                        style="font-size:10px; font-weight:300; height:26px; width:190px; line-height:13px; overflow:hidden; word-wrap:break-word;"
                                        class="mb-1"
                                    >
                                        {{item.set_jp}}
                                    </p>
                                </div>

                                <!-- 리본 -->
                                <v-sheet class="mt-2 d-flex">
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="56"
                                        height="24"
                                        style="font-size:10px; font-weight:400;"
                                        class="rounded-lg"
                                        color="#615DFA"
                                        @click="$emit('CardSelected', item, 'kr')"
                                    >
                                        한글카드
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="56"
                                        height="24"
                                        style="font-size:10px; font-weight:400;"
                                        class="mx-1 rounded-lg"
                                        color="#615DFA"
                                        @click="$emit('CardSelected', item, 'en')"
                                    >
                                        영어카드
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        dark
                                        depressed
                                        width="56"
                                        height="24"
                                        style="font-size:10px; font-weight:400;"
                                        class="rounded-lg"
                                        color="#615DFA"
                                        @click="$emit('CardSelected', item, 'jp')"
                                    >
                                        일어카드
                                    </v-btn>
                                </v-sheet>
                            </v-sheet>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 검색결과가 없을 시 -->
                <v-row no-gutters v-if="!currentPageList.length">
                    <v-col class="py-16 text-center">
                        <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                        <p class="blue--text">검색 결과가 없습니다</p>
                    </v-col>
                </v-row>

                <!-- 버튼 & 페이지네이션 -->
                <v-sheet class="d-flex justify-center align-center pt-2 pb-4 mobile">
                    <v-pagination
                        color="primary"
                        v-model="page"
                        :length="pageLength"
                        :total-visible="7"
                    ></v-pagination>
                </v-sheet>
            </v-sheet>
        </div>
    </v-sheet>
</template>
<script>
export default {
    props: [ "isWrite", "is_collection", "nation", "set_name", "is_deck" ],

    data: () => ({
        // 상세조건 스위치
        switchDetail: false,

        // 타입이름
        types: [
            "Colorless",
            "Darkness",
            "Dragon",
            "Fairy",
            "Fighting",
            "Fire",
            "Grass",
            "Lightning",
            "Metal",
            "Psychic",
            "Water"
        ],

        // 선택목록
        select_list: {
            // 슈퍼타입
            supertype: [
                {name: '모든 카드',  value:'ALL'},
                {name: '에너지',  value:'Energy'},
                {name: '포켓몬', value:'Pokémon'},
                {name: '트레이너스', value:'Trainer'}
            ],

            text_type: [
                "전체",
                "도감 번호",
                "카드 이름",
                "특성 이름",
                "특성 설명",
                "기술 이름",
                "기술 설명"
            ],

            // 서브타입 (카드종류)
            subtypes: {
                All : [],
                Energy: [],
                Pokémon: [],
                Trainer: []
            },

            // 레귤레이션
            regulationMark: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],

            // 희귀도
            rarity: {
                select: ['한글 희귀도', '영어 희귀도', '일본 희귀도'],
                en: [],
                kr: [],
                jp: []
            },

            // 속성
            type: [
                "Colorless",
                "Darkness",
                "Dragon",
                "Fairy",
                "Fighting",
                "Fire",
                "Grass",
                "Lightning",
                "Metal",
                "Psychic",
                "Water"
            ],

            // 제품명
            set: {
                nation: ['한글 제품명', '영어 제품명', '일본 제품명'],
                en: [],
                kr: [],
                jp: []
            },

            // 시리즈
            series: {
                nation: ['한글 시리즈', '영어 시리즈', '일본 시리즈'],
                en: [],
                kr: [],
                jp: []
            },

            // 목록 순서
            sequence: [
                "발매 최신순",
                "발매 오래된순",
                "카드번호 순",
                "카드번호 역순",
                "HP 높은순",
                "HP 낮은순",
                "평점 높은순",
                "평점 낮은순"
            ],
        },

        // 검색조건
        keyword: {
            // 검색 언어
            nation: "en",

            // 기본조건
            supertype: "ALL",
            text_type: "전체",
            text: "",

            // 상세조건
            subtypes: [],
            regulationMark: [],
            types: [],
            set: {
                nation: "한글 제품명",
                name: ""
            },
            rarity: {
                nation: "한글 희귀도",
                name: ""
            },
            series: {
                nation: "한글 시리즈",
                name: ""
            },
            artist: "",

            hp_min: 0,
            hp_max: 0,

            weaknesses: null,
            retreatCost: 0,
            resistances: null,

            damage_min: 0,
            damage_max: 0,

            attacksCost: 0,

            isAbilityExist: false,

            is_deck: false,
            user_id: null
        },

        // 목록 & 페이징
        list: [],
        currentPageList: [],
        page: 1,
        pageLength:1,
        list_ready:false,

        // 검색 키워드 표기
        search_keyword: "",

        // 목록
        sequence: "발매 최신순",
        list_type: "card",

        search_nation: ""
    }),

    mounted(){
        if(this.is_deck == true){
            this.keyword.is_deck = true
            this.keyword.user_id = this.$store.state.user_id
        }else{
            this.keyword.is_deck = false
            this.keyword.user_id = null
        }

        // 레귤레이션 목록 불러오기
        this.loadRegulationMarkList()

        // 카드종류 목록 불러오기
        this.loadSubtypesList()

        // 희귀도 목록 불러오기
        this.loadRarityList()

        // 제품명 목록 불러오기
        this.loadSetsList()

        // 시리즈 목록 불러오기
        this.loadSeriesList()

        // 첫 자동 검색

        // 내 콜렉션 불러오기
        if(this.nation && this.set_name)
        { 
            this.keyword.nation = this.nation

            if(this.nation == 'kr'){
                this.keyword.set.nation = "한글 제품명"
            } else if(this.nation == 'en'){
                this.keyword.set.nation = "영어 제품명"
            } else if(this.nation == 'jp'){
                this.keyword.set.nation = "일본 제품명"
            }
            
            this.keyword.set.name = this.set_name

            this.submit(true)

            this.list = this.list.filter(e => !e.id)
        }
        else
        {
            if(this.$route.query.artist){
                // 아티스트 검색
                this.keyword.artist = this.$route.query.artist
                this.submit(true)
            }else if(this.$route.query.text){
                // TEXT 검색
                this.keyword.text = this.$route.query.text
                this.submit(true)
            }else if(this.is_deck){
                // TIPTAP > 덱 레시피 불러오기
                this.keyword.supertype = 'Pokémon'
                this.submit(true)
            }else if(this.$route.query.set_name){
                // 제품명 검색
                this.keyword.set.nation = this.$route.query.set_nation
                this.keyword.set.name = this.$route.query.set_name
                this.submit(true)
            }else{
                // 설정된 첫페이지 제품명 가져오기
                this.$http.post('/api/admin/card/search/first/set/import')
                .then((res) => {
                    this.keyword.set.nation = "한글 제품명"
                    this.keyword.set.name = res.data[0].set_name
                    this.submit(true)
                })
            }
        }
    },

    computed:{
        // 카드 종류
        subtypes(){
            switch(this.keyword.supertype){
                case 'ALL': return this.select_list.subtypes.All
                case 'Energy': return this.select_list.subtypes.Energy
                case 'Pokémon': return this.select_list.subtypes.Pokémon
                case 'Trainer': return this.select_list.subtypes.Trainer
            }
        },

        // 제품명
        set_list(){
            switch(this.keyword.set.nation){
                case '한글 제품명' : return this.select_list.set.kr
                case '영어 제품명' : return this.select_list.set.en
                case '일본 제품명' : return this.select_list.set.jp
            }
        },

        // 시리즈
        series_list(){
            switch(this.keyword.series.nation){
                case '한글 시리즈' : return this.select_list.series.kr
                case '영어 시리즈' : return this.select_list.series.en
                case '일본 시리즈' : return this.select_list.series.jp
            }
        },

        // 계산된 희귀도 목록
        computed_rarity_list(){
            switch(this.keyword.rarity.nation){
                case '영어 희귀도' : return this.select_list.rarity.en
                case '한글 희귀도' : return this.select_list.rarity.kr
                case '일본 희귀도' : return this.select_list.rarity.jp
            }
        },
    },

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*24,(this.page)*24)
            window.scrollTo(0, 0)
        },

        // 순서
        sequence(){
            this.sortList()
        }
    },

    methods: {
        loadMyCollection(){
            // 언어 확인
            this.nationCheck()

            // API
            this.list_ready = false
            this.$http.post('/api/card/list/search/'+this.keyword.nation+'/collection', {
                params: {
                    keyword: this.keyword,
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                // 검색 키워드 표기
                this.computed_search_keyword(this.keyword)

                this.list = res.data
                this.currentPageList = res.data.slice(0,24)
                this.pageLength = Math.ceil(res.data.length/24)
                this.list_ready = true
                this.page = 1
            })
        },

        // 레귤레이션 목록 불러오기
        loadRegulationMarkList(){
            this.$http.post('/api/admin/card/regulation_mark/list')
            .then((res) => {
                this.select_list.regulationMark = res.data.map(e => e.regulation_mark)
            })
        },

        // 카드종류 목록 불러오기
        loadSubtypesList(){
            this.$http.post('/api/admin/card/supertype/list/show')
            .then((res) => {
                this.select_list.subtypes.All = res.data.filter(e => (e.category != "Energy" && e.kr != "기본 에너지"))
                this.select_list.subtypes.Energy = res.data.filter(e => e.category == "Energy")
                this.select_list.subtypes.Pokémon = res.data.filter(e => e.category == "Pokémon")
                this.select_list.subtypes.Trainer = res.data.filter(e => e.category == "Trainer")
            })
        },
        
        // 희귀도 목록 불러오기
        loadRarityList(){
            this.$http.post('/api/card/select/rarity')
            .then(res => {
                this.select_list.rarity.en = res.data.map(e => e.en).filter(e => e!=null && e!="-" && e!="")
                this.select_list.rarity.kr = res.data.map(e => e.kr).filter(e => e!=null && e!="-" && e!="")
                this.select_list.rarity.jp = res.data.map(e => e.jp).filter(e => e!=null && e!="-" && e!="")
            })
        },

        // 제품명 목록 불러오기
        loadSetsList(){
            // 한글
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.set.kr = res.data.map(a => a.name)
            })

            // 영어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.set.en = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "jp"
                }
            }).then(res => { 
                this.select_list.set.jp = res.data.map(a => a.name)
            })
        },

        // 시리즈 목록 불러오기
        loadSeriesList(){
            // 한글
            this.$http.post('/api/card/select/series/kr')
            .then(res => { 
                this.select_list.series.kr = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 영어
            this.$http.post('/api/card/select/series/en')
            .then(res => { 
                this.select_list.series.en = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 일어
            this.$http.post('/api/card/select/series/jp')
            .then(res => { 
                this.select_list.series.jp = res.data.map(a => a.series).filter(e => e!=null)
            })
        },

        // 카드 상세 페이지로 이동
        goToCardDetail(id){
            if(this.isWrite){ // 게시판 글쓰기 & 판매글쓰기 일때
                this.$emit("goToDetail", id)
            }else{
                window.open('/card/detail?id='+id, '_blank')
            }
        },

        // 검색조건 초기화
        keywordReset(){
            Object.assign(this.$data.keyword, this.$options.data().keyword)
        },

        // 언어 확인
        nationCheck(){
            // 초기화
            this.keyword.nation = "en"

            // 한글 확인
            const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
            let kr_name = korean.test(this.keyword.text)
            let kr_set = this.keyword.set.nation=='한글 제품명' && this.keyword.set.name!=''
            let kr_rarity = this.keyword.rarity.nation=='한글 희귀도' && this.keyword.rarity.name!=''
            let kr_series = this.keyword.series.nation=='한글 시리즈' && this.keyword.series.name!=''

            // 일어 확인
            const Japanese = /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/
            let jp_name = Japanese.test(this.keyword.text)
            let jp_set = this.keyword.set.nation=='일본 제품명' && this.keyword.set.name!=''
            let jp_rarity = this.keyword.rarity.nation=='일본 희귀도' && this.keyword.rarity.name!=''
            let jp_series = this.keyword.series.nation=='일본 시리즈' && this.keyword.series.name!=''

            // 값 지정
            if(kr_name || kr_set || kr_rarity || kr_series){
                this.keyword.nation = "kr"
            }else if(jp_name || jp_set || jp_rarity || jp_series){
                this.keyword.nation = "jp"
            }else{
                this.keyword.nation = "en"
            }
        },

        // 조건 유무 확인
        isEmpty(){
            if(
                this.keyword.supertype == "ALL" 
                && 
                this.keyword.text == ""
                && 
                this.keyword.subtypes.length == 0
                && 
                this.keyword.regulationMark.length == 0
                && 
                this.keyword.types.length == 0
                && 
                this.keyword.set.name == ""
                && 
                this.keyword.series.name == ""
                && 
                this.keyword.rarity.name == ""
                && 
                this.keyword.artist == ""
            ){
                alert("검색조건이 없습니다.")
                return false
            }else{
                return true
            }
        },

        // 검색
        submit(isFirst){
            // 언어 확인
            this.nationCheck()

            // 조건 유무 확인
            if(!isFirst){
                if(!this.isEmpty()) return
            }

            // API
            this.list_ready = false
            this.$http.post('/api/card/list/search/'+this.keyword.nation, {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                console.log(res)
                // 검색 키워드 표기
                this.computed_search_keyword(this.keyword)

                this.list = res.data
                this.currentPageList = res.data.slice(0,24)
                this.pageLength = Math.ceil(res.data.length/24)
                this.list_ready = true
                this.page = 1

                // 첫 자동 검색 값 입력칸에서 제거
                if(isFirst){
                    this.keyword.set.name = ""
                    this.keyword.artist = ""
                }

                if (this.$refs.searchInput) {
                    this.$refs.searchInput.blur()
                }
            })
        },

        // 검색 키워드 표기
        computed_search_keyword(keyword){
            // 카드 종류 한글로 변환
            let subtypes_filtered = []
            subtypes_filtered = this.select_list.subtypes.All.filter(e => keyword.subtypes.includes(e.en))
            subtypes_filtered = subtypes_filtered.map(e => e.kr)

            let keywords = []
            keywords.push(
                keyword.text,
                subtypes_filtered.toString(),
                keyword.regulationMark.toString(),
                keyword.types.toString(),
                keyword.set.name,
                keyword.rarity.name,
                keyword.series.name,
                keyword.artist
            )

            this.search_keyword = keywords.filter(e => e!="").filter(e => e!=null).toString().replaceAll(",", " & ") + " 검색 결과"

            // 검색 nation 저장
            this.search_nation = keyword.nation
        },

        // 에너지 text 아이콘으로 변환 (영어)
        replaceEnglishToIcon(text){
            text = text.replaceAll('Grass', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png"></img>`)
            text = text.replaceAll('Fire', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png"></img>`)
            text = text.replaceAll('Water', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png"></img>`)
            text = text.replaceAll('Lightning', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png"></img>`)
            text = text.replaceAll('Psychic', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png"></img>`)
            text = text.replaceAll('Fighting', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png"></img>`)
            text = text.replaceAll('Darkness', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png"></img>`)
            text = text.replaceAll('Metal', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png"</img>`)
            text = text.replaceAll('Fairy', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png"</img>`)
            text = text.replaceAll('Dragon', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png"></img>`)
            text = text.replaceAll('Colorless', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png"></img>`)
            return text
        },

        sortList(){
            if(this.sequence == "발매 최신순")
            {
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => new Date(b.releaseDate_en).getTime() - new Date(a.releaseDate_en).getTime())
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => new Date(b.releaseDate_kr).getTime() - new Date(a.releaseDate_kr).getTime())
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => new Date(b.releaseDate_jp).getTime() - new Date(a.releaseDate_jp).getTime())
                }
            }
            else if(this.sequence == "발매 오래된순")
            {
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => new Date(a.releaseDate_en).getTime() - new Date(b.releaseDate_en).getTime())
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => new Date(a.releaseDate_kr).getTime() - new Date(b.releaseDate_kr).getTime())
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => new Date(a.releaseDate_jp).getTime() - new Date(b.releaseDate_jp).getTime())
                }
            }
            else if(this.sequence == "카드번호 순")
            {
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => a.number_en - b.number_en)
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => a.number_kr - b.number_kr)
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => a.number_jp - b.number_jp)
                }
            }
            else if(this.sequence == "카드번호 역순")
            {
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => b.number_en - a.number_en)
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => b.number_kr - a.number_kr)
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => b.number_jp - a.number_jp)
                }
            }
            else if(this.sequence == "HP 높은순")
            {
                this.list.sort((a, b) => b.hp - a.hp)
            }
            else if(this.sequence == "HP 낮은순")
            {
                this.list.sort((a, b) => a.hp - b.hp)
            }
            else if(this.sequence == "평점 높은순")
            {
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => b.en_total_avg - a.en_total_avg)
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => b.kr_total_avg - a.kr_total_avg)
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => b.jp_total_avg - a.jp_total_avg)
                }
            }
            else if(this.sequence == "평점 낮은순")
            {
                if(this.search_nation == "en"){
                    this.list.sort((a, b) => a.en_total_avg - b.en_total_avg)
                }else if(this.search_nation == "kr"){
                    this.list.sort((a, b) => a.kr_total_avg - b.kr_total_avg)
                }else if(this.search_nation == "jp"){
                    this.list.sort((a, b) => a.jp_total_avg - b.jp_total_avg)
                }
            }

            this.currentPageList = this.list.slice(0,24)
            this.list_ready = true
            this.page = 1
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}

::v-deep .pc .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .pc .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style scoped>
::v-deep .v15 input {
    font-weight:bold;
    margin-left:8px;
    font-size: 15px !important;
}

::v-deep .v15 .v-select {
    font-weight:bold !important;
    margin-left:8px;
    font-size: 15px !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}
</style>
<style scoped>
/* 페이징 */
::v-deep .mobile .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}


/* 모바일 카테고리 */
::v-deep .mobile .v-select {
    min-height: 33px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-select fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 33px;
    min-height: 33px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-select .v-select__selections {
    min-height: 33px !important;
    height: 33px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

::v-deep .mobile .v-select .v-select__slot {
    font-size: 12px; /* 글꼴 크기 조절 */
    height: 33px;
}

::v-deep .mobile .v-select .v-input__append-inner,
::v-deep .mobile .v-select .v-input__prepend-inner {
    margin-top: 0;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .mobile .v-input--switch .v-label {
    font-size:11px !important;
}

/* 모바일 카테고리 */
::v-deep .mobile .v-text-field {
    min-height: 33px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-text-field fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-text-field .v-input__slot {
    height: 33px;
    min-height: 33px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-text-field .v-select__selections {
    min-height: 33px !important;
    height: 33px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

::v-deep .mobile .v-text-field .v-text-field__slot {
    font-size: 12px !important; /* 글꼴 크기 조절 */
    height: 33px;
    margin-left: 12px;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .v-input--switch .v-label {
    font-size:10px !important;
}

::v-deep .mobile .v-v-select__slot {
    font-size:12px !important;
}

::v-deep .mobile .v-select__selections .v-label {
    font-size:12px !important;
}

::v-deep .mobile .v-label {
    font-size:12px !important;
}

::v-deep button {
    min-width: 10px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

</style>