<template>
    <div
        class="px-5 pt-1"
    >
        <div
            class="d-flex align-center rounded-10 mb-3"
            style="position:relative;"
        >
            <!-- 제목 -->
            <div>
                <p
                    class="mt-2 mb-0 font-weight-bold"
                    style="font-size:16px; cursor:pointer;"
                    @click="$route.query.user_id? $router.push('/collection/dashboard?user_id='+$route.query.user_id) : $router.push('/collection/dashboard')"
                >
                    콜렉션북
                </p>
            </div>

            <v-spacer></v-spacer>

            <!-- 유저 -->
            <div
                class="mt-1 mr-1 rounded-15 d-flex justify-space-between align-center"
            >
                <ProfileForBoardMobile
                    v-if="user_id"
                    :user="user_id"
                    :color="'black'"
                />
                <p
                    class="mb-0"
                    style="font-size:12px; color:#424361; font-weight:bold; margin-left:12px;"
                >
                    {{user_profile.nickname}}
                </p>
            </div>
        </div>
        <!-- 메뉴 -->
        <div class="d-flex align-center mobile" style="margin-top:-16px;">

            <!-- 공개유무 switch -->
            <v-switch
                v-if="!$route.path.startsWith('/collection/challengebook')"
                class="my-4 font-weight-bold"
                v-model="open"
                inset
                hide-details
                @change="openChanged()"
            ></v-switch>

            <div
                v-if="$route.path.startsWith('/collection/challengebook')"
                style="height:60px;"
            ></div>

            <v-spacer></v-spacer>

            <!-- 국가 선택 -->
            <v-select
                v-if="$route.path.startsWith('/collection/list')"
                height="40"
                width="74"
                class="rounded-10 mr-1 my-0 shrink font-weight-bold"
                persistent-placeholder
                dense
                outlined
                hide-details
                item-text="name"
                :items="select_list.card_nation"
                v-model="card_nation"
                :menu-props="{ offsetY: true }"
                @change="changeCardNation(card_nation)"
            >
                <template v-slot:selection="{ item }">
                    <span style="font-size:12px;" class="pl-1">{{item.name}}</span>
                </template>
                <template v-slot:append>
                    <v-icon style="font-size:12px;" class="pt-6px" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <v-btn
                v-if="$route.path.startsWith('/collection/collect')"
                height="40"
                width="74"
                class="rounded-10 px-0 mr-1"
                style="font-size:12px;"
                depressed
                dark
                v-ripple="false"
                color="#615DFA"
            >
                수정중
            </v-btn>
            <v-btn
                height="40"
                width="74"
                class="rounded-10 px-0 mr-1"
                style="font-size:12px;"
                depressed
                dark
                v-ripple="false"
                :color="$route.path.startsWith('/collection/challengebook/list')? '#615DFA':'#ADAFCA'"
                @click="$route.query.user_id? $router.push('/collection/challengebook/list?user_id='+$route.query.user_id) : $router.push('/collection/challengebook/list')"
            >
                챌린지북
            </v-btn>
            <v-btn
                height="40"
                width="74"
                class="rounded-10 px-0 mr-1"
                style="font-size:12px;"
                depressed
                dark
                v-ripple="false"
                :color="$route.path.startsWith('/collection/list')? '#615DFA':'#ADAFCA'"
                @click="$route.query.user_id? $router.push('/collection/list?nation='+card_nation+'&user_id='+$route.query.user_id) : $router.push('/collection/list?nation='+card_nation+'')"
            >
                콜렉션북
            </v-btn>
            <v-btn
                height="40"
                width="74"
                class="rounded-10 px-0"
                style="font-size:12px;"
                depressed
                dark
                v-ripple="false"
                :color="$route.path.startsWith('/collection/dashboard')? '#615DFA':'#ADAFCA'"
                @click="$route.query.user_id? $router.push('/collection/dashboard?user_id='+$route.query.user_id) : $router.push('/collection/dashboard')"
            >
                집계현황
            </v-btn>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import ProfileForBoardMobile from '@/components/ProfileForBoardMobile'

export default {
    components: {
        ProfileForBoardMobile
    },

    data: () => ({
        // 선택목록
        select_list: {
            // 국가 선택
            card_nation: [
                {value: "kr", name: "국내판"},
                {value: "en", name: "북미판"},
                {value: "jp", name: "일본판"}
            ]
        },

        open: null,
        card_nation: "kr",
        user_id: "",
        user_profile: {},
    }),

    mounted(){
        if(this.$route.query.nation){
            this.card_nation = this.$route.query.nation
        }
        
        if(this.$route.query.user_id){
            this.user_id = this.$route.query.user_id
            this.open = true
        }else{
            this.user_id = this.$store.state.user_id
            this.open = this.$store.state.collection_show
        }

        // 집계현황(제품명 목록)에서 클릭한 발매국가 가져와서 적용하기 
        if(this.$route.path.startsWith('/collection/collect')){
            this.card_nation = this.$route.query.nation
        }

        this.importUserProfile()
    },

    methods: {
        ...mapMutations(['update_collection_show']),

        // 유저 정보 가져오기
        importUserProfile(){
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.user_id
                }
            }).then(res => {
                this.user_profile = res.data[0]

                if(this.user_profile.type=='사용자'){
                    if(this.user_profile.grade==0){
                        this.user_icon =  'new'
                    }else{
                        this.user_icon =  this.user_profile.grade
                    }
                }else if(this.user_profile.type=='밴 유저'){
                    this.user_icon =  'banned'
                }else if(this.user_profile.type=='관리자'){
                    this.user_icon =  'admin'
                }else if(this.user_profile.type=='매니저'){
                    this.user_icon =  'manager'
                }else if(this.user_profile.type=='운영진'){
                    this.user_icon =  'board_manager'
                }else if(this.user_profile.type=='테스터'){
                    this.user_icon =  'tester'
                }
            })
        },

        changeCardNation(card_nation){
            if(!this.$route.path.startsWith('/collection/list'))
            {
                this.$router.push('/collection/list?nation='+card_nation)
            }
            else
            {
                this.card_nation = card_nation
                this.$emit("nationUpdated", card_nation)
            }
        },

        openChanged(){
            this.$emit("openChanged")
            this.update_collection_show(this.open)
        }
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>