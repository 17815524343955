<template>
    <div>
        <Mobile
            v-if="$vuetify.breakpoint.mobile"
            @nationUpdated="nationUpdated_handler"
        />

        <Pc
            v-if="!$vuetify.breakpoint.mobile"
        />
    </div>
</template>
<script>
import Mobile from "./Header/Mobile"
import Pc from "./Header/Pc"

export default {
    components: {
        Mobile,
        Pc
    },

    methods: {
        nationUpdated_handler(nation){
            this.$emit("nationUpdated", nation)
        }
    }
}
</script>