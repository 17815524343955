<template>
    <div>
        <Header />

        <!-- 상단 (PC) -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="rounded-15 mb-2 pa-4"
        >
            <v-sheet
                class="px-2 rounded-10 d-flex align-center"
            >
                <!-- 이미지 -->
                <div>
                    <v-img
                        v-if="!is_cert"
                        width="56"
                        height="56"
                        class="rounded-circle grey lighten-4"
                        style="cursor:pointer;"
                        :src="image"
                        @click="updateCert()"
                    ></v-img>

                    <v-img
                        v-if="is_cert"
                        width="56"
                        height="56"
                        class="rounded-circle"
                        style="cursor:pointer;"
                        src="@/assets/cert.png"
                        @click="updateCert()"
                    ></v-img>
                </div>

                <!-- 글자 -->
                <div
                    class="ml-4"
                    style="cursor:pointer;"
                >
                    <!-- 1단 (제목) -->
                    <div
                        class="d-flex"
                    >
                        <p
                            class="font-weight-bold text-subtitle-1 mt-2 text-truncate"
                            style="max-width:350px; margin-bottom:-2px;"
                        >
                            {{title}}
                        </p>

                        <!-- 용스타 -->
                        <div
                            v-if="writer == 'kakao_cyj5234'"
                            class="rounded-circle d-flex justify-center align-center mt-3 ml-2"
                            style="height:18px; width:18px; background:#F8468D;"
                        >
                            <div>
                                <v-img
                                    width="10"
                                    height="10"
                                    src="@/assets/star.svg"
                                ></v-img>
                            </div>
                        </div>

                        <!-- 운영자 -->
                        <div
                            v-if="writer == 'ichooseyou0512'"
                            class="rounded-circle d-flex justify-center align-center mt-2 ml-2"
                        >
                            <div>
                                <v-img
                                    width="18"
                                    height="18"
                                    src="@/assets/red.png"
                                ></v-img>
                            </div>
                        </div>
                    </div>

                    <!-- 2단 (상세정보) -->
                    <div
                        class="d-flex"
                    >
                        <!-- 공개여부 -->
                        <p
                            class="font-weight-medium text-subtitle-2 mb-0 mt-0 text-truncate"
                            style="color:#ADAFCA; max-width:350px;"
                        >
                            {{content}}
                        </p>
                    </div>
                </div>

                <v-spacer></v-spacer>

                <div>
                    <div
                        class="d-flex justify-end"
                    >
                        <v-btn
                            v-if="$store.state.user_id == writer || $store.state.type == '관리자'"
                            depressed
                            color="icu_purple"
                            dark
                            class="rounded-10"
                            @click="dialog.card_manage = true"
                        >
                            카드 관리
                        </v-btn>

                        <!-- 도전중 -->
                        <v-sheet
                            class="rounded-10 text-center pt-1 ml-1"
                            outlined
                            height="36"
                            width="36"
                            style="cursor:pointer;"
                            @click="updateChallenging()"
                        >
                            <v-icon
                                size="18"
                                :color="is_challenging? 'primary':'#b9b9c7'"
                            >
                                mdi-heart
                            </v-icon>
                        </v-sheet>

                        <v-btn
                            depressed
                            color="#ADAFCA"
                            dark
                            class="ml-1 rounded-10"
                            small
                            height="36"
                            width="36"
                            @click="$copyText('https://icu.gg'+$route.path+'&user_id='+user_id)"
                        >
                            <v-icon size="18">mdi-link-variant</v-icon>
                        </v-btn>
                        <v-btn
                            depressed
                            :color="view_type == '수정 로그'? 'primary':'#ADAFCA'"
                            dark
                            class="ml-1 rounded-10"
                            small
                            height="36"
                            width="36"
                            @click="view_type = '수정 로그'"
                        >
                            <v-icon size="18">mdi-message-text-clock-outline</v-icon>
                        </v-btn>
                        <v-btn
                            depressed
                            color="#ADAFCA"
                            dark
                            class="ml-1 rounded-10"
                            small
                            height="36"
                            width="36"
                            @click="capture()"
                        >
                            <v-icon size="18">mdi-image-outline</v-icon>
                        </v-btn>
                        <v-btn
                            depressed
                            color="#ADAFCA"
                            dark
                            class="ml-1 rounded-10"
                            small
                            height="36"
                            width="36"
                            @click="dialog.list = true; $copyText(export_list)"
                        >
                            <v-icon size="18">mdi-file-document-multiple-outline</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="$store.state.user_id == writer || $store.state.type == '관리자'"
                            depressed
                            color="#ADAFCA"
                            dark
                            class="ml-1 rounded-10"
                            small
                            height="36"
                            width="36"
                            @click="dialog.update = true"
                        >
                            <v-icon size="18">mdi-cog-outline</v-icon>
                        </v-btn>
                    </div>

                    <div
                        class="d-flex justify-end mt-1"
                        style="width:100%;"
                    >
                        <p
                            class="font-weight-medium text-caption mb-0 mt-0 mr-3"
                            style="color:#615DFA; font-size:10px; margin-left:4px; cursor:pointer;"
                            @click="copyCollectionBook()"
                        >
                            복사하기
                        </p>

                        <p
                            class="font-weight-medium text-caption mb-0 mt-0 mr-3"
                            style="color:#615DFA; font-size:10px; margin-left:4px; cursor:pointer;"
                            @click="$router.push('/board/write?board_group=커뮤니티&board_name=수집%20게시판&challenge_id='+$route.query.id)"
                        >
                            인증하기
                        </p>

                        <!-- 프로필 -->
                        <p
                            class="font-weight-medium text-caption mb-0 mt-0 mr-2"
                            style="color:#ADAFCA;"
                        >
                            챌린지 관리자
                        </p>
                        <!-- 프로필 -->
                        <ProfileForChallenge
                            :user="writer"
                            color="black"
                        />
                    </div>
                </div>
            </v-sheet>

            <v-sheet
                class="px-2 rounded-10 d-flex align-center mt-4"
            >
                <!-- 글자 -->
                <div
                    class="ml-4"
                    style="cursor:pointer;"
                >
                    <!-- 2단 (상세정보) -->
                    <div
                        class="d-flex"
                    >
                        <div>
                            <!-- 공개여부 -->
                            <p
                                class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                                style="color:#ADAFCA;"
                            >
                                공개여부
                            </p>
                            <!-- 공개여부 -->
                            <p
                                class="font-weight-medium text-subtitle-2 mb-0 mt-0"
                            >
                                {{is_open? "공개" : "비공개"}}
                            </p>
                        </div>

                        <div
                            style="background:#eeeeee; width:1px; height:40px; margin:4px 16px;"
                        ></div>

                        <div>
                            <!-- 공개여부 -->
                            <p
                                class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                                style="color:#ADAFCA;"
                            >
                                생성일
                            </p>
                            <!-- 공개여부 -->
                            <p
                                class="font-weight-medium text-subtitle-2 mb-0 mt-0"
                            >
                                {{dayjs(created).format('YYYY-MM-DD')}}
                            </p>
                        </div>

                        <div
                            style="background:#eeeeee; width:1px; height:40px; margin:4px 16px;"
                        ></div>

                        <div>
                            <!-- 공개여부 -->
                            <p
                                class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                                style="color:#ADAFCA;"
                            >
                                도전 참가
                            </p>
                            <!-- 공개여부 -->
                            <p
                                class="font-weight-medium text-subtitle-2 mb-0 mt-0 pb-2px icu_purple--text"
                                style="cursor:pointer;"
                                :style="view_type == '도전자 목록'? 'border-bottom:2px solid #615df7;':''"
                                @click="view_type = '도전자 목록'"
                            >
                                {{apply_count}}명
                            </p>
                        </div>

                        <div
                            style="background:#eeeeee; width:1px; height:40px; margin:4px 16px;"
                        ></div>

                        <div>
                            <!-- 공개여부 -->
                            <p
                                class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                                style="color:#ADAFCA;"
                            >
                                총 카드 수
                            </p>
                            <!-- 공개여부 -->
                            <p
                                class="font-weight-medium text-subtitle-2 mb-0 mt-0 pb-2px icu_purple--text"
                                style="cursor:pointer;"
                                :style="view_type == '카드 선택'? 'border-bottom:2px solid #615df7;':''"
                                @click="view_type = '카드 선택'"
                            >
                                {{card_amount}}장
                            </p>
                        </div>
                    </div>
                </div>

                <v-spacer></v-spacer>

                <div
                    class="mt-2"
                    style="position:relative; cursor:pointer;"
                    @click="view_type = '카드 선택'"
                >
                    <div
                        style="position:absolute; top:-22px; left:0px;"
                    >
                        <ProfileForChallenge
                            :user="user_id"
                            color="black"
                        />
                    </div>

                    <!-- 프로필 -->
                    <p
                        style="position:absolute; top:-22px; right:0px;"
                        class="font-weight-medium text-caption mb-0 mt-0"
                    >
                        {{progress}} / {{card_amount}}
                    </p>

                    <!-- 수집률 그래프 -->
                    <v-progress-linear
                        color="primary"
                        background-color="grey lighten-3"
                        rounded
                        style="width:200px;"
                        class="rounded-10 shrink"
                        height="10"
                        :value="calculateProbability(progress, card_amount)"
                    ></v-progress-linear>
                </div>

                <!-- 수집률 -->
                <v-sheet
                    width="88"
                    height="32"
                    class="ml-12 d-flex justify-center align-center rounded-20 custom_shadow"
                >
                    <span
                        class="text-body-2 font-weight-medium"
                    >
                        {{calculateProbability(progress, card_amount)}}%
                    </span>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- 상단 (Mobile) -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="rounded-15 mb-2 pa-2 pt-3"
        >
            <v-sheet
                class="px-2 rounded-10 d-flex align-center"
            >
                <!-- 이미지 -->
                <div>
                    <v-img
                        v-if="!is_cert"
                        width="56"
                        height="56"
                        class="rounded-circle grey lighten-4"
                        style="cursor:pointer;"
                        :src="image"
                        @click="updateCert()"
                    ></v-img>

                    <v-img
                        v-if="is_cert"
                        width="56"
                        height="56"
                        class="rounded-circle"
                        style="cursor:pointer;"
                        src="@/assets/cert.png"
                        @click="updateCert()"
                    ></v-img>
                </div>

                <!-- 글자 -->
                <div
                    class="ml-4"
                    style="cursor:pointer;"
                >
                    <!-- 1단 (제목) -->
                    <div
                        class="d-flex"
                    >
                        <p
                            class="font-weight-bold text-subtitle-1 mt-2 text-truncate"
                            style="max-width:240px; margin-bottom:-2px;"
                        >
                            {{title}}
                        </p>
                        <div
                            v-if="writer == 'kakao_cyj5234'"
                            class="rounded-circle d-flex justify-center align-center mt-3 ml-2"
                            style="height:18px; width:18px; background:#F8468D;"
                        >
                            <div>
                                <v-img
                                    width="10"
                                    height="10"
                                    src="@/assets/star.svg"
                                ></v-img>
                            </div>
                        </div>

                        <!-- 운영자 -->
                        <div
                            v-if="writer == 'ichooseyou0512'"
                            class="rounded-circle d-flex justify-center align-center mt-2 ml-2"
                        >
                            <div>
                                <v-img
                                    width="18"
                                    height="18"
                                    src="@/assets/red.png"
                                ></v-img>
                            </div>
                        </div>
                    </div>

                    <!-- 2단 (상세정보) -->
                    <div
                        class="d-flex"
                    >
                        <!-- 공개여부 -->
                        <p
                            class="font-weight-medium text-subtitle-2 mb-0 mt-0 text-truncate"
                            style="color:#ADAFCA; max-width:240px;"
                        >
                            {{content}}
                        </p>
                    </div>
                </div>
            </v-sheet>

            <v-sheet
                class="px-2 rounded-10 d-flex align-center mt-2"
            >
                <v-btn
                    v-if="$store.state.user_id == writer || $store.state.type == '관리자'"
                    depressed
                    color="icu_purple"
                    dark
                    small
                    height="36"
                    class="rounded-10"
                    style="letter-spacing:-0.5px;"
                    @click="dialog.card_manage = true"
                >
                    카드 관리
                </v-btn>

                <v-spacer></v-spacer>

                <!-- 도전중 -->
                <v-sheet
                    class="ml-1 rounded-10 text-center pt-1"
                    outlined
                    small
                    height="36"
                    width="36"
                    style="cursor:pointer;"
                    @click="updateChallenging()"
                >
                    <v-icon
                        size="18"
                        :color="is_challenging? 'primary':'#b9b9c7'"
                    >
                        mdi-heart
                    </v-icon>
                </v-sheet>

                <v-btn
                    depressed
                    color="#ADAFCA"
                    dark
                    class="ml-1 rounded-10"
                    small
                    height="36"
                    width="36"
                    @click="$copyText('https://icu.gg'+$route.path+'&user_id='+user_id)"
                >
                    <v-icon size="18">mdi-link-variant</v-icon>
                </v-btn>
                <v-btn
                    :color="view_type == '수정 로그'? 'primary':'#ADAFCA'"
                    depressed
                    dark
                    class="ml-1 rounded-10"
                    small
                    height="36"
                    width="36"
                    @click="view_type = '수정 로그'"
                >
                    <v-icon size="18">mdi-message-text-clock-outline</v-icon>
                </v-btn>
                <v-btn
                    depressed
                    color="#ADAFCA"
                    dark
                    class="ml-1 rounded-10"
                    small
                    height="36"
                    width="36"
                    @click="capture()"
                >
                    <v-icon size="18">mdi-image-outline</v-icon>
                </v-btn>
                <v-btn
                    depressed
                    color="#ADAFCA"
                    dark
                    class="ml-1 rounded-10"
                    small
                    height="36"
                    width="36"
                    @click="dialog.list = true; $copyText(export_list)"
                >
                    <v-icon size="18">mdi-file-document-multiple-outline</v-icon>
                </v-btn>
                <v-btn
                    v-if="$store.state.user_id == writer || $store.state.type == '관리자'"
                    depressed
                    color="#ADAFCA"
                    dark
                    class="ml-1 rounded-10"
                    small
                    height="36"
                    width="36"
                    @click="dialog.update = true"
                >
                    <v-icon size="18">mdi-cog-outline</v-icon>
                </v-btn>
            </v-sheet>

            <div
                class="d-flex justify-end mt-2 pr-2"
                style="width:100%;"
            >
                <p
                    class="font-weight-medium text-caption mb-0 mt-0 mr-3"
                    style="color:#615DFA; font-size:10px; margin-left:4px; cursor:pointer;"
                    @click="copyCollectionBook()"
                >
                    복사하기
                </p>

                <p
                    class="font-weight-medium text-caption mb-0 mt-0 mr-3"
                    style="color:#615DFA; font-size:10px; margin-left:4px; cursor:pointer;"
                    @click="$router.push('/board/write?board_group=커뮤니티&board_name=수집%20게시판&challenge_id='+$route.query.id)"
                >
                    인증하기
                </p>

                <!-- 프로필 -->
                <p
                    class="font-weight-medium text-caption mb-0 mt-0 mr-2"
                    style="color:#ADAFCA;"
                >
                    챌린지 관리자
                </p>
                <!-- 프로필 -->
                <ProfileForChallenge
                    v-if="writer"
                    :user="writer"
                    color="black"
                />
            </div>

            <!-- 2단 (상세정보) -->
            <div
                class="d-flex justify-space-between mt-2 px-2"
            >
                <div>
                    <!-- 공개여부 -->
                    <p
                        class="text-caption font-weight-regular mb-0 mt-0"
                        style="color:#ADAFCA;"
                    >
                        공개여부
                    </p>
                    <!-- 공개여부 -->
                    <p
                        class="font-weight-medium text-caption mb-0 mt-0"
                    >
                        {{is_open? "공개" : "비공개"}}
                    </p>
                </div>

                <div
                    style="background:#eeeeee; width:1px; height:24px; margin:2px 10px;"
                ></div>

                <div>
                    <!-- 공개여부 -->
                    <p
                        class="text-caption font-weight-regular mb-0 mt-0"
                        style="color:#ADAFCA;"
                    >
                        생성일
                    </p>
                    <!-- 공개여부 -->
                    <p
                        class="font-weight-medium text-caption mb-0 mt-0"
                    >
                        {{dayjs(created).format('YYYY-MM-DD')}}
                    </p>
                </div>

                <div
                    style="background:#eeeeee; width:1px; height:24px; margin:2px 10px;"
                ></div>

                <div>
                    <!-- 공개여부 -->
                    <p
                        class="text-caption font-weight-regular mb-0 mt-0"
                        style="color:#ADAFCA;"
                    >
                        도전 참가
                    </p>
                    <!-- 공개여부 -->
                    <p
                        class="font-weight-medium text-caption mb-0 mt-0 icu_purple--text"
                        style="cursor:pointer;"
                        :style="view_type == '도전자 목록'? 'border-bottom:2px solid #615df7;':''"
                        @click="view_type = '도전자 목록'"
                    >
                        {{apply_count}}명
                    </p>
                </div>

                <div
                    style="background:#eeeeee; width:1px; height:24px; margin:2px 10px;"
                ></div>

                <div>
                    <!-- 공개여부 -->
                    <p
                        class="text-caption font-weight-regular mb-0 mt-0"
                        style="color:#ADAFCA;"
                    >
                        총 카드 수
                    </p>
                    <!-- 공개여부 -->
                    <p
                        class="font-weight-medium text-caption mb-0 mt-0 icu_purple--text"
                        style="cursor:pointer;"
                        :style="view_type == '카드 선택'? 'border-bottom:2px solid #615df7;':''"
                        @click="view_type = '카드 선택'"
                    >
                        {{card_amount}}장
                    </p>
                </div>
            </div>

            <v-spacer></v-spacer>

            <v-sheet
                class="px-2 mt-10"
            >
                <div
                    class="mt-2"
                    style="position:relative; cursor:pointer;"
                    @click="view_type = '카드 선택'"
                >
                    <div
                        style="position:absolute; top:-22px; left:0px;"
                    >
                        <ProfileForChallenge
                            v-if="user_id"
                            :user="user_id"
                            color="black"
                        />
                    </div>

                    <!-- 프로필 -->
                    <p
                        style="position:absolute; top:-22px; right:0px;"
                        class="font-weight-medium text-caption mb-0 mt-0"
                    >
                        {{progress}} / {{card_amount}}
                    </p>

                    <!-- 수집률 그래프 -->
                    <v-progress-linear
                        color="primary"
                        background-color="grey lighten-3"
                        rounded
                        class="rounded-10 shrink"
                        height="10"
                        :value="calculateProbability(progress, card_amount)"
                    ></v-progress-linear>
                </div>

                <!-- 수집률 -->
                <v-sheet
                    width="66"
                    height="24"
                    class="d-flex justify-center align-center rounded-20 custom_shadow mx-auto mt-3 mb-2"
                >
                    <span
                        class="text-caption font-weight-medium"
                    >
                        {{calculateProbability(progress, card_amount)}}%
                    </span>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- 1단 (검색) (PC) -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <v-sheet
                v-if="view_type == '카드 선택'"
                class="rounded-15 mb-2 pa-4 d-flex align-center"
            >
                <!-- 순서 -->
                <div>
                    <!-- 챌린지북 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-2"
                        style="font-size:14px;"
                        :color="sequence == '챌린지북'? 'primary':'#B3B3C2'"
                        height="36"
                        width="100"
                        @click="sequence == '챌린지북'? (sequence = '챌린지북', align_challenge = !align_challenge) : sequence = '챌린지북'"
                    >
                        <v-icon size="16" class="mr-1">{{align_challenge? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        챌린지북
                    </v-btn>

                    <!-- 이름 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-2"
                        style="font-size:14px;"
                        :color="sequence == '이름'? 'primary':'#B3B3C2'"
                        height="36"
                        width="88"
                        @click="sequence == '이름'? (sequence = '이름', align_name = !align_name) : sequence = '이름'"
                    >
                        <v-icon size="16" class="mr-1">{{align_name? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        이름
                    </v-btn>

                    <!-- 보유수량 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-2"
                        style="font-size:14px;"
                        :color="sequence == '보유수량'? 'primary':'#B3B3C2'"
                        height="36"
                        width="100"
                        @click="sequence == '보유수량'? (sequence = '보유수량', align_amount = !align_amount) : sequence = '보유수량'"
                    >
                        <v-icon size="16" class="mr-1">{{align_amount? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        보유수량
                    </v-btn>

                    <!-- 등급 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold"
                        style="font-size:14px;" 
                        :color="sequence == '등급'? 'primary':'#B3B3C2'"
                        height="36"
                        width="88"
                        @click="sequence == '등급'? (sequence = '등급', align_state = !align_state) : sequence = '등급'"
                    >
                        <v-icon size="16" class="mr-1">{{align_state? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        등급
                    </v-btn>
                </div>

                <v-spacer></v-spacer>

                <!-- 목록방식 : 카드형식 -->
                <v-btn
                    icon
                    class="mt-2px"
                    v-ripple="false"
                    :color="list_type == 'card'? '#23D2E2':'#DFDFDF'"
                    @click="list_type = 'card'"
                >
                    <v-icon size="36">mdi-image-outline</v-icon>
                </v-btn>

                <!-- 목록방식 : 목록형식 -->
                <v-btn
                    icon
                    class="ml-2 mt-2px"
                    v-ripple="false"
                    :color="list_type == 'list'? '#23D2E2':'#DFDFDF'"
                    @click="list_type = 'list'"
                >
                    <v-icon size="36">mdi-format-list-bulleted</v-icon>
                </v-btn>

                <!-- 검색어 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="keyword"
                    placeholder="검색어"
                    class="ml-2 rounded-15 font-weight-bold shrink"
                    style="width:200px;"
                    @keyup.enter="search()"
                >
                    <template v-slot:append>
                        <v-icon class="pt-0 mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    class="ml-3 rounded-10"
                    style="font-size:18px; font-weight:bold; letter-spacing:-0.6px;"
                    color="primary"
                    width="86"
                    height="40"
                    @click="search()"
                >
                    검색
                </v-btn>
            </v-sheet>
        </div>

        <!-- 1단 (검색) (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <v-sheet
                v-if="view_type == '카드 선택'"
                class="rounded-15 mb-2 px-4 py-2 d-flex align-center"
            >
                <!-- 목록방식 : 카드형식 -->
                <v-btn
                    icon
                    class="mt-2px px-0"
                    small
                    v-ripple="false"
                    :color="list_type == 'card'? '#23D2E2':'#DFDFDF'"
                    @click="list_type = 'card'"
                >
                    <v-icon size="24">mdi-image-outline</v-icon>
                </v-btn>

                <!-- 목록방식 : 목록형식 -->
                <v-btn
                    icon
                    class="ml-1 mt-2px px-0"
                    small
                    v-ripple="false"
                    :color="list_type == 'list'? '#23D2E2':'#DFDFDF'"
                    @click="list_type = 'list'"
                >
                    <v-icon size="24">mdi-format-list-bulleted</v-icon>
                </v-btn>

                <!-- 검색어 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="keyword"
                    placeholder="검색어"
                    class="ml-2 rounded-10 font-weight-bold flex-grow-1"
                    @keyup.enter="search()"
                >
                    <template v-slot:append>
                        <v-icon class="pt-0 mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    class="ml-2 rounded-10"
                    style="font-size:16px; font-weight:bold; letter-spacing:-0.6px;"
                    color="primary"
                    width="80"
                    height="40"
                    @click="search()"
                >
                    검색
                </v-btn>
            </v-sheet>

            <!-- 순서 -->
            <div
                class="d-flex justify-space-between px-2 mb-3"
            >
                <!-- 챌린지북 -->
                <v-btn
                    depressed
                    dark
                    class="rounded-10 font-weight-bold"
                    style="font-size:10px;"
                    :color="sequence == '챌린지북'? 'primary':'#B3B3C2'"
                    height="32"
                    width="84"
                    @click="sequence == '챌린지북'? (sequence = '챌린지북', align_challenge = !align_challenge) : sequence = '챌린지북'"
                >
                    <v-icon size="16" class="mr-1">{{align_challenge? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                    챌린지북
                </v-btn>

                <!-- 이름 -->
                <v-btn
                    depressed
                    dark
                    class="rounded-10 font-weight-bold"
                    style="font-size:10px;"
                    :color="sequence == '이름'? 'primary':'#B3B3C2'"
                    height="32"
                    width="76"
                    @click="sequence == '이름'? (sequence = '이름', align_name = !align_name) : sequence = '이름'"
                >
                    <v-icon size="16" class="mr-1">{{align_name? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                    이름
                </v-btn>

                <!-- 보유수량 -->
                <v-btn
                    depressed
                    dark
                    class="rounded-10 font-weight-bold"
                    style="font-size:10px;"
                    :color="sequence == '보유수량'? 'primary':'#B3B3C2'"
                    height="32"
                    width="84"
                    @click="sequence == '보유수량'? (sequence = '보유수량', align_amount = !align_amount) : sequence = '보유수량'"
                >
                    <v-icon size="16" class="mr-1">{{align_amount? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                    보유수량
                </v-btn>

                <!-- 등급 -->
                <v-btn
                    depressed
                    dark
                    class="rounded-10 font-weight-bold"
                    style="font-size:10px;" 
                    :color="sequence == '등급'? 'primary':'#B3B3C2'"
                    height="32"
                    width="76"
                    @click="sequence == '등급'? (sequence = '등급', align_state = !align_state) : sequence = '등급'"
                >
                    <v-icon size="16" class="mr-1">{{align_state? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                    등급
                </v-btn>
            </div>
        </div>

        <!-- 수정 로그 -->
        <div
            v-if="view_type == '수정 로그'"
        >
            <v-sheet
                v-for="item in log_list" :key="item.id"
                class="d-flex align-center rounded-15 py-4 px-8 mt-2"
            >
                <div
                    class="rounded-circle custom_shadow pa-4 d-flex justify-center align-center"
                    style="width:48px; height:48px;"
                >
                    <v-img
                        :src="require('@/assets/challenge/'+item.type+'.svg')"
                    ></v-img>
                </div>
                <div
                    class="ml-6"
                >
                    <p
                        class="text-subtitle-1 font-weight-bold mb-0"
                        style="line-height:18px;"
                    >
                        {{item.message}}
                    </p>
                    <p
                        class="text-caption mb-0 grey--text"
                    >
                        {{new Date(item.created).toLocaleString()}}
                    </p>
                </div>
            </v-sheet>
        </div>

        <!-- 카드 목록 -->
        <div
            id="capture"
            class="mb-4"
        >
            <v-sheet
                v-if="view_type == '카드 선택'"
                width="100%"
                class="rounded-15"
                :class="$vuetify.breakpoint.mobile? 'px-5 py-3':'px-7 py-5'"
                style="position:relative;"
            >
                <div
                    v-if="list.length"
                    style="margin:0 -6px;"
                >
                    <!-- 목록 방식 (card) (PC) -->
                    <v-row
                        v-if="list.length && list_type=='card' && !$vuetify.breakpoint.mobile"
                        no-gutters
                        class="pt-4"
                    >
                        <v-col
                            v-for="(item, index) in list" :key="'card'+index"
                            :cols="3"
                            class="pa-0 pb-2 mb-4"
                            style="position:relative;"
                        >
                            <div
                                style="width:180px; height:213px; background:#000; position:absolute; z-index:9; opacity:0.60; left:8px;"
                                :class="{
                                    'no_amount': (item.challenge_nation == 'kr' && item.amount_kr == 0) || (item.challenge_nation == 'jp' && item.amount_jp == 0) || (item.challenge_nation == 'en' && item.amount_en == 0),
                                    'yes_amount': (item.challenge_nation == 'kr' && item.amount_kr != 0) || (item.challenge_nation == 'jp' && item.amount_jp != 0) || (item.challenge_nation == 'en' && item.amount_en != 0)
                                }"
                            ></div>

                            <!-- 라벨 -->
                            <v-sheet
                                style="background:rgba(0,0,0,0.4); height:34px; width:180px; position:absolute; bottom:0px; top: 213px; left:8px; z-index:100;"
                                :class="{
                                    'no_amount': (item.challenge_nation == 'kr' && item.amount_kr == 0) || (item.challenge_nation == 'jp' && item.amount_jp == 0) || (item.challenge_nation == 'en' && item.amount_en == 0),
                                    'yes_amount': (item.challenge_nation == 'kr' && item.amount_kr != 0) || (item.challenge_nation == 'jp' && item.amount_jp != 0) || (item.challenge_nation == 'en' && item.amount_en != 0)
                                }"
                            >
                                <p
                                    class="mt-2 mb-0 text-center px-2 text-truncate"
                                    style="font-size:12px;"
                                >
                                    <span v-if="item.challenge_nation == 'kr'" class="white--text">{{item.number_kr.toString().padStart(3,'0')}} {{item.name_kr}}</span>
                                    <span v-if="item.challenge_nation == 'jp'" class="white--text">{{item.number_jp.toString().padStart(3,'0')}} {{item.name_jp}}</span>
                                    <span v-if="item.challenge_nation == 'en'" class="white--text">{{item.number_en.toString().padStart(3,'0')}} {{item.name_en}}</span>
                                </p>
                            </v-sheet>

                            <!-- 이미지 -->
                            <div
                                width="180"
                                style="cursor:pointer; position:relative; width:180px;"
                                :style="
                                    (item.challenge_nation == 'kr' && item.amount_kr)
                                    || (item.challenge_nation == 'jp' && item.amount_jp)
                                    || (item.challenge_nation == 'en' && item.amount_en)
                                    ? '':'opacity:0.6; filter: grayscale(100%);'"
                                class="mx-auto"
                                @click="item_toggle(item)"
                            >
                                <div
                                    v-if="
                                        (item.challenge_nation == 'kr' && (item.card_state_kr.includes('PSA') || item.card_state_kr.includes('기타')) && !item.card_state_kr.includes('10등급'))
                                        || (item.challenge_nation == 'en' && (item.card_state_en.includes('PSA') || item.card_state_en.includes('기타')) && !item.card_state_en.includes('10등급'))
                                        || (item.challenge_nation == 'jp' && (item.card_state_jp.includes('PSA') || item.card_state_jp.includes('기타')) && !item.card_state_jp.includes('10등급'))
                                    "
                                    :style="
                                        (item.challenge_nation == 'kr' && item.card_state_kr.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                        (item.challenge_nation == 'kr' && item.card_state_kr.includes('기타'))? 'border:3px solid #F700FF;' : 
                                        (item.challenge_nation == 'en' && item.card_state_en.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                        (item.challenge_nation == 'en' && item.card_state_en.includes('기타'))? 'border:3px solid #F700FF;' : 
                                        (item.challenge_nation == 'jp' && item.card_state_jp.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                        (item.challenge_nation == 'jp' && item.card_state_jp.includes('기타'))? 'border:3px solid #F700FF;' : 
                                        ''
                                    "
                                    style="position:absolute; top:-8px; right:-4px; border-radius:20px; background:white; font-size:12px; font-weight:bold;"
                                    class="py-2px px-2"
                                >
                                    <!-- {{item.challenge_nation == 'kr'? item.card_state_kr : (item.challenge_nation == 'jp'? item.card_state_jp : item.card_state_en)}} -->
                                    {{view_state(item)}}
                                </div>

                                <!-- 기타 10등급 하이라이트 -->
                                <div
                                    v-if="
                                        (item.challenge_nation == 'kr' && item.card_state_kr == 'PSA 10등급')
                                        || (item.challenge_nation == 'en' && item.card_state_en == 'PSA 10등급')
                                        || (item.challenge_nation == 'jp' && item.card_state_jp == 'PSA 10등급')
                                    "
                                    style="position:absolute; width:180px; height:248px; border:5px solid #FF0000; inset; border-radius:5px;"
                                >
                                    <div
                                        style="position:absolute; top:-13px; right:-9px; border:3px solid #FF0000; border-radius:20px; background:white; font-size:12px; font-weight:bold;"
                                        class="py-2px px-2"
                                    >
                                        PSA 10
                                    </div>
                                </div>

                                <!-- 기타 10등급 하이라이트 -->
                                <div
                                    v-if="
                                        (item.challenge_nation == 'kr' && item.card_state_kr == '기타 10등급')
                                        || (item.challenge_nation == 'en' && item.card_state_en == '기타 10등급')
                                        || (item.challenge_nation == 'jp' && item.card_state_jp == '기타 10등급')
                                    "
                                    style="position:absolute; width:180px; height:248px; border:5px solid #F700FF; inset; border-radius:5px;"
                                >
                                    <div
                                        style="position:absolute; top:-13px; right:-9px; border:3px solid #F700FF; border-radius:20px; background:white; font-size:12px; font-weight:bold;"
                                        class="py-2px px-2"
                                    >
                                        등급 10
                                    </div>
                                </div>

                                <v-sheet height="248">
                                    <!-- <img width="180" height="248" src='@/assets/card.png' /> -->
                                    <img
                                        width="180"
                                        height="248"
                                        :src="view_image(item)"
                                    />
                                </v-sheet>

                                <!-- 라벨 -->
                                <v-sheet
                                    style="background:rgba(0,0,0,0.6); height:34px; width:180px; position:absolute; bottom:0px; z-index:100;"
                                    @click.stop="openDetail(item)"
                                >
                                    <p
                                        class="mt-2 mb-0 text-center px-2 text-truncate"
                                        style="font-size:12px;"
                                    >
                                        <span v-if="item.challenge_nation == 'kr'" class="white--text">{{item.number_kr.toString().padStart(3,'0')}} {{item.name_kr}}</span>
                                        <span v-if="item.challenge_nation == 'jp'" class="white--text">{{item.number_jp.toString().padStart(3,'0')}} {{item.name_jp}}</span>
                                        <span v-if="item.challenge_nation == 'en'" class="white--text">{{item.number_en.toString().padStart(3,'0')}} {{item.name_en}}</span>
                                    </p>
                                </v-sheet>
                            </div>

                            <!-- 희귀도 & 넘버/토탈넘버 -->
                            <v-sheet class="d-flex justify-space-between px-2 pt-2 pb-1 mx-auto" style="width:180px;">
                                <p
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    <span v-if="item.challenge_nation == 'kr'">{{item.rarity_kr}}</span>
                                    <span v-if="item.challenge_nation == 'jp'">{{item.rarity_jp}}</span>
                                    <span v-if="item.challenge_nation == 'en'">{{item.rarity_en}}</span>
                                </p>

                                <p
                                    v-if="item.challenge_nation == 'kr'"
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    {{item.number_kr}}/{{item.printedTotal_kr}}
                                </p>
                                <p
                                    v-if="item.challenge_nation == 'jp'"
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    {{item.number_jp}}/{{item.printedTotal_jp}}
                                </p>
                                <p
                                    v-if="item.challenge_nation == 'en'"
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    {{item.number_en}}/{{item.printedTotal_en}}
                                </p>
                            </v-sheet>

                            <v-divider class="mx-auto" style="width:180px;"></v-divider>

                            <!-- 메모 & 수량 & 미보유/보유중 -->
                            <v-sheet class="d-flex align-center justify-space-between px-2 pt-6px mx-auto" style="width:180px;">
                                <!-- 메모 -->
                                <v-btn
                                    v-if="item.challenge_nation == 'kr'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.memo_kr.length? 'primary':'#B3B3C2'"
                                    @click="item_memo(item)"
                                >
                                    메모
                                </v-btn>
                                <v-btn
                                    v-if="item.challenge_nation == 'jp'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.memo_jp.length? 'primary':'#B3B3C2'"
                                    @click="item_memo(item)"
                                >
                                    메모
                                </v-btn>
                                <v-btn
                                    v-if="item.challenge_nation == 'en'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.memo_en.length? 'primary':'#B3B3C2'"
                                    @click="item_memo(item)"
                                >
                                    메모
                                </v-btn>
                                
                                <!-- 수량 증가/감소 -->
                                <div
                                    class="d-flex align-center"
                                >
                                    <v-icon
                                        :disabled="loading"
                                        size="16"
                                        @click="item_decrease(item)"
                                    >
                                        mdi-minus
                                    </v-icon>
                                    <p
                                        class="text-caption mx-2 mb-0 pb-2px"
                                    >
                                        <span v-if="item.challenge_nation == 'en'">{{item.amount_en}}</span>
                                        <span v-if="item.challenge_nation == 'kr'">{{item.amount_kr}}</span>
                                        <span v-if="item.challenge_nation == 'jp'">{{item.amount_jp}}</span>
                                    </p>
                                    <v-icon
                                        :disabled="loading"
                                        size="16"
                                        @click="item_increase(item)"
                                    >
                                        mdi-plus
                                    </v-icon>
                                </div>

                                <!-- 미보유/보유중 -->
                                <v-btn
                                    v-if="item.challenge_nation == 'kr'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.amount_kr? 'primary':'#B3B3C2'"
                                >
                                    {{item.amount_kr==0? '미보유':'보유중'}}
                                </v-btn>
                                <v-btn
                                    v-if="item.challenge_nation == 'jp'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.amount_jp? 'primary':'#B3B3C2'"
                                >
                                    {{item.amount_jp==0? '미보유':'보유중'}}
                                </v-btn>
                                <v-btn
                                    v-if="item.challenge_nation == 'en'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.amount_en? 'primary':'#B3B3C2'"
                                >
                                    {{item.amount_en==0? '미보유':'보유중'}}
                                </v-btn>
                            </v-sheet>
                        </v-col>
                    </v-row>

                    <!-- 목록 방식 (card) (Mobile) -->
                    <v-row
                        v-if="list.length && list_type=='card' && $vuetify.breakpoint.mobile"
                        no-gutters
                        class="pt-4"
                    >
                        <v-col
                            v-for="(item, index) in list" :key="'card'+index"
                            :cols="6"
                            class="pa-0 pb-2 mb-4"
                        >
                            <!-- 이미지 -->
                            <div
                                width="140"
                                style="cursor:pointer; position:relative; width:140px;"
                                :style="
                                    (item.challenge_nation == 'kr' && item.amount_kr)
                                    || (item.challenge_nation == 'jp' && item.amount_jp)
                                    || (item.challenge_nation == 'en' && item.amount_en)
                                    ? '':'opacity:0.6; filter: grayscale(100%);'"
                                class="mx-auto"
                                @click="item_toggle(item)"
                            >
                                <div
                                    style="width:140px; height:158px; background:#000; position:absolute; z-index:9; opacity:0.60; left:0;"
                                    :class="{
                                        'no_amount': (item.challenge_nation == 'kr' && item.amount_kr == 0) || (item.challenge_nation == 'jp' && item.amount_jp == 0) || (item.challenge_nation == 'en' && item.amount_en == 0),
                                        'yes_amount': (item.challenge_nation == 'kr' && item.amount_kr != 0) || (item.challenge_nation == 'jp' && item.amount_jp != 0) || (item.challenge_nation == 'en' && item.amount_en != 0)
                                    }"
                                ></div>

                                <!-- 라벨 -->
                                <v-sheet
                                    style="background:rgba(0,0,0,0.4); height:34px; width:140px; position:absolute; bottom:0px; top: 158px; left:0; z-index:100;"
                                    :class="{
                                        'no_amount': (item.challenge_nation == 'kr' && item.amount_kr == 0) || (item.challenge_nation == 'jp' && item.amount_jp == 0) || (item.challenge_nation == 'en' && item.amount_en == 0),
                                        'yes_amount': (item.challenge_nation == 'kr' && item.amount_kr != 0) || (item.challenge_nation == 'jp' && item.amount_jp != 0) || (item.challenge_nation == 'en' && item.amount_en != 0)
                                    }"
                                >
                                    <p
                                        class="mt-2 mb-0 text-center px-2 text-truncate"
                                        style="font-size:12px;"
                                    >
                                        <span v-if="item.challenge_nation == 'kr'" class="white--text">{{item.number_kr.toString().padStart(3,'0')}} {{item.name_kr}}</span>
                                        <span v-if="item.challenge_nation == 'jp'" class="white--text">{{item.number_jp.toString().padStart(3,'0')}} {{item.name_jp}}</span>
                                        <span v-if="item.challenge_nation == 'en'" class="white--text">{{item.number_en.toString().padStart(3,'0')}} {{item.name_en}}</span>
                                    </p>
                                </v-sheet>

                                <div
                                    v-if="
                                        (item.challenge_nation == 'kr' && (item.card_state_kr.includes('PSA') || item.card_state_kr.includes('기타')) && !item.card_state_kr.includes('10등급'))
                                        || (item.challenge_nation == 'en' && (item.card_state_en.includes('PSA') || item.card_state_en.includes('기타')) && !item.card_state_en.includes('10등급'))
                                        || (item.challenge_nation == 'jp' && (item.card_state_jp.includes('PSA') || item.card_state_jp.includes('기타')) && !item.card_state_jp.includes('10등급'))
                                    "
                                    :style="
                                        (item.challenge_nation == 'kr' && item.card_state_kr.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                        (item.challenge_nation == 'kr' && item.card_state_kr.includes('기타'))? 'border:3px solid #F700FF;' : 
                                        (item.challenge_nation == 'en' && item.card_state_en.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                        (item.challenge_nation == 'en' && item.card_state_en.includes('기타'))? 'border:3px solid #F700FF;' : 
                                        (item.challenge_nation == 'jp' && item.card_state_jp.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                        (item.challenge_nation == 'jp' && item.card_state_jp.includes('기타'))? 'border:3px solid #F700FF;' : 
                                        ''
                                    "
                                    style="position:absolute; top:-8px; right:-4px; border-radius:20px; background:white; font-size:12px; font-weight:bold;"
                                    class="py-2px px-2"
                                >
                                    <!-- {{item.challenge_nation == 'kr'? item.card_state_kr : (item.challenge_nation == 'jp'? item.card_state_jp : item.card_state_en)}} -->
                                    {{view_state(item)}}
                                </div>

                                <!-- 기타 10등급 하이라이트 -->
                                <div
                                    v-if="
                                        (item.challenge_nation == 'kr' && item.card_state_kr == 'PSA 10등급')
                                        || (item.challenge_nation == 'en' && item.card_state_en == 'PSA 10등급')
                                        || (item.challenge_nation == 'jp' && item.card_state_jp == 'PSA 10등급')
                                    "
                                    style="position:absolute; width:140px; height:193px; border:5px solid #FF0000; inset; border-radius:5px;"
                                >
                                    <div

                                        style="position:absolute; top:-13px; right:-9px; border:3px solid #FF0000; border-radius:20px; background:white; font-size:12px; font-weight:bold;"
                                        class="py-2px px-2"
                                    >
                                        PSA 10
                                    </div>
                                </div>

                                <!-- 기타 10등급 하이라이트 -->
                                <div
                                    v-if="
                                        (item.challenge_nation == 'kr' && item.card_state_kr == '기타 10등급')
                                        || (item.challenge_nation == 'en' && item.card_state_en == '기타 10등급')
                                        || (item.challenge_nation == 'jp' && item.card_state_jp == '기타 10등급')
                                    "
                                    style="position:absolute; width:140px; height:193px; border:5px solid #F700FF; inset; border-radius:5px;"
                                >
                                    <div
                                        style="position:absolute; top:-13px; right:-9px; border:3px solid #F700FF; border-radius:20px; background:white; font-size:12px; font-weight:bold;"
                                        class="py-2px px-2"
                                    >
                                        등급 10
                                    </div>
                                </div>

                                <v-sheet height="193">
                                    <!-- <img width="140" height="193" src='@/assets/card.png' /> -->
                                    <img
                                        width="140"
                                        height="193"
                                        :src="view_image(item)"
                                    />
                                </v-sheet>

                                <!-- 라벨 -->
                                <v-sheet
                                    style="background:rgba(0,0,0,0.6); height:34px; width:140px; position:absolute; bottom:0px;"
                                    @click.stop="openDetail(item)"
                                >
                                    <p
                                        class="mt-2 mb-0 text-center px-2 text-truncate"
                                        style="font-size:12px;"
                                    >
                                        <span v-if="item.challenge_nation == 'kr'" class="white--text">{{item.number_kr.toString().padStart(3,'0')}} {{item.name_kr}}</span>
                                        <span v-if="item.challenge_nation == 'jp'" class="white--text">{{item.number_jp.toString().padStart(3,'0')}} {{item.name_jp}}</span>
                                        <span v-if="item.challenge_nation == 'en'" class="white--text">{{item.number_en.toString().padStart(3,'0')}} {{item.name_en}}</span>
                                    </p>
                                </v-sheet>
                            </div>

                            <!-- 희귀도 & 넘버/토탈넘버 -->
                            <v-sheet class="d-flex justify-space-between px-2 pt-2 pb-1 mx-auto" style="width:140px;">
                                <p
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    <span v-if="item.challenge_nation == 'kr'">{{item.rarity_kr}}</span>
                                    <span v-if="item.challenge_nation == 'jp'">{{item.rarity_jp}}</span>
                                    <span v-if="item.challenge_nation == 'en'">{{item.rarity_en}}</span>
                                </p>

                                <p
                                    v-if="item.challenge_nation == 'kr'"
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    {{item.number_kr}}/{{item.printedTotal_kr}}
                                </p>
                                <p
                                    v-if="item.challenge_nation == 'jp'"
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    {{item.number_jp}}/{{item.printedTotal_jp}}
                                </p>
                                <p
                                    v-if="item.challenge_nation == 'en'"
                                    class="font-weight-medium mb-0"
                                    style="font-size:11px;"
                                >
                                    {{item.number_en}}/{{item.printedTotal_en}}
                                </p>
                            </v-sheet>

                            <v-divider class="mx-auto" style="width:140px;"></v-divider>

                            <!-- 메모 & 수량 & 미보유/보유중 -->
                            <v-sheet class="d-flex align-center justify-space-between px-1 pt-6px mx-auto" style="width:140px;">
                                <!-- 메모 -->
                                <v-btn
                                    v-if="item.challenge_nation == 'kr'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.memo_kr.length? 'primary':'#B3B3C2'"
                                    @click="item_memo(item)"
                                >
                                    메모
                                </v-btn>
                                <v-btn
                                    v-if="item.challenge_nation == 'jp'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.memo_jp.length? 'primary':'#B3B3C2'"
                                    @click="item_memo(item)"
                                >
                                    메모
                                </v-btn>
                                <v-btn
                                    v-if="item.challenge_nation == 'en'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.memo_en.length? 'primary':'#B3B3C2'"
                                    @click="item_memo(item)"
                                >
                                    메모
                                </v-btn>
                                
                                <!-- 수량 증가/감소 -->
                                <div
                                    class="d-flex align-center mx-1"
                                >
                                    <v-icon
                                        :disabled="loading"
                                        size="16"
                                        @click="item_decrease(item)"
                                    >
                                        mdi-minus
                                    </v-icon>
                                    <p
                                        class="text-caption mx-1 mb-0 pb-2px"
                                    >
                                        <span v-if="item.challenge_nation == 'en'">{{item.amount_en}}</span>
                                        <span v-if="item.challenge_nation == 'kr'">{{item.amount_kr}}</span>
                                        <span v-if="item.challenge_nation == 'jp'">{{item.amount_jp}}</span>
                                    </p>
                                    <v-icon
                                        :disabled="loading"
                                        size="16"
                                        @click="item_increase(item)"
                                    >
                                        mdi-plus
                                    </v-icon>
                                </div>

                                <!-- 미보유/보유중 -->
                                <v-btn
                                    v-if="item.challenge_nation == 'kr'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.amount_kr? 'primary':'#B3B3C2'"
                                >
                                    {{item.amount_kr==0? '미보유':'보유중'}}
                                </v-btn>
                                <v-btn
                                    v-if="item.challenge_nation == 'jp'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.amount_jp? 'primary':'#B3B3C2'"
                                >
                                    {{item.amount_jp==0? '미보유':'보유중'}}
                                </v-btn>
                                <v-btn
                                    v-if="item.challenge_nation == 'en'"
                                    width="40"
                                    outlined
                                    x-small
                                    :color="item.amount_en? 'primary':'#B3B3C2'"
                                >
                                    {{item.amount_en==0? '미보유':'보유중'}}
                                </v-btn>
                            </v-sheet>
                        </v-col>
                    </v-row>

                    <!-- 목록 방식 (list) (PC) -->
                    <v-row
                        v-if="!$vuetify.breakpoint.mobile && list.length && list_type=='list'"
                        no-gutters
                        class="pt-2 mb-4"
                    >
                        <v-col
                            cols="12"
                        >
                            <div
                                class="d-flex py-4"
                                style="border-bottom:1px solid #eee; border-top:1px solid #ccc;"
                            >
                                <div
                                    style="width:110px;"
                                    class="text-center font-weight-bold"
                                >
                                    이미지
                                </div>
                                <div
                                    class="text-center font-weight-bold flex-grow-1"
                                >
                                    카드
                                </div>
                                <div
                                    style="width:106px;"
                                    class="text-center font-weight-bold"
                                >
                                    보유
                                </div>
                                <div
                                    style="width:102px;"
                                    class="text-center font-weight-bold"
                                >
                                    메모
                                </div>
                                <div
                                    style="width:166px;"
                                    class="text-center font-weight-bold"
                                >
                                    수량
                                </div>
                            </div>
                        </v-col>
                        <v-col
                            v-for="(item, index) in list" :key="'list'+index"
                            cols="12"
                            class="py-2"
                        >
                            <v-sheet class="pt-1 pb-3 d-flex align-center" style="border-bottom:1px solid #eee;">
                                <!-- 이미지 -->
                                <div
                                    style="width:120px;"
                                >
                                     <div
                                        width="60"
                                        style="cursor:pointer; position:relative; width:60px;"
                                        :style="
                                            (item.challenge_nation == 'kr' && item.amount_kr)
                                            || (item.challenge_nation == 'jp' && item.amount_jp)
                                            || (item.challenge_nation == 'en' && item.amount_en)
                                            ? '':'opacity:0.6; filter: grayscale(100%);'"
                                        class="mx-auto"
                                        @click="item_toggle(item)"
                                    >
                                        <v-sheet height="83">
                                            <!-- <img width="60" height="83" src='@/assets/card.png' /> -->
                                            <img width="60" height="83" :src="view_image(item)" />
                                        </v-sheet>
                                    </div>
                                </div>

                                <!-- 카드 -->
                                <v-sheet class="flex-grow-1 px-4">
                                    <!-- 카드이름 -->
                                    <div>
                                        <!-- 영어 -->
                                        <p
                                            v-if="item.challenge_nation=='en' && item.name_en"
                                            style="font-size:22px; font-weight:600; width:272px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.name_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="item.challenge_nation=='kr' && item.name_kr"
                                            style="font-size:22px; font-weight:600; width:272px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.name_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="item.challenge_nation=='jp' && item.name_jp"
                                            style="font-size:22px; font-weight:600; width:272px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.name_jp}}
                                        </p>
                                    </div>

                                    <!-- 레귤레이션, 넘버, 토탈넘버 & 희귀도 -->
                                    <div class="d-flex">
                                        <!-- 레귤레이션 -->
                                        <p
                                            v-if="item.regulationMark"
                                            style="font-size:12px;"
                                            class="mb-0"
                                        >
                                            <v-icon size="19" class="pb-2px mr-1">
                                                mdi-alpha-{{item.regulationMark.toLowerCase()}}-box-outline
                                            </v-icon>
                                        </p>

                                        <!-- 넘버, 토탈넘버 -->
                                        <div class="mr-2">
                                            <!-- 영어 -->
                                            <p
                                                v-if="item.challenge_nation=='en' && item.number_en && item.printedTotal_en"
                                                style="font-size:12px;"
                                                class="mb-0"
                                            >
                                                {{item.number_en}} / {{item.printedTotal_en}}
                                            </p>

                                            <!-- 한글 -->
                                            <p
                                                v-if="item.challenge_nation=='kr' && item.number_kr && item.printedTotal_kr"
                                                style="font-size:12px;"
                                                class="mb-0"
                                            >
                                                {{item.number_kr}} / {{item.printedTotal_kr}}
                                            </p>

                                            <!-- 일어 -->
                                            <p
                                                v-if="item.challenge_nation=='jp' && item.number_jp && item.printedTotal_jp"
                                                style="font-size:12px;"
                                                class="mb-0"
                                            >
                                                {{item.number_jp}} / {{item.printedTotal_jp}}
                                            </p>
                                        </div>

                                        <!-- 희귀도 -->
                                        <div>
                                            <!-- 영어 -->
                                            <p
                                                v-if="item.challenge_nation=='en' && item.rarity_en"
                                                style="font-size:12px;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_en}}
                                            </p>

                                            <!-- 한글 -->
                                            <p
                                                v-if="item.challenge_nation=='kr' && item.rarity_kr"
                                                style="font-size:12px;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_kr}}
                                            </p>

                                            <!-- 일어 -->
                                            <p
                                                v-if="item.challenge_nation=='jp' && item.rarity_jp"
                                                style="font-size:12px;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_jp}}
                                            </p>
                                        </div>
                                    </div>

                                    <!-- 제품명 -->
                                    <div class="ml-1px">
                                        <!-- 영어 -->
                                        <p
                                            v-if="item.challenge_nation=='en' && item.set_en"
                                            style="font-size:12px; width:272px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="item.challenge_nation=='kr' && item.set_kr"
                                            style="font-size:12px; width:272px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="item.challenge_nation=='jp' && item.set_jp"
                                            style="font-size:12px; width:272px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_jp}}
                                        </p>
                                    </div>
                                </v-sheet>

                                <!-- 보유 -->
                                <div
                                    style="width:110px;"
                                >
                                    <div
                                        class="d-flex justify-center"
                                    >
                                        <v-btn
                                            v-if="item.challenge_nation == 'kr'"
                                            width="60"
                                            outlined
                                            small
                                            :color="item.amount_kr? 'primary':'#B3B3C2'"
                                        >
                                            {{item.amount_kr==0? '미보유':'보유중'}}
                                        </v-btn>
                                        <v-btn
                                            v-if="item.challenge_nation == 'jp'"
                                            width="60"
                                            outlined
                                            small
                                            :color="item.amount_jp? 'primary':'#B3B3C2'"
                                        >
                                            {{item.amount_jp==0? '미보유':'보유중'}}
                                        </v-btn>
                                        <v-btn
                                            v-if="item.challenge_nation == 'en'"
                                            width="60"
                                            outlined
                                            small
                                            :color="item.amount_en? 'primary':'#B3B3C2'"
                                        >
                                            {{item.amount_en==0? '미보유':'보유중'}}
                                        </v-btn>
                                    </div>

                                    <!-- 기타 10등급 하이라이트 -->
                                    <!-- <div
                                        v-if="
                                            (item.challenge_nation == 'kr' && item.card_state_kr == 'PSA 10등급')
                                            || (item.challenge_nation == 'en' && item.card_state_en == 'PSA 10등급')
                                            || (item.challenge_nation == 'jp' && item.card_state_jp == 'PSA 10등급')
                                        "
                                        style="width:60px; border:3px solid #FF0000; border-radius:20px; background:white; font-size:12px; font-weight:bold;"
                                        class="py-2px text-center mx-auto mt-2"
                                    >
                                        PSA 10
                                    </div> -->

                                    <!-- 기타 10등급 하이라이트 -->
                                    <!-- <div
                                        v-if="
                                            (item.challenge_nation == 'kr' && item.card_state_kr == '기타 10등급')
                                            || (item.challenge_nation == 'en' && item.card_state_en == '기타 10등급')
                                            || (item.challenge_nation == 'jp' && item.card_state_jp == '기타 10등급')
                                        "
                                        style="width:60px; border:3px solid #F700FF; border-radius:20px; background:white; font-size:12px; font-weight:bold;"
                                        class="py-2px text-center mx-auto mt-2"
                                    >
                                        등급 10
                                    </div> -->

                                    <div
                                        v-if="
                                            (item.challenge_nation == 'kr' && (item.card_state_kr.includes('PSA') || item.card_state_kr.includes('기타')))
                                            || (item.challenge_nation == 'en' && (item.card_state_en.includes('PSA') || item.card_state_en.includes('기타')))
                                            || (item.challenge_nation == 'jp' && (item.card_state_jp.includes('PSA') || item.card_state_jp.includes('기타')))
                                        "
                                        :style="
                                            (item.challenge_nation == 'kr' && item.card_state_kr.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                            (item.challenge_nation == 'kr' && item.card_state_kr.includes('기타'))? 'border:3px solid #F700FF;' : 
                                            (item.challenge_nation == 'en' && item.card_state_en.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                            (item.challenge_nation == 'en' && item.card_state_en.includes('기타'))? 'border:3px solid #F700FF;' : 
                                            (item.challenge_nation == 'jp' && item.card_state_jp.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                            (item.challenge_nation == 'jp' && item.card_state_jp.includes('기타'))? 'border:3px solid #F700FF;' : 
                                            ''
                                        "
                                        style="width:80px; border:3px solid #F700FF; border-radius:20px; background:white; font-size:12px; font-weight:bold;"
                                        class="py-2px text-center mx-auto mt-2"
                                    >
                                        <!-- {{item.challenge_nation == 'kr'? item.card_state_kr : (item.challenge_nation == 'jp'? item.card_state_jp : item.card_state_en)}} -->
                                        {{view_state(item)}}
                                    </div>
                                </div>

                                <!-- 메모 -->
                                <div
                                    class="d-flex justify-center"
                                    style="width:110px;"
                                >
                                    <v-btn
                                        v-if="item.challenge_nation == 'kr'"
                                        width="60"
                                        outlined
                                        small
                                        :color="item.memo_kr.length? 'primary':'#B3B3C2'"
                                        @click="item_memo(item)"
                                    >
                                        메모
                                    </v-btn>
                                    <v-btn
                                        v-if="item.challenge_nation == 'jp'"
                                        width="60"
                                        outlined
                                        small
                                        :color="item.memo_jp.length? 'primary':'#B3B3C2'"
                                        @click="item_memo(item)"
                                    >
                                        메모
                                    </v-btn>
                                    <v-btn
                                        v-if="item.challenge_nation == 'en'"
                                        width="60"
                                        outlined
                                        small
                                        :color="item.memo_en.length? 'primary':'#B3B3C2'"
                                        @click="item_memo(item)"
                                    >
                                        메모
                                    </v-btn>
                                </div>

                                <!-- 수량 -->
                                <div
                                    style="width:160px;"
                                    class="d-flex align-center justify-center mx-3 pb-2px"
                                >
                                    <v-icon
                                        :disabled="loading"
                                        size="20"
                                        @click="item_decrease(item)"
                                    >
                                        mdi-minus
                                    </v-icon>
                                    <span
                                        class="text-caption mx-2"
                                    >
                                        <span v-if="item.challenge_nation == 'kr'">{{item.amount_kr}}</span>
                                        <span v-if="item.challenge_nation == 'jp'">{{item.amount_jp}}</span>
                                        <span v-if="item.challenge_nation == 'en'">{{item.amount_en}}</span>
                                    </span>
                                    <v-icon
                                        :disabled="loading"
                                        size="20"
                                        @click="item_increase(item)"
                                    >
                                        mdi-plus
                                    </v-icon>
                                </div>
                            </v-sheet>
                        </v-col>
                    </v-row>

                    <!-- 목록 방식 (list) (Mobile) -->
                    <v-row
                        v-if="$vuetify.breakpoint.mobile && list.length && list_type=='list'"
                        no-gutters
                        class="pt-1 mb-4"
                        style="border-top:1px solid #ccc;"
                    >
                        <v-col
                            v-for="(item, index) in list" :key="'list'+index"
                            cols="12"
                            class="py-2"
                        >
                            <v-sheet class="pt-1 pb-3" style="border-bottom:1px solid #eee;">
                                <div
                                    class="d-flex align-center"
                                >
                                    <!-- 이미지 -->
                                    <div
                                        style="width:120px;"
                                    >
                                        <div
                                            width="60"
                                            style="cursor:pointer; position:relative; width:60px;"
                                            :style="
                                                (item.challenge_nation == 'kr' && item.amount_kr)
                                                || (item.challenge_nation == 'jp' && item.amount_jp)
                                                || (item.challenge_nation == 'en' && item.amount_en)
                                                ? '':'opacity:0.6; filter: grayscale(100%);'"
                                            class="mx-auto"
                                            @click="item_increase(item)"
                                        >
                                            <v-sheet height="83">
                                                <!-- <img width="60" height="83" src='@/assets/card.png' /> -->
                                                <img width="60" height="83" :src="view_image(item)" />
                                            </v-sheet>
                                        </div>
                                    </div>

                                    <!-- 카드 -->
                                    <v-sheet class="flex-grow-1 px-4">
                                        <!-- 카드이름 -->
                                        <div>
                                            <!-- 영어 -->
                                            <p
                                                v-if="item.challenge_nation=='en' && item.name_en"
                                                style="font-size:22px; font-weight:600; width:272px;"
                                                class="mb-1 text-truncate"
                                            >
                                                {{item.name_en}}
                                            </p>

                                            <!-- 한글 -->
                                            <p
                                                v-if="item.challenge_nation=='kr' && item.name_kr"
                                                style="font-size:22px; font-weight:600; width:272px;"
                                                class="mb-1 text-truncate"
                                            >
                                                {{item.name_kr}}
                                            </p>

                                            <!-- 일어 -->
                                            <p
                                                v-if="item.challenge_nation=='jp' && item.name_jp"
                                                style="font-size:22px; font-weight:600; width:272px;"
                                                class="mb-1 text-truncate"
                                            >
                                                {{item.name_jp}}
                                            </p>
                                        </div>

                                        <!-- 레귤레이션, 넘버, 토탈넘버 & 희귀도 -->
                                        <div class="d-flex">
                                            <!-- 레귤레이션 -->
                                            <p
                                                v-if="item.regulationMark"
                                                style="font-size:12px;"
                                                class="mb-0"
                                            >
                                                <v-icon size="19" class="pb-2px mr-1">
                                                    mdi-alpha-{{item.regulationMark.toLowerCase()}}-box-outline
                                                </v-icon>
                                            </p>

                                            <!-- 넘버, 토탈넘버 -->
                                            <div class="mr-2">
                                                <!-- 영어 -->
                                                <p
                                                    v-if="item.challenge_nation=='en' && item.number_en && item.printedTotal_en"
                                                    style="font-size:12px;"
                                                    class="mb-0"
                                                >
                                                    {{item.number_en}} / {{item.printedTotal_en}}
                                                </p>

                                                <!-- 한글 -->
                                                <p
                                                    v-if="item.challenge_nation=='kr' && item.number_kr && item.printedTotal_kr"
                                                    style="font-size:12px;"
                                                    class="mb-0"
                                                >
                                                    {{item.number_kr}} / {{item.printedTotal_kr}}
                                                </p>

                                                <!-- 일어 -->
                                                <p
                                                    v-if="item.challenge_nation=='jp' && item.number_jp && item.printedTotal_jp"
                                                    style="font-size:12px;"
                                                    class="mb-0"
                                                >
                                                    {{item.number_jp}} / {{item.printedTotal_jp}}
                                                </p>
                                            </div>

                                            <!-- 희귀도 -->
                                            <div>
                                                <!-- 영어 -->
                                                <p
                                                    v-if="item.challenge_nation=='en' && item.rarity_en"
                                                    style="font-size:12px;"
                                                    class="mb-0"
                                                >
                                                    {{item.rarity_en}}
                                                </p>

                                                <!-- 한글 -->
                                                <p
                                                    v-if="item.challenge_nation=='kr' && item.rarity_kr"
                                                    style="font-size:12px;"
                                                    class="mb-0"
                                                >
                                                    {{item.rarity_kr}}
                                                </p>

                                                <!-- 일어 -->
                                                <p
                                                    v-if="item.challenge_nation=='jp' && item.rarity_jp"
                                                    style="font-size:12px;"
                                                    class="mb-0"
                                                >
                                                    {{item.rarity_jp}}
                                                </p>
                                            </div>
                                        </div>

                                        <!-- 제품명 -->
                                        <div class="ml-1px">
                                            <!-- 영어 -->
                                            <p
                                                v-if="item.challenge_nation=='en' && item.set_en"
                                                style="font-size:12px; width:272px;"
                                                class="mb-1 text-truncate"
                                            >
                                                {{item.set_en}}
                                            </p>

                                            <!-- 한글 -->
                                            <p
                                                v-if="item.challenge_nation=='kr' && item.set_kr"
                                                style="font-size:12px; width:272px;"
                                                class="mb-1 text-truncate"
                                            >
                                                {{item.set_kr}}
                                            </p>

                                            <!-- 일어 -->
                                            <p
                                                v-if="item.challenge_nation=='jp' && item.set_jp"
                                                style="font-size:12px; width:272px;"
                                                class="mb-1 text-truncate"
                                            >
                                                {{item.set_jp}}
                                            </p>
                                        </div>
                                    </v-sheet>
                                </div>

                                <div
                                    class="d-flex justify-end"
                                >
                                    <!-- 보유 -->
                                    <div
                                        style="width:70px;"
                                    >
                                        <div
                                            class="d-flex justify-center"
                                        >
                                            <v-btn
                                                v-if="item.challenge_nation == 'kr'"
                                                width="60"
                                                outlined
                                                small
                                                :color="item.amount_kr? 'primary':'#B3B3C2'"
                                            >
                                                {{item.amount_kr==0? '미보유':'보유중'}}
                                            </v-btn>
                                            <v-btn
                                                v-if="item.challenge_nation == 'jp'"
                                                width="60"
                                                outlined
                                                small
                                                :color="item.amount_jp? 'primary':'#B3B3C2'"
                                            >
                                                {{item.amount_jp==0? '미보유':'보유중'}}
                                            </v-btn>
                                            <v-btn
                                                v-if="item.challenge_nation == 'en'"
                                                width="60"
                                                outlined
                                                small
                                                :color="item.amount_en? 'primary':'#B3B3C2'"
                                            >
                                                {{item.amount_en==0? '미보유':'보유중'}}
                                            </v-btn>
                                        </div>

                                        <div
                                            v-if="
                                                (item.challenge_nation == 'kr' && (item.card_state_kr.includes('PSA') || item.card_state_kr.includes('기타')))
                                                || (item.challenge_nation == 'en' && (item.card_state_en.includes('PSA') || item.card_state_en.includes('기타')))
                                                || (item.challenge_nation == 'jp' && (item.card_state_jp.includes('PSA') || item.card_state_jp.includes('기타')))
                                            "
                                            :style="
                                                (item.challenge_nation == 'kr' && item.card_state_kr.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                                (item.challenge_nation == 'kr' && item.card_state_kr.includes('기타'))? 'border:3px solid #F700FF;' : 
                                                (item.challenge_nation == 'en' && item.card_state_en.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                                (item.challenge_nation == 'en' && item.card_state_en.includes('기타'))? 'border:3px solid #F700FF;' : 
                                                (item.challenge_nation == 'jp' && item.card_state_jp.includes('PSA'))? 'border:3px solid #FF0000;' : 
                                                (item.challenge_nation == 'jp' && item.card_state_jp.includes('기타'))? 'border:3px solid #F700FF;' : 
                                                ''
                                            "
                                            style="width:60px; border:3px solid #F700FF; border-radius:20px; background:white; font-size:12px; font-weight:bold;"
                                            class="py-2px text-center mx-auto mt-2"
                                        >
                                            <!-- {{item.challenge_nation == 'kr'? item.card_state_kr : (item.challenge_nation == 'jp'? item.card_state_jp : item.card_state_en)}} -->
                                            {{view_state(item)}}
                                        </div>
                                    </div>

                                    <!-- 메모 -->
                                    <div
                                        class="d-flex justify-center"
                                        style="width:70px;"
                                    >
                                        <v-btn
                                            v-if="item.challenge_nation == 'kr'"
                                            width="60"
                                            outlined
                                            small
                                            :color="item.memo_kr.length? 'primary':'#B3B3C2'"
                                            @click="item_memo(item)"
                                        >
                                            메모
                                        </v-btn>
                                        <v-btn
                                            v-if="item.challenge_nation == 'jp'"
                                            width="60"
                                            outlined
                                            small
                                            :color="item.memo_jp.length? 'primary':'#B3B3C2'"
                                            @click="item_memo(item)"
                                        >
                                            메모
                                        </v-btn>
                                        <v-btn
                                            v-if="item.challenge_nation == 'en'"
                                            width="60"
                                            outlined
                                            small
                                            :color="item.memo_en.length? 'primary':'#B3B3C2'"
                                            @click="item_memo(item)"
                                        >
                                            메모
                                        </v-btn>
                                    </div>

                                    <!-- 수량 -->
                                    <div
                                        style="width:90px;"
                                        class="d-flex align-center justify-center mx-1 pb-2px"
                                    >
                                        <v-icon
                                            :disabled="loading"
                                            size="20"
                                            @click="item_decrease(item)"
                                        >
                                            mdi-minus
                                        </v-icon>
                                        <span
                                            class="text-caption mx-2"
                                        >
                                            <span v-if="item.challenge_nation == 'kr'">{{item.amount_kr}}</span>
                                            <span v-if="item.challenge_nation == 'jp'">{{item.amount_jp}}</span>
                                            <span v-if="item.challenge_nation == 'en'">{{item.amount_en}}</span>
                                        </span>
                                        <v-icon
                                            :disabled="loading"
                                            size="20"
                                            @click="item_increase(item)"
                                        >
                                            mdi-plus
                                        </v-icon>
                                    </div>
                                </div>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>
            </v-sheet>
        </div>

        <!-- 도전자 목록 -->
        <v-sheet
            v-if="view_type == '도전자 목록'"
            class="rounded-15 pb-10 mb-4"
        >
            <!-- PC -->
            <div
                v-if="!$vuetify.breakpoint.mobile"
            >
                <!-- 목록 상단 -->
                <v-sheet
                    height="40"
                    color="#F0F3F5"
                    class="pt-1 d-flex rounded-t-15"
                >
                    <div
                        style="width:100px; font-size:14px;"
                        class="text-center mt-1"
                    >
                        #
                    </div>
                    <div
                        style="width:300px; font-size:14px;"
                        class="text-center mt-1"
                    >
                        도전자
                    </div>

                    <div
                        style="font-size:14px;"
                        class="text-center mt-1 flex-grow-1"
                    >
                        진행률
                    </div>
                    <div
                        style="width:120px; font-size:14px;"
                        class="text-center mt-1"
                    >
                        비고
                    </div>
                </v-sheet>

                <!-- 도전자가 없을떄 -->
                <div
                    v-if="apply_list.length == 0"
                    class="mt-5 text-center py-10"
                >
                    <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                    <p style="color:#ccc">도전 참가자가 아직 없습니다</p>
                </div>

                <!-- 목록 (PC) -->
                <div
                    v-if="apply_list.length"
                >
                    <v-sheet
                        v-for="(item, index) in apply_list" :key="item.id"
                        height="56"
                        class="d-flex align-center ma-0 pa-0"
                        style="position:relative; cursor:pointer;"
                    >
                        <div
                            style="width:100px; font-size:14px; height:56px; border-bottom:1px solid #C8C8C8;"
                            class="text-center d-flex align-center justify-center"
                        >
                            {{index + 1}}
                        </div>

                        <div
                            class="d-flex justify-space-between align-center pr-3"
                            style="width:300px; height:56px; border-bottom:1px solid #C8C8C8;"
                        >
                            <!-- 프로필 -->
                            <ProfileForChallenge
                                :user="item.user_id"
                                color="black"
                            />

                            <div>
                                <v-img
                                    v-if="item.is_cert"
                                    width="36"
                                    height="36"
                                    class="rounded-circle"
                                    style="cursor:pointer;"
                                    src="@/assets/cert.png"
                                ></v-img>
                            </div>
                        </div>

                        <!-- 점유율 & RP -->
                        <div
                            class="d-flex flex-grow-1"
                            style="position:relative;"
                        >
                            <div
                                style="width:100%; font-size:14px; height:56px; background:#FAFAFA;"
                                class="text-center d-flex align-center justify-center font-weight-medium"
                            >
                                {{item.count}} / {{card_amount}}
                            </div>

                            <v-progress-linear
                                style="position:absolute; width:100%; bottom:0px; right:0px; z-index:5;"
                                height="2"
                                color="#F8468D"
                                background-color="grey lighten-3"
                                :value="calculateProbability(item.count, card_amount)"
                            ></v-progress-linear>
                        </div>

                        <div
                            style="width:120px; font-size:14px; height:56px; border-bottom:1px solid #C8C8C8; cursor:pointer;"
                            class="text-center d-flex align-center justify-center font-weight-medium primary--text"
                            @click="$router.push({ query: { ...$route.query, user_id: item.user_id }})"
                        >
                            자세히
                        </div>
                    </v-sheet>
                </div>
            </div>

            <!-- Mobile -->
            <div
                v-if="$vuetify.breakpoint.mobile"
            >
                <!-- 목록 상단 -->
                <v-sheet
                    height="40"
                    color="#F0F3F5"
                    class="pt-1 d-flex rounded-t-15"
                >
                    <div
                        style="width:60px; font-size:14px;"
                        class="text-center mt-1"
                    >
                        #
                    </div>
                    <div
                        style="width:160px; font-size:14px;"
                        class="text-center mt-1"
                    >
                        도전자
                    </div>

                    <div
                        style="width:80px; font-size:14px;"
                        class="text-center mt-1 flex-grow-1"
                    >
                        진행률
                    </div>
                    <div
                        style="width:70px; font-size:14px;"
                        class="text-center mt-1"
                    >
                        비고
                    </div>
                </v-sheet>

                <!-- 도전자가 없을떄 -->
                <div
                    v-if="apply_list.length == 0"
                    class="mt-5 text-center py-10"
                >
                    <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                    <p style="color:#ccc">도전 참가자가 아직 없습니다</p>
                </div>

                <!-- 목록 -->
                <div
                    v-if="apply_list.length"
                >
                    <v-sheet
                        v-for="(item, index) in apply_list" :key="item.id"
                        height="56"
                        class="d-flex align-center ma-0 pa-0"
                        style="position:relative; cursor:pointer;"
                    >
                        <div
                            style="width:60px; font-size:14px; height:56px; border-bottom:1px solid #C8C8C8;"
                            class="text-center d-flex align-center justify-center"
                        >
                            {{index + 1}}
                        </div>

                        <div
                            class="d-flex justify-space-between align-center pr-3"
                            style="width:160px; height:56px; border-bottom:1px solid #C8C8C8;"
                        >
                            <!-- 프로필 -->
                            <ProfileForChallenge
                                :user="item.user_id"
                                color="black"
                            />

                            <div>
                                <v-img
                                    v-if="item.is_cert"
                                    width="24"
                                    height="24"
                                    class="rounded-circle"
                                    style="cursor:pointer;"
                                    src="@/assets/cert.png"
                                ></v-img>
                            </div>
                        </div>

                        <!-- 점유율 & RP -->
                        <div
                            class="d-flex flex-grow-1"
                            style="width:80px; position:relative;"
                        >
                            <div
                                style="width:100%; font-size:14px; height:56px; background:#FAFAFA;"
                                class="text-center d-flex align-center justify-center font-weight-medium"
                            >
                                {{item.count}} / {{card_amount}}
                            </div>

                            <v-progress-linear
                                style="position:absolute; width:100%; bottom:0px; right:0px; z-index:5;"
                                height="2"
                                color="#F8468D"
                                background-color="grey lighten-3"
                                :value="calculateProbability(item.count, card_amount)"
                            ></v-progress-linear>
                        </div>

                        <div
                            style="width:70px; font-size:10px; height:56px; border-bottom:1px solid #C8C8C8; cursor:pointer;"
                            class="text-center d-flex align-center justify-center font-weight-medium primary--text"
                            @click="$router.push({ query: { ...$route.query, user_id: item.user_id }})"
                        >
                            자세히
                        </div>
                    </v-sheet>
                </div>
            </div>
        </v-sheet>

        <!-- 카드 관리 -->
        <v-dialog
            v-model="dialog.card_manage"
            content-class="rounded-10"
            width="auto"
        >
            <CardManage
                @close="dialog.card_manage = false; load(); search(); loadLogList();"
                @delete_all="dialog.card_manage = false; card_amount = 0; search(); loadLogList();"
            />
        </v-dialog>

        <!-- 카드 메모 (dialog) -->
        <v-dialog
            v-model="dialog.memo"
            :width="$vuetify.breakpoint.mobile? '':440"
            content-class="rounded-15"
        >
            <!-- PC -->
            <v-sheet
                v-if="!$vuetify.breakpoint.mobile"
                class="py-4 px-10"
            >
                <p
                    class="text-center mt-1"
                    style="font-size:20px; font-weight:bold; color:#424361;"
                >
                    카드 메모
                </p>

                <v-textarea
                    v-if="selected_item.challenge_nation == 'kr'"
                    class="rounded-15"
                    hide-details
                    rows="3"
                    outlined
                    v-model="selected_item.memo_kr"
                    placeholder="메모할 내용을 입력해주세요"
                ></v-textarea>
                <v-textarea
                    v-if="selected_item.challenge_nation == 'en'"
                    class="rounded-15"
                    hide-details
                    rows="3"
                    outlined
                    v-model="selected_item.memo_en"
                    placeholder="메모할 내용을 입력해주세요"
                ></v-textarea>
                <v-textarea
                    v-if="selected_item.challenge_nation == 'jp'"
                    class="rounded-15"
                    hide-details
                    rows="3"
                    outlined
                    v-model="selected_item.memo_jp"
                    placeholder="메모할 내용을 입력해주세요"
                ></v-textarea>
                <!-- 희귀도 & 넘버/토탈넘버 -->
                <v-sheet class="d-flex justify-space-between px-3 pt-2 pb-1 text-body-2">
                    <p
                        class="mb-0"
                        style="color:#615DFA;"
                    >
                        <span v-if="selected_item.challenge_nation == 'en'">{{selected_item.name_en}}</span>
                        <span v-if="selected_item.challenge_nation == 'kr'">{{selected_item.name_kr}}</span>
                        <span v-if="selected_item.challenge_nation == 'jp'">{{selected_item.name_jp}}</span>
                    </p>

                    <p
                        class="mb-0"
                    >
                        <span v-if="selected_item.challenge_nation == 'en'">{{selected_item.rarity_en}} {{selected_item.number_en}}/{{selected_item.printedTotal_en}}</span>
                        <span v-if="selected_item.challenge_nation == 'kr'">{{selected_item.rarity_kr}} {{selected_item.number_kr}}/{{selected_item.printedTotal_kr}}</span>
                        <span v-if="selected_item.challenge_nation == 'jp'">{{selected_item.rarity_jp}} {{selected_item.number_jp}}/{{selected_item.printedTotal_jp}}</span>
                    </p>
                </v-sheet>

                <v-sheet
                    class="d-flex mt-3"
                >
                    <v-select
                        v-if="selected_item.challenge_nation == 'kr'"
                        class="rounded-10 shrink mb-3 font-weight-normal"
                        style="width:174px;"
                        hide-details
                        dense
                        outlined
                        placeholder="카드상태"
                        :items="select_list.state"
                        v-model="selected_item.card_state_kr"
                        @change="componentKey++"
                    ></v-select>
                    <v-select
                        v-if="selected_item.challenge_nation == 'en'"
                        class="rounded-10 shrink mb-3 font-weight-normal"
                        style="width:174px;"
                        hide-details
                        dense
                        outlined
                        placeholder="카드상태"
                        :items="select_list.state"
                        v-model="selected_item.card_state_en"
                        @change="componentKey++"
                    ></v-select>
                    <v-select
                        v-if="selected_item.challenge_nation == 'jp'"
                        class="rounded-10 shrink mb-3 font-weight-normal"
                        style="width:174px;"
                        hide-details
                        dense
                        outlined
                        placeholder="카드상태"
                        :items="select_list.state"
                        v-model="selected_item.card_state_jp"
                        @change="componentKey++"
                    ></v-select>

                    <v-spacer></v-spacer>

                    <div
                        v-if="selected_item.challenge_nation == 'kr'"
                        class="d-flex align-center justify-space-between"
                        style="width:174px; border:1px solid #eee; border-radius:10px; height:40px;"
                        :key="componentKey"
                    >
                        <v-btn
                            :disabled="selected_item.card_state_kr != '직접 입력'"
                            text
                            v-ripple="false"
                            width="20"
                            class="mr-1"
                            @click="state2_decrease()"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>

                        <v-text-field
                            :disabled="selected_item.card_state_kr != '직접 입력'"
                            hide-details
                            dense
                            solo
                            flat
                            v-model="state2"
                            :key="componentKey"
                            prefix="등급"
                        ></v-text-field>

                        <v-btn
                            :disabled="selected_item.card_state_kr != '직접 입력'"
                            text
                            v-ripple="false"
                            width="20"
                            @click="state2_increase()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </div>

                    <div
                        v-if="selected_item.challenge_nation == 'en'"
                        class="d-flex align-center justify-space-between"
                        style="width:174px; border:1px solid #eee; border-radius:10px; height:40px;"
                        :key="componentKey"
                    >
                        <v-btn
                            :disabled="selected_item.card_state_en != '직접 입력'"
                            text
                            v-ripple="false"
                            width="20"
                            class="mr-1"
                            @click="state2_decrease()"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>

                        <v-text-field
                            :disabled="selected_item.card_state_en != '직접 입력'"
                            hide-details
                            dense
                            solo
                            flat
                            v-model="state2"
                            :key="componentKey"
                            prefix="등급"
                        ></v-text-field>

                        <v-btn
                            :disabled="selected_item.card_state_en != '직접 입력'"
                            text
                            v-ripple="false"
                            width="20"
                            @click="state2_increase()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </div>

                    <div
                        v-if="selected_item.challenge_nation == 'jp'"
                        class="d-flex align-center justify-space-between"
                        style="width:174px; border:1px solid #eee; border-radius:10px; height:40px;"
                        :key="componentKey"
                    >
                        <v-btn
                            :disabled="selected_item.card_state_jp != '직접 입력'"
                            text
                            v-ripple="false"
                            width="20"
                            class="mr-1"
                            @click="state2_decrease()"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>

                        <v-text-field
                            :disabled="selected_item.card_state_jp != '직접 입력'"
                            hide-details
                            dense
                            solo
                            flat
                            v-model="state2"
                            :key="componentKey"
                            prefix="등급"
                        ></v-text-field>

                        <v-btn
                            :disabled="selected_item.card_state_jp != '직접 입력'"
                            text
                            v-ripple="false"
                            width="20"
                            @click="state2_increase()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </div>

                </v-sheet>

                <div
                    class="d-flex justify-center mt-4"
                >
                    <v-btn
                        width="80"
                        height="36"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="rounded-10 mr-3"
                        color="#615DFA"
                        @click="save_memo()"
                    >
                        저장
                    </v-btn>

                    <v-btn
                        width="80"
                        height="36"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="rounded-10"
                        color="#ADAFCA"
                        @click="dialog.memo = false;"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>

            <!-- Mobile -->
            <v-sheet
                v-if="$vuetify.breakpoint.mobile"
                class="py-4 px-6"
            >
                <p
                    class="text-center mt-1"
                    style="font-size:20px; font-weight:bold; color:#424361;"
                >
                    카드 메모
                </p>

                <v-textarea
                    v-if="selected_item.challenge_nation == 'kr'"
                    class="rounded-15"
                    hide-details
                    rows="3"
                    outlined
                    v-model="selected_item.memo_kr"
                    placeholder="메모할 내용을 입력해주세요"
                ></v-textarea>
                <v-textarea
                    v-if="selected_item.challenge_nation == 'en'"
                    class="rounded-15"
                    hide-details
                    rows="3"
                    outlined
                    v-model="selected_item.memo_en"
                    placeholder="메모할 내용을 입력해주세요"
                ></v-textarea>
                <v-textarea
                    v-if="selected_item.challenge_nation == 'jp'"
                    class="rounded-15"
                    hide-details
                    rows="3"
                    outlined
                    v-model="selected_item.memo_jp"
                    placeholder="메모할 내용을 입력해주세요"
                ></v-textarea>
                <!-- 희귀도 & 넘버/토탈넘버 -->
                <v-sheet class="d-flex justify-space-between px-1 pt-2 pb-1 text-caption">
                    <p
                        class="mb-0"
                        style="color:#615DFA;"
                    >
                        <span v-if="selected_item.challenge_nation == 'en'">{{selected_item.name_en}}</span>
                        <span v-if="selected_item.challenge_nation == 'kr'">{{selected_item.name_kr}}</span>
                        <span v-if="selected_item.challenge_nation == 'jp'">{{selected_item.name_jp}}</span>
                    </p>

                    <p
                        class="mb-0"
                    >
                        <span v-if="selected_item.challenge_nation == 'en'">{{selected_item.rarity_en}} {{selected_item.number_en}}/{{selected_item.printedTotal_en}}</span>
                        <span v-if="selected_item.challenge_nation == 'kr'">{{selected_item.rarity_kr}} {{selected_item.number_kr}}/{{selected_item.printedTotal_kr}}</span>
                        <span v-if="selected_item.challenge_nation == 'jp'">{{selected_item.rarity_jp}} {{selected_item.number_jp}}/{{selected_item.printedTotal_jp}}</span>
                    </p>
                </v-sheet>

                <v-select
                    v-if="selected_item.challenge_nation == 'kr'"
                    class="rounded-10 mx-auto mt-3 mb-3 font-weight-normal"
                    style="width:174px;"
                    hide-details
                    dense
                    outlined
                    placeholder="카드상태"
                    :items="select_list.state"
                    v-model="selected_item.card_state_kr"
                    @change="componentKey++"
                ></v-select>
                <v-select
                    v-if="selected_item.challenge_nation == 'en'"
                    class="rounded-10 mx-auto mt-3 mb-3 font-weight-normal"
                    style="width:174px;"
                    hide-details
                    dense
                    outlined
                    placeholder="카드상태"
                    :items="select_list.state"
                    v-model="selected_item.card_state_en"
                    @change="componentKey++"
                ></v-select>
                <v-select
                    v-if="selected_item.challenge_nation == 'jp'"
                    class="rounded-10 mx-auto mt-3 mb-3 font-weight-normal"
                    style="width:174px;"
                    hide-details
                    dense
                    outlined
                    placeholder="카드상태"
                    :items="select_list.state"
                    v-model="selected_item.card_state_jp"
                    @change="componentKey++"
                ></v-select>

                <div
                    v-if="selected_item.challenge_nation == 'kr'"
                    class="d-flex align-center justify-space-between mx-auto"
                    style="width:174px; border:1px solid #eee; border-radius:10px; height:40px;"
                    :key="componentKey"
                >
                    <v-btn
                        :disabled="selected_item.card_state_kr != '직접 입력'"
                        text
                        v-ripple="false"
                        width="20"
                        class="mr-1"
                        @click="state2_decrease()"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>

                    <v-text-field
                        :disabled="selected_item.card_state_kr != '직접 입력'"
                        hide-details
                        dense
                        solo
                        flat
                        v-model="state2"
                        :key="componentKey"
                        prefix="등급"
                    ></v-text-field>

                    <v-btn
                        :disabled="selected_item.card_state_kr != '직접 입력'"
                        text
                        v-ripple="false"
                        width="20"
                        @click="state2_increase()"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </div>

                <div
                    v-if="selected_item.challenge_nation == 'en'"
                    class="d-flex align-center justify-space-between mx-auto"
                    style="width:174px; border:1px solid #eee; border-radius:10px; height:40px;"
                    :key="componentKey"
                >
                    <v-btn
                        :disabled="selected_item.card_state_en != '직접 입력'"
                        text
                        v-ripple="false"
                        width="20"
                        class="mr-1"
                        @click="state2_decrease()"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>

                    <v-text-field
                        :disabled="selected_item.card_state_en != '직접 입력'"
                        hide-details
                        dense
                        solo
                        flat
                        v-model="state2"
                        :key="componentKey"
                        prefix="등급"
                    ></v-text-field>

                    <v-btn
                        :disabled="selected_item.card_state_en != '직접 입력'"
                        text
                        v-ripple="false"
                        width="20"
                        @click="state2_increase()"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </div>

                <div
                    v-if="selected_item.challenge_nation == 'jp'"
                    class="d-flex align-center justify-space-between mx-auto"
                    style="width:174px; border:1px solid #eee; border-radius:10px; height:40px;"
                    :key="componentKey"
                >
                    <v-btn
                        :disabled="selected_item.card_state_jp != '직접 입력'"
                        text
                        v-ripple="false"
                        width="20"
                        class="mr-1"
                        @click="state2_decrease()"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>

                    <v-text-field
                        :disabled="selected_item.card_state_jp != '직접 입력'"
                        hide-details
                        dense
                        solo
                        flat
                        v-model="state2"
                        :key="componentKey"
                        prefix="등급"
                    ></v-text-field>

                    <v-btn
                        :disabled="selected_item.card_state_jp != '직접 입력'"
                        text
                        v-ripple="false"
                        width="20"
                        @click="state2_increase()"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </div>

                <div
                    class="d-flex justify-center mt-4"
                >
                    <v-btn
                        width="80"
                        height="36"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="rounded-10 mr-3"
                        color="#615DFA"
                        @click="save_memo()"
                    >
                        저장
                    </v-btn>

                    <v-btn
                        width="80"
                        height="36"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="rounded-10"
                        color="#ADAFCA"
                        @click="dialog.memo = false;"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 목록 출력 (dialog) -->
        <v-dialog
            v-model="dialog.list"
            width="600"
            content-class="rounded-15"
        >
            <v-sheet
                class="py-4 px-10"
            >
                <p
                    class="text-center"
                    style="font-size:20px; font-weight:bold; color:#424361;"
                >
                    목록 출력
                </p>
                <v-textarea
                    class="rounded-15"
                    hide-details
                    rows="10"
                    outlined
                    v-model="export_list"
                ></v-textarea>

                <div
                    class="d-flex justify-center mt-4"
                >
                    <v-btn
                        width="80"
                        height="36"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="rounded-10 mx-3"
                        color="#615DFA"
                        @click="copySubmit()"
                    >
                        복사
                    </v-btn>

                    <v-btn
                        width="80"
                        height="36"
                        depressed
                        dark
                        style="font-size:18px; font-weight:bold;"
                        class="rounded-10"
                        color="#ADAFCA"
                        @click="dialog.list = false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 챌린지북 수정 (dialog) -->
        <v-dialog
            v-model="dialog.update"
            width="auto"
            content-class="rounded-15"
        >
            <UpdateChallenge
                :title_import="title"
                :is_open_import="is_open"
                :image_import="image"
                :content_import="content"
                @close="dialog.update = false"
                @updated="dialog.update = false; load()"
            />
        </v-dialog>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 40%; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="100"
        ></v-progress-circular>
    </div>
</template>
<script>
import Header from "../components/Header"
import CardManage from "./read/CardManage"
import ProfileForChallenge from "@/components/ProfileForChallenge"
import UpdateChallenge from "./read/UpdateChallenge"
import html2canvas from 'html2canvas'

export default {
    components: {
        Header,
        CardManage,
        ProfileForChallenge,
        UpdateChallenge,
        html2canvas
    },

    data: () => ({
        card_list: [
            {
                card_view_name: "",
                card_id: "",
                card_nation: "",
                card_name: "",
            }
        ],

        select_list: {
            state: [
                '없음',
                '직접 입력',
                'S급',
                'A급',
                'B급',
                'C급 이하',
                'PSA 10등급',
                'PSA 9등급',
                'PSA 8등급',
                'PSA 7등급 이하',
                '기타 10등급',
                '기타 9등급',
                '기타 8등급',
                '기타 7등급',
            ]
        },

        state_list: [
            'PSA 10등급',
            'PSA 9등급',
            'PSA 8등급',
            'PSA 7등급 이하',
            'PSA 7등급',
            '기타 10등급',
            '기타 9.5등급',
            '기타 9등급',
            '기타 8.5등급',
            '기타 8등급',
            '기타 7.5등급',
            '기타 7등급',
            '기타 6.5등급',
            '기타 6등급',
            '기타 5.5등급',
            '기타 5등급',
            '기타 4.5등급',
            '기타 4등급',
            '기타 3.5등급',
            '기타 3등급',
            '기타 2.5등급',
            '기타 2등급',
            '기타 1.5등급',
            '기타 1등급',
            'S급',
            'A급',
            'B급',
            'C급 이하',
        ],

        list: [],

        log_list: [],

        apply_list: [],

        title: "",
        content: "",
        image: "",
        is_open: 0,
        is_cert: 0,
        writer: "",
        writer_nickname: "",
        apply_count: 0,
        card_amount:0,
        created: null,

        loading: false,

        is_challenging: false,

        keyword: "",

        list_type: "card",

        view_type: "카드 선택",
        export_list: "",

        progress: 0,

        state2: 1,

        // 순서
        sequence: "챌린지북",
        align_challenge: false,
        align_name: false,
        align_amount: false,
        align_state: false,

        selected_item: {},

        dialog: {
            card_manage: false,
            memo: false,
            update: false,
            list: false,
        },

        state: "",

        first_list: [],

        user_id: "",
        componentKey: 0
    }),

    watch: {
        $route(){
            if(this.$route.query.user_id){
                this.user_id = this.$route.query.user_id
                this.view_type = "카드 선택"
                this.load()
                this.search()
            }else{
                this.user_id = this.$store.state.user_id
                this.view_type = "카드 선택"
                this.load()
                this.search()
            }
        },

        // 순서
        sequence(){
            this.sortList()
        },

        // 순서
        align_challenge(){
            // this.sortList()
            this.list.reverse()
        },

        // 순서
        align_name(){
            this.sortList()
        },

        // 순서
        align_amount(){
            this.sortList()
        },

        // 순서
        align_state(){
            this.sortList()
        }
    },

    mounted(){
        if(this.$route.query.user_id){
            this.user_id = this.$route.query.user_id
            this.load()
            this.search()
        }else{
            this.user_id = this.$store.state.user_id
            this.load()
            this.search()
        }

        this.loadLogList()
    },

    methods: {
        state2_increase(){
            if(this.state2 != 10){
                this.state2 += 0.5
            }
        },

        state2_decrease(){
            if(this.state2 != 1){
                this.state2 -= 0.5
            }
        },

        loadLogList(){
            this.$http.post("/api/challenge/select/log", {
                params: {
                    challenge_id: this.$route.query.id
                }
            }).then((res) => {
                this.log_list = res.data
            })
        },

        // 도전여부 변경
        updateChallenging(){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }
            
            if(this.is_challenging){
                this.$http.post("/api/challenge/delete/apply", {
                    params: {
                        challenge_id: this.$route.query.id,
                        user_id: this.$store.state.user_id
                    }
                }).then(res => {
                    if(res.data.affectedRows){
                        this.load()
                    }
                })
            }
            else{
                this.$http.post('/api/challenge/insert/apply', {
                    params: {
                        challenge_id: this.$route.query.id,
                        user_id: this.$store.state.user_id
                    }
                }).then(res => {
                    if(res.data.affectedRows){
                        this.load()
                    }
                })
            }
        },

        // 검색
        search(){
            this.loading = true
            this.$http.post("/api/challenge/select/card/search", {
                params: {
                    challenge_id: this.$route.query.id,
                    user_id: this.user_id,
                    keyword: this.keyword
                }
            }).then((res) => {
                // console.log("list", res.data)
                if(res.data.length){
                    this.list = res.data
                    this.first_list = JSON.parse(JSON.stringify(this.list))
                    this.sortList()
                }else{
                    this.list = []
                }

                if(this.keyword != ""){
                    this.list = this.list.filter(e => {
                        if (e.challenge_nation === 'kr') {
                            return e.name_kr.includes(this.keyword)
                        } else if (e.challenge_nation === 'jp') {
                            return e.name_jp.includes(this.keyword)
                        } else if (e.challenge_nation === 'en') {
                            return e.name_en.includes(this.keyword)
                        }
                    })
                }

                // 내 진행률
                this.progress = 0
                res.data.forEach(e => {
                    if(e.challenge_nation == "kr" && e.amount_kr != 0){
                        this.progress += 1
                    } else if(e.challenge_nation == "jp" && e.amount_jp != 0){
                        this.progress += 1
                    } else if(e.challenge_nation == "en" && e.amount_en != 0){
                        this.progress += 1
                    }
                })

                // 목록 출력
                this.export_list = res.data.map(e => {
                    let card_state = e.challenge_nation == "kr" ? e.card_state_kr : e.challenge_nation == "jp" ? e.card_state_jp : e.card_state_en;
                    let card_state_str = card_state != "없음" ? " " + card_state : "";
                    return e.challenge_nation == "kr" ? e.amount_kr + "장 " + e.set_kr + " " + e.name_kr + card_state_str : e.challenge_nation == "jp" ? e.amount_jp + "장 " + e.set_jp + " " + e.name_jp + card_state_str : e.amount_en + "장 " + e.set_en + " " + e.name_en + card_state_str;
                })

                this.export_list = this.export_list.join('\n')
                this.loading = false
            })
        },

        // 이미지
        view_image(item){
            if(item.challenge_nation == "kr"){
                if(item.image_kr != "default.png"){
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_jp != "default.png"){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else if(item.image_en != "default.png"){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else{
                    return "@/assets/default.png"
                }
            }
            else if(item.challenge_nation == "jp"){
                if(item.image_jp != "default.png"){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else if(item.image_kr != "default.png"){
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_en != "default.png"){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else{
                    return "@/assets/default.png"
                }
            }
            else if(item.challenge_nation == "en"){
                if(item.image_en != "default.png"){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_kr != "default.png"){
                    return '/upload/card_kr_image/'+encodeURI(item.image_kr)
                }
                else if(item.image_jp != "default.png"){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else{
                    return "@/assets/default.png"
                }
            }
        },

        // 순서
        sortList(){
            if(this.sequence == "챌린지북"){
                this.list = this.first_list
            }
            if(this.sequence == "이름"){
                this.list.sort((a, b) => {
                    let nameA, nameB

                    if (a.challenge_nation === 'kr') {
                        nameA = a.name_kr
                    } else if (a.challenge_nation === 'en') {
                        nameA = a.name_en
                    } else if (a.challenge_nation === 'jp') {
                        nameA = a.name_jp
                    }

                    if (b.challenge_nation === 'kr') {
                        nameB = b.name_kr
                    } else if (b.challenge_nation === 'en') {
                        nameB = b.name_en
                    } else if (b.challenge_nation === 'jp') {
                        nameB = b.name_jp
                    }

                    return nameA.localeCompare(nameB)
                })

                if(this.align_name){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "보유수량"){
                this.list.sort((a, b) => {
                    let amountA, amountB

                    if (a.challenge_nation === 'kr') {
                        amountA = a.amount_kr.toString()
                    } else if (a.challenge_nation === 'en') {
                        amountA = a.amount_en.toString()
                    } else if (a.challenge_nation === 'jp') {
                        amountA = a.amount_jp.toString()
                    }

                    if (b.challenge_nation === 'kr') {
                        amountB = b.amount_kr.toString()
                    } else if (b.challenge_nation === 'en') {
                        amountB = b.amount_en.toString()
                    } else if (b.challenge_nation === 'jp') {
                        amountB = b.amount_jp.toString()
                    }

                    return amountA.localeCompare(amountB)
                })

                if(!this.align_amount){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "등급"){
                this.list.sort((a, b) => {
                    let card_stateA, card_stateB

                    if (a.challenge_nation === 'kr') {
                        card_stateA = this.state_list.indexOf(a.card_state_kr)
                    } else if (a.challenge_nation === 'en') {
                        card_stateA = this.state_list.indexOf(a.card_state_en)
                    } else if (a.challenge_nation === 'jp') {
                        card_stateA = this.state_list.indexOf(a.card_state_jp)
                    }

                    if (b.challenge_nation === 'kr') {
                        card_stateB = this.state_list.indexOf(b.card_state_kr)
                    } else if (b.challenge_nation === 'en') {
                        card_stateB = this.state_list.indexOf(b.card_state_en)
                    } else if (b.challenge_nation === 'jp') {
                        card_stateB = this.state_list.indexOf(b.card_state_jp)
                    }

                    // return card_stateA - card_stateB

                    // '없음'이 맨 뒤로 가도록 비교
                    if (card_stateA === -1) {
                        return 1;
                    } else if (card_stateB === -1) {
                        return -1;
                    } else {
                        return card_stateA - card_stateB;
                    }
                })

                if (this.align_state) {
                    this.list = this.list.reverse()
                }

                console.log("after", this.list.map(e => e.card_state_kr))
            }
        },

        // 수량 토글
        async item_toggle(item){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            if(this.$store.state.user_id != this.user_id){
                alert("다른 회원의 수량은 조절할 수 없습니다")
                return
            }

            if(item.challenge_nation == "en")
            {
                if(item.amount_en == 0){
                    item.amount_en = 1
                }else{
                    item.amount_en = 0
                }
            }
            else if(item.challenge_nation == "kr")
            {
                if(item.amount_kr == 0){
                    item.amount_kr = 1
                }else{
                    item.amount_kr = 0
                }
            }
            else if(item.challenge_nation == "jp")
            {
                if(item.amount_jp == 0){
                    item.amount_jp = 1
                }else{
                    item.amount_jp = 0
                }
            }

            await this.save_card(item)
            this.search()
        },

        // 수량 증가
        async item_increase(item){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            if(this.$store.state.user_id != this.user_id){
                alert("다른 회원의 수량은 조절할 수 없습니다")
                return
            }

            if(item.challenge_nation == "en")
            {
                if(item.amount_en == 0){
                    item.amount_en++
                    await this.save_card(item)
                    this.search()
                }
                else if(item.amount_en > 0){
                    item.amount_en++
                    await this.save_card(item)
                }
            }
            else if(item.challenge_nation == "kr")
            {
                if(item.amount_kr == 0){
                    item.amount_kr++
                    await this.save_card(item)
                    this.search()
                }
                else if(item.amount_kr > 0){
                    item.amount_kr++
                    await this.save_card(item)
                }
            }
            else if(item.challenge_nation == "jp")
            {
                if(item.amount_jp == 0){
                    item.amount_jp++
                    await this.save_card(item)
                    this.search()
                }
                else if(item.amount_jp > 0){
                    item.amount_jp++
                    await this.save_card(item)
                }
            }
        },

        // 수량 감소
        async item_decrease(item){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            if(this.$store.state.user_id != this.user_id){
                alert("다른 회원의 수량은 조절할 수 없습니다")
                return
            }

            if(item.challenge_nation == "en")
            {
                if(item.amount_en == 1){
                    item.amount_en--
                    await this.save_card(item)
                    this.search()
                }
                else if(item.amount_en > 1){
                    item.amount_en--
                    await this.save_card(item)
                }
            }
            else if(item.challenge_nation == "kr")
            {
                if(item.amount_kr == 1){
                    item.amount_kr--
                    await this.save_card(item)
                    this.search()
                }
                else if(item.amount_kr > 1){
                    item.amount_kr--
                    await this.save_card(item)
                }
            }
            else if(item.challenge_nation == "jp")
            {
                if(item.amount_jp == 1){
                    item.amount_jp--
                    await this.save_card(item)
                    this.search()
                }
                else if(item.amount_jp > 1){
                    item.amount_jp--
                    await this.save_card(item)
                }
            }
        },

        // 카드 1장 저장 (수량증가시)
        async save_card(item){
            if(item.challenge_nation == "kr")
            {
                await this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: item.set_kr,
                        card_nation: item.challenge_nation,
                        card_id: item.id,
                        card_state: item.card_state_kr,
                        card_number: item.number_kr,
                        amount: item.amount_kr,
                        memo: item.memo_kr
                    }
                }).then(async(res) => {
                    console.log(res)
                    // this.search()
                    await this.load()
                })
            }
            else if(item.challenge_nation == "jp")
            {
                await this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: item.set_jp,
                        card_nation: item.challenge_nation,
                        card_id: item.id,
                        card_state: item.card_state_jp,
                        card_number: item.number_jp,
                        amount: item.amount_jp,
                        memo: item.memo_jp
                    }
                }).then(async(res) => {
                    console.log(res)
                    // this.search()
                    await this.load()
                })
            }
            else if(item.challenge_nation == "en")
            {
                await this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: item.set_en,
                        card_nation: item.challenge_nation,
                        card_id: item.id,
                        card_state: item.card_state_en,
                        card_number: item.number_en,
                        amount: item.amount_en,
                        memo: item.memo_en
                    }
                }).then(async(res) => {
                    console.log(res)
                    // this.search()
                    await this.load()
                })
            }
        },

        // 카드 상태 표시
        view_state(item){
            if(item.challenge_nation == "kr"){
                if(item.card_state_kr.includes("PSA")){
                    if(item.card_state_kr.includes("이하")){
                        return item.card_state_kr.slice(0, -2) + " 이하"
                    }
                    else{
                        return item.card_state_kr.slice(0, -2)
                    }
                }
                else if(item.card_state_kr.includes("기타")){
                    return item.card_state_kr.slice(0, -2).replace("기타", "등급")
                }
                else{
                    return item.card_state_kr
                }
            }
            else if(item.challenge_nation == "en"){
                if(item.card_state_en.includes("PSA")){
                    if(item.card_state_en.includes("이하")){
                        return item.card_state_en.slice(0, -2) + " 이하"
                    }
                    else{
                        return item.card_state_en.slice(0, -2)
                    }
                }
                else if(item.card_state_en.includes("기타")){
                    return item.card_state_en.slice(0, -2).replace("기타", "등급")
                }
                else{
                    return item.card_state_en
                }
            }
            else if(item.challenge_nation == "jp"){
                if(item.card_state_jp.includes("PSA")){
                    if(item.card_state_jp.includes("이하")){
                        return item.card_state_jp.slice(0, -2) + " 이하"
                    }
                    else{
                        return item.card_state_jp.slice(0, -2)
                    }
                }
                else if(item.card_state_jp.includes("기타")){
                    return item.card_state_jp.slice(0, -2).replace("기타", "등급")
                }
                else{
                    return item.card_state_jp
                }
            }
        },

        // 카드메모 > 열기
        item_memo(item){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            if(this.$store.state.user_id != this.user_id){
                alert("다른 회원의 챌린지북입니다")
                return
            }

            this.selected_item.challenge_nation = item.challenge_nation

            this.selected_item.card_state_kr = item.card_state_kr
            this.selected_item.card_state_en = item.card_state_en
            this.selected_item.card_state_jp = item.card_state_jp

            this.selected_item.set_kr = item.set_kr
            this.selected_item.set_en = item.set_en
            this.selected_item.set_jp = item.set_jp

            this.selected_item.amount_kr = item.amount_kr
            this.selected_item.amount_en = item.amount_en
            this.selected_item.amount_jp = item.amount_jp

            this.selected_item.id = item.id

            this.selected_item.memo_kr = item.memo_kr
            this.selected_item.memo_en = item.memo_en
            this.selected_item.memo_jp = item.memo_jp

            this.selected_item.name_kr = item.name_kr
            this.selected_item.name_en = item.name_en
            this.selected_item.name_jp = item.name_jp

            this.selected_item.rarity_kr = item.rarity_kr
            this.selected_item.rarity_en = item.rarity_en
            this.selected_item.rarity_jp = item.rarity_jp

            this.selected_item.number_kr = item.number_kr
            this.selected_item.number_en = item.number_en
            this.selected_item.number_jp = item.number_jp

            this.selected_item.printedTotal_kr = item.printedTotal_kr
            this.selected_item.printedTotal_en = item.printedTotal_en
            this.selected_item.printedTotal_jp = item.printedTotal_jp

            this.dialog.memo = true
        },

        // 카드메모 > 저장
        save_memo(){
            console.log(this.selected_item)
            if(this.selected_item.challenge_nation == "kr")
            {
                this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.selected_item.set_kr,
                        card_nation: this.selected_item.challenge_nation,
                        card_id: this.selected_item.id,
                        card_state: this.selected_item.card_state_kr!='직접 입력'? this.selected_item.card_state_kr : "기타 " + this.state2 + "등급",
                        card_number: this.selected_item.number_kr,
                        amount: this.selected_item.amount_kr,
                        memo: this.selected_item.memo_kr
                    }
                }).then((res) => {
                    // console.log(res)
                    this.dialog.memo = false
                    this.search()
                })
            }
            else if(this.selected_item.challenge_nation == "jp")
            {
                this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.selected_item.set_jp,
                        card_nation: this.selected_item.challenge_nation,
                        card_id: this.selected_item.id,
                        card_state: this.selected_item.card_state_jp!='직접 입력'? this.selected_item.card_state_jp : "기타 " + this.state2 + "등급",
                        card_number: this.selected_item.number_jp,
                        amount: this.selected_item.amount_jp,
                        memo: this.selected_item.memo_jp
                    }
                }).then((res) => {
                    // console.log(res)
                    this.dialog.memo = false
                    this.search()
                })
            }
            else if(this.selected_item.challenge_nation == "en")
            {
                this.$http.post("/api/new/collection/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        set_name: this.selected_item.set_en,
                        card_nation: this.selected_item.challenge_nation,
                        card_id: this.selected_item.id,
                        card_state: this.selected_item.card_state_en!='직접 입력'? this.selected_item.card_state_en : "기타 " + this.state2 + "등급",
                        card_number: this.selected_item.number_en,
                        amount: this.selected_item.amount_en,
                        memo: this.selected_item.memo_en
                    }
                }).then((res) => {
                    // console.log(res)
                    this.dialog.memo = false
                    this.search()
                })
            }
        },

        // 불러오기
        async load(){
            // 기본 정보 불러오기
            await this.$http.post("/api/challenge/select/specific", {
                params: {
                    id: this.$route.query.id,
                    user_id: this.user_id
                }
            }).then(res => {
                this.title = res.data[0].title
                this.image = res.data[0].image
                this.is_open = res.data[0].is_open
                this.is_cert = res.data[0].is_cert
                this.content = res.data[0].content
                this.writer = res.data[0].writer
                this.writer_nickname = res.data[0].writer_nickname
                this.apply_count = res.data[0].apply_count
                this.created = res.data[0].created
                this.is_challenging = res.data[0].is_challenging
            })

            // 카드 목록 불러오기
            await this.$http.post('/api/challenge/select/card', {
                params: {
                    challenge_id : this.$route.query.id
                }
            }).then(res => {
                if(res.data.length){
                    this.card_amount = res.data.length
                }
            })

            // 도전자 목록 불러오기
            await this.$http.post('/api/challenge/select/apply', {
                params: {
                    challenge_id : this.$route.query.id
                }
            }).then(res => {
                if(res.data.length){
                    this.apply_list = res.data.sort((a, b) => b.count - a.count)
                }
            })
        },

        // 인증하기
        updateCert(){
            if(this.$store.state.type == "관리자"){
                this.$http.post("/api/challenge/update/apply/is_cert", {
                    params: {
                        challenge_id: this.$route.query.id,
                        user_id: this.user_id,
                        is_cert: !this.is_cert
                    }
                }).then((res) => {
                    this.load()
                })
            }
        },

        // 캡처
        capture(){
            const captureElement = document.getElementById('capture')

            // html2canvas 실행 전에 test 클래스를 가진 요소의 display 속성을 변경
            const testElements = document.getElementsByClassName('no_amount');
            for (let i = 0; i < testElements.length; i++) {
                testElements[i].style.display = 'block';
            }

            html2canvas(
                captureElement,
                {
                    scale: 2,
                    useCORS: true,
                }
            )
            .then(canvas => {
                // const dataUrl = canvas.toDataURL('image/png')
                const dataUrl = canvas.toDataURL('image/jpeg', 0.5)
                const img = document.createElement('img')
                img.src = dataUrl
                document.body.appendChild(img)
                const a = document.createElement('a')
                a.href = dataUrl
                a.download = this.title
                a.click()

                // 이미지를 다운로드한 후에는 document에서 해당 이미지를 제거
                document.body.removeChild(img)

                // html2canvas 실행 후에 test 클래스를 가진 요소의 display 속성을 다시 변경
                for (let i = 0; i < testElements.length; i++) {
                    testElements[i].style.display = 'none';
                }
            })
        },

        // 챌린지북 복사하기
        copyCollectionBook(){
            if(confirm("이 챌린지북을 복사하시겠습니까?")){
                this.$http.post("/api/challenge/insert", {
                    params: {
                        title: this.title + " 복사본",
                        writer: this.$store.state.user_id,
                        image: this.image,
                        content: this.content,
                        is_open: this.is_open
                    }
                }).then(async(res) => {
                    this.$http.post("/api/challenge/insert/card/copy", {
                        params: {
                            challenge_id: this.$route.query.id,
                            new_id: res.data.insertId
                        }
                    })

                    this.$http.post("/api/challenge/insert/log", {
                        params: {
                            challenge_id: res.data.insertId,
                            user_id: this.$store.state.user_id,
                            type: "create",
                            message: this.$store.state.nickname+"님이 "+ this.title +" 챌린지북을 복사하였습니다",

                        }
                    })

                    this.$router.push("/collection/challengebook/read?id="+res.data.insertId)
                })
            }
        },

        copySubmit(){
            this.$copyText(this.export_list)
            alert("카드목록이 복사되었습니다")
        },

        calculateProbability(count, totalNumber) {
            // console.log(count)
            // console.log(totalNumber)
            // count와 totalNumber가 숫자가 아니거나 totalNumber가 0인 경우 확률을 계산할 수 없음
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0; // 확률을 계산할 수 없으므로 0%로 표시
            }

            const probability = (count / totalNumber) * 100; // 확률 계산 (백분율로 표시)
            return parseInt(probability); // 소수점 두 자리까지 표시
        },

        openDetail(item){
            window.open('/card/detail?id='+item.id, '_blank')
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 4px #f0f0f0 !important;
}
</style>
<style scoped>
::v-deep input {
    font-weight:bold;
    margin-left:8px;
}
</style>
<style scoped>
.no_amount{
    display:none;
}

.yes_amount{
    display:none;
}

/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}

::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

::v-deep .mobile .v-text-field input {
    font-size: 10px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 4px #f0f0f0 !important;
}
</style>