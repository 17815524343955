<template>
    <div>
        <!-- 상단 1단 (게시글명) -->
        <v-sheet
            color="transparent"
            class="d-flex align-center rounded-10 py-4 pl-4"
        >
            <!-- 제목 -->
            <div>
                <!-- 기본 -->
                <div v-if="$route.query.board_name != '자주 묻는 질문 게시판'">
                    <p
                        class="mb-0 font-weight-bold"
                        style="letter-spacing:-0.5px; font-size:26px; cursor:pointer;"
                        @click="loadFirst()"
                    >
                        {{$route.query.board_group}}
                        <v-icon class="mb-1 mx-0" color="#424361">mdi-chevron-right</v-icon>
                        {{$route.query.board_name}}
                    </p>
                    <p style="font-size:11px; margin-top:-4px; margin-bottom:0">
                        공지사항과 이용약관을 준수하여 활동 부탁드립니다
                    </p>
                </div>

                <!-- 자주 묻는 질문 게시판 (표시 대신 Dummy) -->
                <div
                    v-if="$route.query.board_name == '자주 묻는 질문 게시판'"
                    style="height:80px; margin-top:3px;"
                ></div>
            </div>

            <v-spacer></v-spacer>

            <BannerView
                v-if="$store.state.ads_banner.community"
                type="community"
                width="360"
                height="46"
                :round="'rounded-10'"
            />
        </v-sheet>

        <!-- 상단 2단 (버튼) -->
        <v-sheet
            class="d-flex align-center rounded-10 mb-2 py-4 px-5"
        >
            <!-- 검색 유형 선택 -->
            <v-sheet width="190" class="rounded-10 mr-1 mobile">
                <v-select
                    style="height:36px; width:190px;"
                    class="rounded-10"
                    dense
                    outlined
                    hide-details
                    :items="select_list.category"
                    v-model="category"
                    @change="category_selected"
                    placeholder="카테고리"
                >
                    <template v-slot:append>
                        <v-icon
                            class="mb-2"
                            color="#424361"
                            size="20"
                        >
                            mdi-chevron-down
                        </v-icon>
                    </template>
                </v-select>
            </v-sheet>


            <!-- 전체글 -->
            <v-btn
                width="80"
                height="36"
                class="ml-2 px-6 rounded-10"
                color="primary"
                dark
                depressed
                @click="load_all()"
            >
                전체글
            </v-btn>

            <!-- 인기글 -->
            <v-btn
                width="80"
                height="36"
                class="ml-2 px-6 rounded-10"
                color="#615DFA"
                dark
                depressed
                @click="hot()"
            >
                인기글
            </v-btn>

            <!-- 내글 -->
            <v-btn
                width="80"
                height="36"
                class="ml-2 px-6 rounded-10"
                color="#ADAFCA"
                dark
                depressed
                @click="myPost()"
            >
                내글
            </v-btn>

            <!-- 내댓글 -->
            <v-btn
                width="80"
                height="36"
                class="ml-2 px-6 rounded-10"
                color="#ADAFCA"
                dark
                depressed
                @click="myComment()"
            >
                내댓글
            </v-btn>

            <v-spacer></v-spacer>

            <!-- 썸네일 스위치 -->
            <v-switch
                v-if="$route.query.board_name != '유튜브 게시판'"
                class="font-weight-bold mt-0"
                v-model="thumbnail_switch"
                inset
                :ripple="false"
                hide-details
                :label="thumbnail_switch? '썸네일 ON':'썸네일 OFF'"
            ></v-switch>
        </v-sheet>

        <!-- 게시글 목록 -->
        <v-sheet
            class="mt-2 rounded-10 pa-4 pt-2"
        >
            <!-- 목록 & 페이징 -->
            <v-sheet class="mt-2">
                <!-- 로딩 -->
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary"
                    class="d-flex mx-auto my-10"
                    size="80"
                ></v-progress-circular>

                <!-- 기본 게시판 -->
                <v-data-table
                    v-if="!loading && page && $route.query.board_name != '유튜브 게시판'"
                    :items="list"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount = $event"
                    hide-default-footer
                    no-data-text="게시글이 존재하지 않습니다."
                >
                    <template v-slot:item="{ item }">
                        <!-- 공지 -->
                        <tr 
                            v-if="item.type == '공지'"
                            class="pa-0 ma-0" 
                            style="cursor:pointer;" 
                            @click="read(item)"
                        >
                            <td
                                class="py-0 px-3"
                                colspan="2"
                                style="height:40px;"
                            >
                                <!-- 공지 & 제목 -->
                                <div
                                    class="d-flex align-center text-truncate"
                                >
                                    <!-- 공지 -->
                                    <v-sheet
                                        outlined
                                        class="rounded-15 d-flex align-center justify-center mr-1"
                                        style="border:1px solid #615DFA; color:#615DFA;"
                                        width="40"
                                    >
                                        공지
                                    </v-sheet>

                                    <!-- 제목 -->
                                    <span>{{item.title}}</span>
                                </div>
                            </td>
                        </tr>

                        <!-- 일반글 -->
                        <tr
                            v-if="item.type != '공지'"
                            class="pa-0 ma-0" 
                            style="cursor:pointer;" 
                            @click="read(item)"
                        >
                            <td class="py-1">
                                <v-sheet
                                    class="d-flex"
                                    color="transparent"
                                >
                                    <v-sheet color="transparent">
                                        <v-sheet
                                            :width="thumbnail_switch? 600:710"
                                            color="transparent"
                                            class="text-truncate d-flex"
                                        >
                                            <v-sheet color="transparent" class="text-truncate" v-if="item.status=='공개'">
                                                <font v-if="item.category" class="mr-1 cyan--text">{{item.category}}</font>
                                                <font>{{item.title}}</font>
                                            </v-sheet>
                                            <v-icon v-if="isNewItem(item) && item.status=='공개'" color="#F8468D" style="margin-left:3px; margin-top:1px; width:14px;" small>mdi-alpha-n-circle</v-icon>
                                            <v-sheet color="transparent" v-if="item.status=='블라인드됨'">
                                                <font color="grey">해당 게시글은 운영진에 의해 블라인드 처리된 글입니다.</font>
                                            </v-sheet>
                                        </v-sheet>
                                        <v-sheet class="d-flex mt-1" color="transparent">
                                            <Profile v-if="item.user_id" :user="item.user_id" :color="'grey'"/>
                                            <font class="ml-2 grey--text">
                                                {{ dayjs(item.created).format(dayjs().isSame(item.created, 'day') ? 'a hh:mm' : 'YYYY. MM. DD').replace('am', '오전').replace('pm', '오후') }}
                                            </font>
                                            <font class="ml-2 grey--text"><v-icon small color="grey" class="mr-1 pb-2px">mdi-eye-outline</v-icon>{{item.view_count}}</font>
                                            <font class="ml-2">
                                                <v-icon small :color="item.like_count>=5? '#615df7':'grey'" class="pb-2px">mdi-heart-outline</v-icon>
                                                <span :style="item.like_count>=5? 'color:#615df7':'color:#9E9E9E'">
                                                    {{item.like_count}}
                                                </span>
                                            </font>
                                            <v-icon
                                                v-if="item.image!='HI' && !thumbnail_switch"
                                                size="16"
                                                color="grey"
                                                class="ml-2 mr-2px pt-1px"
                                            >
                                                mdi-image-outline
                                            </v-icon>
                                        </v-sheet>
                                    </v-sheet>
                                    <v-spacer></v-spacer>
                                    <v-img
                                        v-if="thumbnail_switch"
                                        height="50"
                                        width="50"
                                        :src="item.image=='HI'? '':item.image"
                                    />
                                    <v-spacer></v-spacer>
                                    <v-sheet class="mx-1" color="transparent">
                                        <p class="ma-0 mt-1 text-center"><v-icon color="grey">mdi-comment-processing-outline</v-icon></p>
                                        <p class="ma-0 text-center grey--text">{{item.comment_count + item.sub_comment_count}}</p>
                                    </v-sheet>
                                </v-sheet>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <!-- 유튜브 게시판 -->
                <v-row v-if="!loading && $route.query.board_name == '유튜브 게시판'">
                    <v-col
                        v-for="(item, index) in currentPageList" :key="index"
                        :cols="item.type=='공지'? 12:4"
                        class="py-1"
                        style="cursor:pointer;"
                        @click="read(item)"
                    >

                        <!-- 공지 -->
                        <table>
                            <tr 
                                v-if="item.type == '공지'"
                                class="pa-0 ma-0" 
                                style="cursor:pointer;" 
                                @click="read(item)"
                            >
                                <v-sheet
                                    colspan="2"
                                >
                                    <!-- 공지 & 제목 -->
                                    <div
                                        class="d-flex align-center text-truncate mb-2px"
                                    >
                                        <!-- 공지 -->
                                        <v-sheet
                                            outlined
                                            class="rounded-15 d-flex align-center justify-center mr-1"
                                            style="border:1px solid #615DFA; color:#615DFA; font-size:14px;"
                                            width="40"
                                        >
                                            공지
                                        </v-sheet>

                                        <!-- 제목 -->
                                        <span>{{item.title}}</span>
                                    </div>
                                </v-sheet>
                            </tr>
                        </table>


                        <!-- 일반글 -->

                        <!-- 썸네일 -->
                        <v-img
                            v-if="item.type != '공지'"
                            class="rounded-10"
                            height="140"
                            :src="item.image=='HI'? '':item.image"
                        />

                        <!-- 게시글 정보 -->
                        <v-sheet v-if="item.type != '공지'" class="d-flex" color="transparent">
                            <v-sheet color="transparent" class="text-truncate">
                                <v-sheet color="transparent" class="text-truncate pa-1">
                                    <v-sheet class="text-truncate" color="transparent" v-if="item.status=='공개'">
                                        <font v-if="item.category" class="mr-1 cyan--text">{{item.category}}</font>
                                        <font :class="item.type=='공지'? 'font-weight-bold deep-orange--text':''">{{item.title}}</font>
                                    </v-sheet>
                                    <v-sheet color="transparent" v-if="item.status=='블라인드됨'">
                                        <font color="grey">해당 게시글은 운영진에 의해 블라인드 처리된 글입니다.</font>
                                    </v-sheet>
                                </v-sheet>
                                <v-sheet class="d-flex align-center mx-1" color="transparent">
                                    <!-- 프로필 -->
                                    <Profile v-if="item.user_id" :user="item.user_id" :color="'grey'"/>
                                    <v-spacer></v-spacer>
                                    <font class="ml-2 grey--text" style="font-size:10px;">
                                        {{ dayjs(item.created).format(dayjs().isSame(item.created, 'day') ? 'a hh:mm' : 'YYYY. MM. DD').replace('am', '오전').replace('pm', '오후') }}
                                    </font>
                                    <font class="ml-2 grey--text" style="font-size:10px;"><v-icon x-small color="grey" class="mr-1 pb-2px">mdi-eye-outline</v-icon>{{item.view_count}}</font>
                                    <font class="ml-2 grey--text" style="font-size:10px;"><v-icon x-small color="grey" class="mr-1 pb-2px">mdi-heart-outline</v-icon>{{item.like_count}}</font>
                                    <font class="ml-2 grey--text" style="font-size:10px;"><v-icon x-small color="grey" class="mr-1 pb-2px">mdi-comment-processing-outline</v-icon>{{item.comment_count + item.sub_comment_count}}</font>
                                </v-sheet>
                            </v-sheet>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 하단 1단 (버튼 & 페이징) -->
                <v-sheet
                    class="d-flex align-center mt-4"
                >
                    <div
                        style="width:120px"
                    ></div>

                    <v-spacer></v-spacer>

                    <!-- 페이징 -->
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        :total-visible="7"
                    ></v-pagination>
                    <v-spacer></v-spacer>

                    <div
                        style="width:120px"
                        class="d-flex justify-end"
                    >
                        <!-- 글쓰기 -->
                        <v-btn
                            height="35"
                            class="mr-2 px-6 rounded-10"
                            color="#23D2E2"
                            dark
                            depressed
                            @click="write()"
                        >
                            글쓰기
                        </v-btn>
                    </div>
                </v-sheet>

                <!-- 하단 2단 (검색) -->
                <div
                    class="d-flex align-center justify-center mt-4"
                >
                    <!-- 검색 유형 선택 -->
                        <v-select
                            style="width:92px;"
                            height="40"
                            class="shrink rounded-10 font-weight-bold mr-1"
                            dense
                            outlined
                            hide-details
                            :items="select_list.search_type"
                            item-text="name"
                            item-value="value"
                            v-model="search_type"
                        >
                            <template v-slot:selection="{ item }">
                                <span
                                    class="pl-1"
                                    style="font-size:14px;"
                                    color="#424361"
                                >
                                    {{item}}
                                </span>
                            </template>
                            <template v-slot:append>
                                <v-icon
                                    color="#424361"
                                    style="width:12px;"
                                >
                                    mdi-chevron-down
                                </v-icon>
                            </template>
                        </v-select>

                    <!-- 검색 입력란 -->
                    <v-sheet width="180" class="rounded-10 mr-1">
                        <v-text-field
                            height="40"
                            class="rounded-10 font-weight-bold"
                            style="font-size:14px;"
                            hide-details
                            outlined
                            dense
                            placeholder="검색어"
                            color="primary"
                            v-model="keyword"
                            @keyup.enter="search()"
                        ></v-text-field>
                    </v-sheet>
                    <v-btn
                        color="primary"
                        dark
                        depressed
                        class="rounded-10 font-weight-bold"
                        style="font-size:15px;"
                        width="80"
                        height="40"
                        @click="search()"
                    >
                        검색
                    </v-btn>
                </div>

                <BannerView
                    v-if="$store.state.ads_banner.community"
                    class="mt-3 mx-auto"
                    type="community"
                    width="360"
                    height="46"
                    :round="'rounded-10'"
                />
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Profile from "@/components/Profile"
import BannerView from '@/components/BannerView'

export default {
    components: {
        Profile,
        BannerView
    },

    data: () => ({
        // 검색
        select_list: {
            search_type: ["전체", "글쓴이", "제목", "내용"],
            category: []
        },
        search_type: "전체",
        keyword: "",

        category: "",
        
        // 목록
        list: [],

        // 페이징
        page: 1,
        pageCount: 0,
        itemsPerPage: 17,

        // 유튜브 게시판
        currentPageList: [],

        // 인기글
        isHot: false,

        loading: false,

        // 썸네일
        thumbnail_switch: true
    }),

    mounted() {
        if(this.$route.query.category){
            this.category = this.$route.query.category
        }

        // 첫 목록 불러오기
        this.load()

        // 카테고리 목록 불러오기
        this.loadCategory()
    },

    watch: {
        page(){
            this.$router.replace({ query: { ...this.$route.query, page: this.page }})
            this.currentPageList = this.list.slice((this.page-1)*17,(this.page)*17)
        }
    },

    methods: {
        isNewItem(item) {
            return new Date() - new Date(item.created) < 24 * 60 * 60 * 1000
        },

        loadFirst(){
            this.$router.push('/board/list?board_group='+this.$route.query.board_group+'&board_name='+this.$route.query.board_name)
        },
        
        // 카테고리가 선택됨
        category_selected(item){
            this.$router.push('/board/list?board_group='+this.$route.query.board_group+'&board_name='+this.$route.query.board_name+'&category='+item)
        },

        // 카테고리 목록 불러오기
        loadCategory(){
            this.$http.post('/api/board/list/category', {
                params: {
                    board_name : this.$route.query.board_name
                }
            }).then(res => {
                this.select_list.category = res.data.map(e => e.title)
            })
        },

        // 첫 목록
        load(){
            this.loading = true
            this.isHot = false
            if(!this.$route.query.category){
                this.$http.post('/api/board/list', {
                    params: {
                        board_name: this.$route.query.board_name
                    }
                }).then((res) => {
                    // 주소 page 불러오기
                    if(this.$route.query.page){
                        this.page = parseInt(this.$route.query.page)
                    }

                    this.list = res.data

                    // 유튜브게시판 용
                    this.currentPageList = res.data.slice(0,17)
                    this.pageCount = Math.ceil(res.data.length/17)

                    // 썸네일 추가
                    this.matchThumbnail()

                    this.loading = false
                })
            }else {
                this.$http.post('/api/board/list/category/search', {
                    params: {
                        board_name: this.$route.query.board_name,
                        category: this.$route.query.category
                    }
                }).then((res) => {
                    // 주소 page 불러오기
                    if(this.$route.query.page){
                        this.page = parseInt(this.$route.query.page)
                    }
                    
                    this.list = res.data

                    // 유튜브게시판 용
                    this.currentPageList = res.data.slice(0,17)
                    this.pageCount = Math.ceil(res.data.length/17)

                    // 썸네일 추가
                    this.matchThumbnail()

                    this.loading = false
                })
            }
        },

        // 전체글
        load_all(){
            this.isHot = false
            this.loading = true
            this.search_type = "전체"
            this.keyword = ""
            this.category = ""

            this.$http.post('/api/board/list', {
                params: {
                    board_name: this.$route.query.board_name
                }
            }).then((res) => {
                // 주소 page 불러오기
                if(this.$route.query.page){
                    this.page = parseInt(this.$route.query.page)
                }

                this.list = res.data

                // 유튜브게시판 용
                this.currentPageList = res.data.slice(0,17)
                this.pageCount = Math.ceil(res.data.length/17)

                // 썸네일 추가
                this.matchThumbnail()
                this.loading = false
            })
        },

        // 인기글 목록
        hot(){
            this.loading = true
            this.$http.post('/api/board/list/hot', {
                params: {
                    board_name: this.$route.query.board_name,
                    category: this.$route.query.category
                }
            }).then((res) => {
                this.list = res.data

                // 유튜브게시판 용
                this.currentPageList = res.data.slice(0,17)
                this.pageCount = Math.ceil(res.data.length/17)

                // 썸네일 추가
                this.matchThumbnail()

                // 인기글
                this.isHot = true

                this.loading = false
            })
        },

        // 내글 목록
        async myPost(){
            this.search_type = "글쓴이"
            this.keyword = this.$store.state.nickname
            await this.search()
            this.search_type = "전체"
            this.keyword = ""
        },

        // 내댓글 목록
        myComment(){
            this.$http.post('/api/board/list/search/myComment', {
                params: {
                    board_name: this.$route.query.board_name,
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                this.list = res.data

                // 썸네일 추가
                this.matchThumbnail()
            })
        },

        // 검색
        async search(){
            this.loading = true
            if(!this.$route.query.category){
                await this.$http.post('/api/board/list/search', {
                    params: {
                        board_name: this.$route.query.board_name,
                        search_type: this.search_type,
                        keyword: this.keyword
                    }
                }).then((res) => {
                    this.list = res.data

                    // 유튜브게시판 용
                    this.currentPageList = res.data.slice(0,17)
                    this.pageCount = Math.ceil(res.data.length/17)

                    // 썸네일 추가
                    this.matchThumbnail()

                    this.loading = false
                })
            }else{
                await this.$http.post('/api/board/list/searchInCategory', {
                    params: {
                        board_name: this.$route.query.board_name,
                        category: this.$route.query.category,
                        search_type: this.search_type,
                        keyword: this.keyword
                    }
                }).then((res) => {
                    this.list = res.data

                    // 유튜브게시판 용
                    this.currentPageList = res.data.slice(0,17)
                    this.pageCount = Math.ceil(res.data.length/17)

                    // 썸네일 추가
                    this.matchThumbnail()

                    this.loading = false
                })
            }
        },

        // 썸네일 추가
        matchThumbnail(){
            if(this.$route.query.board_name == "유튜브 게시판"){
                this.list.forEach(e => {
                    const match = e.content.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
                    if (match) {
                        e.image = "https://img.youtube.com/vi/" + match.pop() + "/0.jpg"
                    }
                })
            }else{
                const imgReg = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
                const imgGalleryReg = /<imagegallery[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
                const customImgReg = /<customimage[^>]*src=["']?([^>"']+)["']?[^>]*>/i;
                const cardReg = /<card[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i
                
                this.list.forEach(e => {
                    if(e.content.match(imgReg)){
                        e.image = e.content.match(imgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if(e.content.match(customImgReg)){
                        e.image = e.content.match(customImgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if(e.content.match(imgGalleryReg)){
                        e.image = e.content.match(imgGalleryReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if(e.content.match(cardReg)){
                        e.image = e.content.match(cardReg)[0].replace(/.*src="([^"]*)".*/, '$1').replace('&amp;', '&')
                    }
                })
            }
        },

        // 게시글로 이동
        read(item){
            if(item.status=='블라인드됨' && this.$store.state.type!='관리자'){
                alert("블라인드 처리된 글은 읽을 수 없습니다.")
            }else{
                this.$router.push("/board/read?board_group="+this.$route.query.board_group+"&board_name="+this.$route.query.board_name+"&id="+item.id)
            }
        },

        // 글쓰기
        write(){
            if(!this.$store.state.is_logined){
                alert("글쓰기는 로그인 후에만 가능합니다.")
            }
            else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 밴 기간동안 글쓰기가 제한됩니다.')
            }
            else if(this.$store.state.type!='관리자' && this.$route.query.board_name=='당첨 / 구매 공지게시판'){
                alert('당첨 / 구매 공지게시판은 관리자만 글쓰기가 가능합니다.')
            }
            else{
                this.$router.push("/board/write?board_group="+this.$route.query.board_group+"&board_name="+this.$route.query.board_name)
            }
        },
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

/* 검색 keyword */
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 14px;
}

/* 검색 type */
::v-deep .v-select input {
    margin-left:4px;
    font-size: 16px;
    padding-top:12px;
}

/* 입력란 공통 (label) */
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}

/* Custom Truncate */
.custom-truncate-mobile{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* 페이징 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}


/* 모바일 카테고리 */

::v-deep .mobile .v-select {
    min-height: 33px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-select fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 33px;
    min-height: 33px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-select .v-select__selections {
    min-height: 33px !important;
    height: 33px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}


::v-deep .mobile .v-select .v-select__slot {
    font-size: 13px; /* 글꼴 크기 조절 */
    height: 33px;
}

::v-deep .mobile .v-select .v-input__append-inner,
::v-deep .mobile .v-select .v-input__prepend-inner {
    margin-top: 0;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .v-input--switch .v-label {
    font-size:14px !important;
}
</style>