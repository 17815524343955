<template>
    <div>
        <Header />

        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 1단 (검색) -->
            <v-sheet
                class="rounded-15 mb-2 pa-4 d-flex align-center"
            >
                <!-- 도전중 -->
                <v-sheet
                    class="rounded-15 d-flex align-center px-4"
                    outlined
                    height="50"
                    style="cursor:pointer;"
                    @click="keyword.is_challenging = !keyword.is_challenging; search(true)"
                >
                    <v-icon
                        color="primary"
                    >
                        mdi-heart
                    </v-icon>

                    <p
                        class="ml-3 mb-0 font-weight-bold"
                    >
                        도전중
                    </p>

                    <v-icon
                        class="ml-12"
                        :color="keyword.is_challenging? 'primary':'#b9b9c7'"
                    >
                        mdi-check-circle
                    </v-icon>
                </v-sheet>

                <!-- 공개 -->
                <v-sheet
                    class="ml-2 rounded-15 d-flex align-center px-4"
                    outlined
                    height="50"
                    width="172"
                    style="cursor:pointer;"
                    @click="keyword.is_open = !keyword.is_open; search(true)"
                >
                    <div>
                        <v-icon
                            v-if="keyword.is_open"
                            color="primary"
                            class="ml-1"
                        >
                            mdi-lock-open-variant-outline
                        </v-icon>
                        <v-icon
                            v-if="!keyword.is_open"
                            color="primary"
                        >
                            mdi-lock-outline
                        </v-icon>
                    </div>

                    <p
                        class="mb-0 font-weight-bold"
                        :class="keyword.is_open? 'ml-3':'ml-2'"
                    >
                        {{keyword.is_open? '공개':'비공개'}}
                    </p>

                    <v-spacer></v-spacer>

                    <v-icon
                        :color="keyword.is_open? 'primary':'#b9b9c7'"
                    >
                        mdi-check-circle
                    </v-icon>
                </v-sheet>

                <!-- 검색어 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="keyword.text"
                    placeholder="검색어"
                    class="ml-2 rounded-15 font-weight-bold"
                    height="50"
                    @keyup.enter="search(true)"
                >
                    <template v-slot:append>
                        <v-icon class="pt-1 mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    class="ml-3 rounded-10"
                    style="font-size:18px; font-weight:bold; letter-spacing:-0.6px;"
                    color="primary"
                    width="86"
                    height="46"
                    @click="search(true)"
                >
                    검색
                </v-btn>
            </v-sheet>

            <!-- 2단 (만들기 & 순서) -->
            <div
                class="mt-2 d-flex px-2"
            >
                <!-- 챌린지북 만들기 -->
                <v-btn
                    color="icu_purple"
                    class="rounded-10"
                    dark
                    depressed
                    @click="create_challenge_open()"
                >
                    챌린지북 만들기
                </v-btn>

                <v-spacer></v-spacer>

                <!-- 용스타 HIT북 -->
                <v-btn
                    color="#F8468D"
                    class="rounded-10 mr-1"
                    style="cursor:pointer;"
                    dark
                    depressed
                    @click="keyword.is_yongstar = !keyword.is_yongstar; is_yongstar_to_path(); search(true)"
                >
                    <div
                        class="rounded-circle d-flex justify-center align-center"
                        style="position:absolute; left:-30px; border:1px solid white; height:36px; width:36px;"
                        :style="keyword.is_yongstar? 'background:#F8468D':'background:#B3B3C2'"
                    >
                        <div>
                            <v-img
                                width="20"
                                height="20"
                                src="@/assets/star.svg"
                            ></v-img>
                        </div>
                    </div>
                    <span class="ml-2 white--text">용스타 HIT북</span>
                </v-btn>

                <v-icon
                    size="28"
                    class="mr-4"
                    color="#F8468D"
                    style="cursor:pointer;"
                    @click="dialog.yongstar = true"

                >
                    mdi-help-circle
                </v-icon>

                <!-- 순서 -->
                <div>
                    <!-- 생성일 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-2"
                        style="font-size:14px;"
                        :color="sequence == '생성일'? 'primary':'#B3B3C2'"
                        height="36"
                        width="100"
                        @click="sequence == '생성일'? (sequence = '생성일', align_date = !align_date) : sequence = '생성일'"
                    >
                        <v-icon size="16" class="mr-1">{{align_date? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        생성일
                    </v-btn>

                    <!-- 이름 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-2"
                        style="font-size:14px;"
                        :color="sequence == '이름'? 'primary':'#B3B3C2'"
                        height="36"
                        width="100"
                        @click="sequence == '이름'? (sequence = '이름', align_name = !align_name) : sequence = '이름'"
                    >
                        <v-icon size="16" class="mr-1">{{align_name? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        이름
                    </v-btn>

                    <!-- 도전자 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold"
                        style="font-size:14px;"
                        :color="sequence == '도전자'? 'primary':'#B3B3C2'"
                        height="36"
                        width="100"
                        @click="sequence == '도전자'? (sequence = '도전자', align_challenger = !align_challenger) : sequence = '도전자'"
                    >
                        <v-icon size="16" class="mr-1">{{align_challenger? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        도전자
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 1단 (검색) -->
            <v-sheet
                class="pa-2 pb-0 d-flex align-center"
            >
                <!-- 도전중 -->
                <v-sheet
                    class="rounded-15 d-flex align-center px-4"
                    outlined
                    height="40"
                    width="50%"
                    style="cursor:pointer;"
                    @click="keyword.is_challenging = !keyword.is_challenging; search(true)"
                >
                    <v-icon
                        color="primary"
                        size="20"
                    >
                        mdi-heart
                    </v-icon>

                    <p
                        class="ml-3 mb-0 font-weight-bold text-body-2"
                    >
                        도전중
                    </p>

                    <v-spacer></v-spacer>

                    <v-icon
                        :color="keyword.is_challenging? 'primary':'#b9b9c7'"
                        size="20"
                    >
                        mdi-check-circle
                    </v-icon>
                </v-sheet>

                <!-- 공개 -->
                <v-sheet
                    class="ml-2 rounded-15 d-flex align-center px-4"
                    outlined
                    height="40"
                    width="50%"
                    style="cursor:pointer;"
                    @click="keyword.is_open = !keyword.is_open; search(true)"
                >
                    <div>
                        <v-icon
                            v-if="keyword.is_open"
                            color="primary"
                            class="ml-1"
                            size="20"
                        >
                            mdi-lock-open-variant-outline
                        </v-icon>
                        <v-icon
                            v-if="!keyword.is_open"
                            color="primary"
                            size="20"
                        >
                            mdi-lock-outline
                        </v-icon>
                    </div>

                    <p
                        class="mb-0 font-weight-bold text-body-2"
                        :class="keyword.is_open? 'ml-3':'ml-2'"
                    >
                        {{keyword.is_open? '공개':'비공개'}}
                    </p>

                    <v-spacer></v-spacer>

                    <v-icon
                        :color="keyword.is_open? 'primary':'#b9b9c7'"
                        size="20"
                    >
                        mdi-check-circle
                    </v-icon>
                </v-sheet>
            </v-sheet>

            <v-sheet
                class="mb-2 pa-2 d-flex align-center"
            >
                <!-- 검색어 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="keyword.text"
                    placeholder="검색어"
                    class="rounded-15 font-weight-bold"
                    height="40"
                    @keyup.enter="search(true)"
                >
                    <template v-slot:append>
                        <v-icon class="pt-0 mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    class="ml-2 rounded-10"
                    style="font-size:16px; font-weight:bold; letter-spacing:-0.6px;"
                    color="primary"
                    width="86"
                    height="38"
                    @click="search(true)"
                >
                    검색
                </v-btn>
            </v-sheet>

            <!-- 2단 (만들기 & 순서) -->
            <div
                class="mt-2 d-flex justify-space-between px-2"
            >
                <!-- 챌린지북 만들기 -->
                <v-btn
                    color="icu_purple"
                    class="rounded-10"
                    dark
                    small
                    height="32"
                    depressed
                    @click="create_challenge_open()"
                >
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>

                <!-- 용스타 HIT북 -->
                <div>
                    <div
                        class="rounded-circle d-flex justify-center align-center ml-1"
                        style="border:1px solid white; height:32px; width:32px;"
                        :style="keyword.is_yongstar? 'background:#F8468D':'background:#B3B3C2'"
                        @click="keyword.is_yongstar = !keyword.is_yongstar; is_yongstar_to_path(); search(true)"
                    >
                        <div>
                            <v-img
                                width="16"
                                height="16"
                                src="@/assets/star.svg"
                            ></v-img>
                        </div>
                    </div>
                </div>

                <v-icon
                    size="24"
                    class="ml-1"
                    color="#F8468D"
                    style="cursor:pointer;"
                    @click="dialog.yongstar = true"

                >
                    mdi-help-circle
                </v-icon>

                <v-spacer></v-spacer>

                <!-- 순서 -->
                <div>
                    <!-- 생성일 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-1"
                        style="font-size:10px;"
                        :color="sequence == '생성일'? 'primary':'#B3B3C2'"
                        height="32"
                        width="68"
                        @click="sequence == '생성일'? (sequence = '생성일', align_date = !align_date) : sequence = '생성일'"
                    >
                        <v-icon size="16" class="mr-1">{{align_date? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        생성일
                    </v-btn>

                    <!-- 이름 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-1"
                        style="font-size:10px;"
                        :color="sequence == '이름'? 'primary':'#B3B3C2'"
                        height="32"
                        width="68"
                        @click="sequence == '이름'? (sequence = '이름', align_name = !align_name) : sequence = '이름'"
                    >
                        <v-icon size="16" class="mr-1">{{align_name? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        이름
                    </v-btn>

                    <!-- 도전자 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold"
                        style="font-size:10px;"
                        :color="sequence == '도전자'? 'primary':'#B3B3C2'"
                        height="32"
                        width="68"
                        @click="sequence == '도전자'? (sequence = '도전자', align_challenger = !align_challenger) : sequence = '도전자'"
                    >
                        <v-icon size="16" class="mr-1">{{align_challenger? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        도전자
                    </v-btn>
                </div>
            </div>
        </div>


        <!-- 목록 (PC) -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            class="mt-2 mb-4"
        >
            <v-sheet
                v-for="item in currentPageList" :key="item.id"
                class="mt-2 px-6 py-4 rounded-10 d-flex align-center"
                style="cursor:pointer;"
                @click="$router.push('/collection/challengebook/read?id='+item.id)"
            >
                <!-- 이미지 -->
                <div>
                    <v-img
                        width="56"
                        height="56"
                        class="rounded-circle"
                        :src="item.image"
                    ></v-img>
                </div>

                <!-- 글자 -->
                <div
                    class="ml-4"
                >
                    <!-- 1단 (제목) -->
                    <div
                        class="d-flex"
                    >
                        <p
                            class="font-weight-bold text-subtitle-1 mb-1 mt-2 text-truncate"
                            style="line-height:16px; max-width:300px;"
                        >
                            {{item.title}}
                        </p>

                        <!-- 용스타 -->
                        <div
                            v-if="item.writer == 'kakao_cyj5234'"
                            class="rounded-circle d-flex justify-center align-center mt-6px ml-2"
                            style="height:18px; width:18px; background:#F8468D;"
                        >
                            <div>
                                <v-img
                                    width="10"
                                    height="10"
                                    src="@/assets/star.svg"
                                ></v-img>
                            </div>
                        </div>

                        <!-- 운영자 -->
                        <div
                            v-if="item.writer == 'ichooseyou0512'"
                            class="rounded-circle d-flex justify-center align-center mt-6px ml-2"
                        >
                            <div>
                                <v-img
                                    width="18"
                                    height="18"
                                    src="@/assets/red.png"
                                ></v-img>
                            </div>
                        </div>
                    </div>

                    <!-- 2단 (상세정보) -->
                    <div
                        class="d-flex"
                    >
                        <!-- 공개여부 -->
                        <p
                            class="font-weight-medium text-subtitle-2 mb-0 mt-0"
                            style="color:#ADAFCA;"
                        >
                            {{item.is_open? '공개':'비공개'}}
                        </p>

                        <span class="mx-2 grey--text text--lighten-2">|</span>

                        <!-- 도전 수 -->
                        <p
                            class="font-weight-medium text-subtitle-2 mb-0 mt-0 primary--text"
                        >
                            {{item.apply_count}}명 도전 중
                        </p>

                        <span class="mx-2 grey--text text--lighten-2">|</span>

                        <!-- 장수 -->
                        <p
                            class="font-weight-medium text-subtitle-2 mb-0 mt-0"
                            style="color:#ADAFCA;"
                        >
                            {{item.card_amount}}장
                        </p>

                        <span class="mx-2 grey--text text--lighten-2">|</span>
                        
                        <div>
                            <!-- 프로필 -->
                            <ProfileForChallenge
                                :user="item.writer"
                                color="black"
                            />
                        </div>
                    </div>
                </div>

                <v-spacer></v-spacer>

                <!-- 수집률 그래프 -->
                <v-progress-linear
                    color="primary"
                    background-color="grey lighten-3"
                    rounded
                    style="width:200px;"
                    class="rounded-10 shrink"
                    height="10"
                    :value="calculateProbability(item.progress_amount, item.card_amount)"
                ></v-progress-linear>

                <!-- 수집률 -->
                <v-sheet
                    width="88"
                    height="32"
                    class="ml-12 d-flex justify-center align-center rounded-20 custom_shadow"
                >
                    <span
                        class="text-body-2 font-weight-medium"
                    >
                        {{calculateProbability(item.progress_amount, item.card_amount)}}%
                    </span>
                </v-sheet>

                <!-- 도전중 -->
                <v-icon
                    v-if="!item.challenge_loading"
                    class="ml-6"
                    :color="item.is_challenging? 'primary':''"
                    @click.stop="challenging_update(item)"
                >
                    mdi-heart
                </v-icon>

                <!-- 로딩 -->
                <v-progress-circular
                    v-if="item.challenge_loading"
                    class="ml-6"
                    style="width:24px;"
                    indeterminate
                    color="primary"
                    size="20"
                    @click.stop=""
                ></v-progress-circular>
            </v-sheet>

            <!-- 버튼 & 페이지네이션 -->
            <v-pagination
                v-if="!loading"
                class="mt-4"
                color="primary"
                v-model="page"
                :length="pageLength"
                :total-visible="10"
            ></v-pagination>
        </div>

        <!-- 목록 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mt-2 mb-4"
        >
            <v-sheet
                v-for="item in currentPageList" :key="item.id"
                class="mt-2 px-3 py-3 rounded-10 d-flex align-center"
                style="cursor:pointer;"
                @click="$router.push('/collection/challengebook/read?id='+item.id)"
            >
                <!-- 이미지 -->
                <div>
                    <v-img
                        width="48"
                        height="48"
                        class="rounded-circle"
                        :src="item.image"
                    ></v-img>
                </div>

                <!-- 글자 -->
                <div
                    class="ml-2 flex-grow-1"
                    style="width:60%;"
                >
                    <!-- 1단 (제목) -->
                    <div
                        class="d-flex"
                    >
                        <p
                            class="font-weight-bold mb-0 mt-0 text-truncate"
                            style="line-height:17px; font-size:15px;"
                        >
                            {{item.title}}
                        </p>
                        <div
                            v-if="item.writer == 'kakao_cyj5234'"
                            class="rounded-circle d-flex justify-center align-center ml-6px"
                            style="height:16px; width:16px; background:#F8468D;"
                        >
                            <div>
                                <v-img
                                    width="10"
                                    height="10"
                                    src="@/assets/star.svg"
                                ></v-img>
                            </div>
                        </div>
                    </div>

                    <!-- 2단 (상세정보) -->
                    <div
                        class="d-flex align-center mb-1"
                    >
                        <!-- 공개여부 -->
                        <p
                            class="mb-0 mt-0"
                            style="color:#ADAFCA; font-size:12px; letter-spacing:-0.4px;"
                        >
                            {{item.is_open? '공개':'비공개'}}
                        </p>

                        <span class="mx-1 grey--text text--lighten-2" style="font-size:12px;">|</span>

                        <!-- 도전 수 -->
                        <p
                            class="mb-0 mt-0 primary--text"
                            style="font-size:12px;"
                        >
                            {{item.apply_count}}명
                        </p>

                        <span class="mx-1 grey--text text--lighten-2" style="font-size:12px;">|</span>

                        <!-- 장수 -->
                        <p
                            class="mb-0 mt-0"
                            style="color:#ADAFCA; font-size:12px; letter-spacing:-0.4px;"
                        >
                            {{item.card_amount}}장
                        </p>

                        <span class="mx-1 grey--text text--lighten-2" style="font-size:12px;">|</span>
                        
                        <!-- 프로필 -->
                        <ProfileForChallenge
                            :user="item.writer"
                            color="black"
                        />
                    </div>

                    <!-- 수집률 그래프 -->
                    <v-progress-linear
                        color="primary"
                        background-color="grey lighten-3"
                        rounded
                        class="rounded-10"
                        style="font-size:9px;"
                        height="12"
                        :value="calculateProbability(item.progress_amount, item.card_amount)"
                    >
                        {{calculateProbability(item.progress_amount, item.card_amount)}}%
                    </v-progress-linear>
                </div>

                <v-spacer></v-spacer>

                <!-- 도전중 -->
                <v-icon
                    :color="item.is_challenging? 'primary':''"
                    @click.stop="challenging_update(item)"
                >
                    mdi-heart
                </v-icon>
            </v-sheet>

            <!-- 버튼 & 페이지네이션 -->
            <v-pagination
                v-if="!loading"
                class="mt-4"
                color="primary"
                v-model="page"
                :length="pageLength"
                :total-visible="10"
            ></v-pagination>
        </div>

        <v-dialog
            v-model="dialog.write"
            width="auto"
            content-class="rounded-15"
        >
            <CreateChallenge
                @close="dialog.write = false"
                @created="dialog.write = false; search(true)"
            />
        </v-dialog>

        <v-dialog
            v-model="dialog.yongstar"
            width="auto"
            content-class="rounded-15"
        >
            <v-sheet
                :width="$vuetify.breakpoint.mobile? '':480"
                class="rounded-15 pa-4"
                style="border:3px solid #F8468D"
            >
                <!-- 용스타 HIT북 -->
                <v-btn
                    color="#F8468D"
                    class="rounded-10 d-flex mx-auto"
                    style="cursor:pointer;"
                    dark
                    depressed
                >
                    <div
                        class="rounded-circle d-flex justify-center align-center"
                        style="position:absolute; left:-30px; border:1px solid white; height:36px; width:36px; background:#F8468D;"
                    >
                        <div>
                            <v-img
                                width="20"
                                height="20"
                                src="@/assets/star.svg"
                            ></v-img>
                        </div>
                    </div>
                    <span class="ml-2 white--text">용스타 HIT북</span>
                </v-btn>

                <p
                    class="mt-5 text-center px-8"
                >
                    오랜 기간 동안 하드컬렉터로 활동 중이신 용스타님이 만든
                    챌린지북입니다. 하드컬렉터의 수집 라인을 볼 수 있고 또,
                    함께 도전해 볼 수 있습니다
                </p>

                <v-btn
                    height="40"
                    width="90"
                    dark
                    depressed
                    color="#ADAFCA"
                    class="rounded-10 d-flex mx-auto mt-7"
                    style="font-size:14px;"
                    @click="dialog.yongstar = false"
                >
                    닫기
                </v-btn>
            </v-sheet>
        </v-dialog>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            :style="$vuetify.breakpoint.mobile? 'top: 360px;':'top: 40%;'"
            indeterminate
            color="primary"
            size="100"
        ></v-progress-circular>
    </div>
</template>
<script>
import Header from "../components/Header"
import CreateChallenge from "./list/CreateChallenge"
import ProfileForChallenge from "@/components/ProfileForChallenge"

export default {
    components: {
        Header,
        CreateChallenge,
        ProfileForChallenge
    },

    data: () => ({
        keyword: {
            is_challenging: false,
            is_open: true,
            is_yongstar: false,
            text: "",
        },

        list: [],
        currentPageList: [],
        page: 1,
        pageLength:1,

        // 순서
        sequence: "생성일",
        align_date: false,
        align_name: false,
        align_challenger: false,

        dialog: {
            write: false,
            yongstar: false
        },

        loading: false,
        challenge_loading: false
    }),

    watch: {
        // 페이징
        page(){
            this.$router.replace({ query: { ...this.$route.query, page: this.page, is_yongstar: this.keyword.is_yongstar }})
            this.currentPageList = this.list.slice((this.page-1)*10,(this.page)*10)
            window.scrollTo(0, 0)
        },

        // 순서
        sequence(){
            this.sortList()
        },

        // 순서
        align_date(){
            this.sortList()
        },

        // 순서
        align_name(){
            this.sortList()
        },

        // 순서
        align_challenger(){
            this.sortList()
        }
    },

    mounted(){
        if ('is_yongstar' in this.$route.query) {
            this.keyword.is_yongstar = JSON.parse(this.$route.query.is_yongstar)
        }
        
        this.search(true)
    },

    methods: {
        is_yongstar_to_path(){
            this.$router.replace({ query: { ...this.$route.query, page: this.page, is_yongstar: this.keyword.is_yongstar }})
        },

        // 챌린지북 생성하기
        create_challenge_open(){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            this.dialog.write = true
        },

        // 검색
        search(loading){
            if(loading){
                this.loading = true
            }else{
                this.loading = false
            }

            this.$http.post("/api/challenge/select/search", {
                params: {
                    keyword: this.keyword,
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                console.log("search", res)

                // 주소 page 불러오기
                if(this.$route.query.page){
                    this.page = parseInt(this.$route.query.page)
                }

                this.list = res.data
                this.currentPageList = this.list.slice((this.page-1)*10,(this.page)*10)
                this.pageLength = Math.ceil(res.data.length/10)
                // this.page = 1

                if(this.page > this.pageLength){
                    this.page = 1
                    this.$router.replace({ query: { ...this.$route.query, page: this.page, is_yongstar: this.keyword.is_yongstar }})
                }
                
                this.sortList()

                this.loading = false
            })
        },

        // 도전중 업데이트
        async challenging_update(item){
            if(!this.$store.state.is_logined){
                alert("로그인한 회원만 이용할 수 있습니다")
                return
            }

            item.challenge_loading = true
            
            if(item.is_challenging){
                await this.$http.post('/api/challenge/delete/apply', {
                    params: {
                        challenge_id: item.id,
                        user_id: this.$store.state.user_id
                    }
                }).then(async res => {
                    if(res.data.affectedRows){
                        await this.search(false)
                    }
                })
            }
            else{
                await this.$http.post('/api/challenge/insert/apply', {
                    params: {
                        challenge_id: item.id,
                        user_id: this.$store.state.user_id
                    }
                }).then(async res => {
                    if(res.data.affectedRows){
                        await this.search(false)
                    }
                })
            }

            setTimeout(() => {
                item.challenge_loading = false
            }, 2000)
        },

        // 순서
        sortList(){
            if(this.sequence == "생성일")
            {
                this.list = this.list.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                if(this.align_date){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "이름")
            {
                this.list.sort((a, b) => (typeof a.title !== 'string' ? 1 : (typeof b.title !== 'string' ? -1 : a.title.localeCompare(b.title))))
                if(this.align_name){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "도전자")
            {
                this.list = this.list.sort((a, b) => b.apply_count - a.apply_count)
                if(this.align_challenger){
                    this.list = this.list.reverse()
                }
            }

            this.currentPageList = this.list.slice((this.page-1)*10,(this.page)*10)
        },

        calculateProbability(count, totalNumber) {
            if (count == null || totalNumber == null || isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0 // 확률을 계산할 수 없으므로 0%로 표시
            }

            const probability = (count / totalNumber) * 100 // 확률 계산 (백분율로 표시)
            return parseInt(probability) // 소수점 두 자리까지 표시
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 4px #f0f0f0 !important;
}
</style>
<style scoped>
::v-deep input {
    font-weight:bold;
    margin-left:8px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

/* 페이징 */
::v-deep .mobile .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}
</style>