<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >

            <!-- 1단 (검색) -->
            <v-sheet
                class="rounded-15 pa-4 pb-2 d-flex align-center"
            >
                <!-- 도전중 -->
                <v-sheet
                    class="rounded-15 d-flex align-center px-4"
                    outlined
                    height="50"
                    style="cursor:pointer;"
                    @click="keyword.is_challenging = !keyword.is_challenging; search()"
                >
                    <v-icon
                        color="primary"
                    >
                        mdi-heart
                    </v-icon>

                    <p
                        class="ml-3 mb-0 font-weight-bold"
                    >
                        도전중
                    </p>

                    <v-icon
                        class="ml-12"
                        :color="keyword.is_challenging? 'primary':'#b9b9c7'"
                    >
                        mdi-check-circle
                    </v-icon>
                </v-sheet>

                <!-- 공개 -->
                <v-sheet
                    class="ml-2 rounded-15 d-flex align-center px-4"
                    outlined
                    height="50"
                    width="172"
                    style="cursor:pointer;"
                    @click="keyword.is_open = !keyword.is_open; search()"
                >
                    <div>
                        <v-icon
                            v-if="keyword.is_open"
                            color="primary"
                            class="ml-1"
                        >
                            mdi-lock-open-variant-outline
                        </v-icon>
                        <v-icon
                            v-if="!keyword.is_open"
                            color="primary"
                        >
                            mdi-lock-outline
                        </v-icon>
                    </div>

                    <p
                        class="mb-0 font-weight-bold"
                        :class="keyword.is_open? 'ml-3':'ml-2'"
                    >
                        {{keyword.is_open? '공개':'비공개'}}
                    </p>

                    <v-spacer></v-spacer>

                    <v-icon
                        :color="keyword.is_open? 'primary':'#b9b9c7'"
                    >
                        mdi-check-circle
                    </v-icon>
                </v-sheet>

                <!-- 검색어 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="keyword.text"
                    placeholder="검색어"
                    class="ml-2 rounded-15 font-weight-bold"
                    height="50"
                    @keyup.enter="search()"
                >
                    <template v-slot:append>
                        <v-icon class="pt-1 mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    class="ml-3 rounded-10"
                    style="font-size:18px; font-weight:bold; letter-spacing:-0.6px;"
                    color="primary"
                    width="86"
                    height="46"
                    @click="search()"
                >
                    검색
                </v-btn>
            </v-sheet>

            <!-- 2단 (만들기 & 순서) -->
            <div
                class="mt-2 d-flex px-4"
            >
                <!-- 용스타 HIT북 -->
                <v-btn
                    color="#F8468D"
                    class="rounded-10 mr-1 ml-3"
                    style="cursor:pointer;"
                    dark
                    depressed
                    @click="keyword.is_yongstar = !keyword.is_yongstar; search()"
                >
                    <div
                        class="rounded-circle d-flex justify-center align-center"
                        style="position:absolute; left:-30px; border:1px solid white; height:36px; width:36px;"
                        :style="keyword.is_yongstar? 'background:#F8468D':'background:#B3B3C2'"
                    >
                        <div>
                            <v-img
                                width="20"
                                height="20"
                                src="@/assets/star.svg"
                            ></v-img>
                        </div>
                    </div>
                    <span class="ml-2 white--text">용스타 HIT북</span>
                </v-btn>

                <v-icon
                    size="28"
                    class="mr-4"
                    color="#F8468D"
                    style="cursor:pointer;"

                >
                    mdi-help-circle
                </v-icon>

                <v-spacer></v-spacer>

                <!-- 순서 -->
                <div>
                    <!-- 생성일 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-2"
                        style="font-size:14px;"
                        :color="sequence == '생성일'? 'primary':'#B3B3C2'"
                        height="36"
                        width="100"
                        @click="sequence == '생성일'? (sequence = '생성일', align_date = !align_date) : sequence = '생성일'"
                    >
                        <v-icon size="16" class="mr-1">{{align_date? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        생성일
                    </v-btn>

                    <!-- 이름 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-2"
                        style="font-size:14px;"
                        :color="sequence == '이름'? 'primary':'#B3B3C2'"
                        height="36"
                        width="100"
                        @click="sequence == '이름'? (sequence = '이름', align_name = !align_name) : sequence = '이름'"
                    >
                        <v-icon size="16" class="mr-1">{{align_name? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        이름
                    </v-btn>

                    <!-- 도전자 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold"
                        style="font-size:14px;"
                        :color="sequence == '도전자'? 'primary':'#B3B3C2'"
                        height="36"
                        width="100"
                        @click="sequence == '도전자'? (sequence = '도전자', align_challenger = !align_challenger) : sequence = '도전자'"
                    >
                        <v-icon size="16" class="mr-1">{{align_challenger? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        도전자
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 1단 (검색) -->
            <v-sheet
                class="pt-2 pb-0 d-flex align-center"
            >
                <!-- 도전중 -->
                <v-sheet
                    class="rounded-15 d-flex align-center px-4"
                    outlined
                    height="40"
                    width="50%"
                    style="cursor:pointer;"
                    @click="keyword.is_challenging = !keyword.is_challenging; search()"
                >
                    <v-icon
                        color="primary"
                        size="20"
                    >
                        mdi-heart
                    </v-icon>

                    <p
                        class="ml-3 mb-0 font-weight-bold text-body-2"
                    >
                        도전중
                    </p>

                    <v-spacer></v-spacer>

                    <v-icon
                        :color="keyword.is_challenging? 'primary':'#b9b9c7'"
                        size="20"
                    >
                        mdi-check-circle
                    </v-icon>
                </v-sheet>

                <!-- 공개 -->
                <v-sheet
                    class="ml-2 rounded-15 d-flex align-center px-4"
                    outlined
                    height="40"
                    width="50%"
                    style="cursor:pointer;"
                    @click="keyword.is_open = !keyword.is_open; search()"
                >
                    <div>
                        <v-icon
                            v-if="keyword.is_open"
                            color="primary"
                            class="ml-1"
                            size="20"
                        >
                            mdi-lock-open-variant-outline
                        </v-icon>
                        <v-icon
                            v-if="!keyword.is_open"
                            color="primary"
                            size="20"
                        >
                            mdi-lock-outline
                        </v-icon>
                    </div>

                    <p
                        class="mb-0 font-weight-bold text-body-2"
                        :class="keyword.is_open? 'ml-3':'ml-2'"
                    >
                        {{keyword.is_open? '공개':'비공개'}}
                    </p>

                    <v-spacer></v-spacer>

                    <v-icon
                        :color="keyword.is_open? 'primary':'#b9b9c7'"
                        size="20"
                    >
                        mdi-check-circle
                    </v-icon>
                </v-sheet>
            </v-sheet>

            <v-sheet
                class="mb-2 pt-2 d-flex align-center"
            >
                <!-- 검색어 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="keyword.text"
                    placeholder="검색어"
                    class="rounded-15 font-weight-bold"
                    height="40"
                    @keyup.enter="search()"
                >
                    <template v-slot:append>
                        <v-icon class="pt-0 mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    class="ml-2 rounded-10"
                    style="font-size:16px; font-weight:bold; letter-spacing:-0.6px;"
                    color="primary"
                    width="86"
                    height="38"
                    @click="search()"
                >
                    검색
                </v-btn>
            </v-sheet>

            <!-- 2단 (만들기 & 순서) -->
            <div
                class="mt-2 d-flex justify-space-between"
            >
                <!-- 챌린지북 만들기 -->
                <v-btn
                    color="icu_purple"
                    class="rounded-10"
                    dark
                    small
                    height="32"
                    depressed
                    @click="dialog.write = true"
                >
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>

                <v-spacer></v-spacer>

                <!-- 순서 -->
                <div>
                    <!-- 생성일 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-2"
                        style="font-size:10px;"
                        :color="sequence == '생성일'? 'primary':'#B3B3C2'"
                        height="32"
                        width="70"
                        @click="sequence == '생성일'? (sequence = '생성일', align_date = !align_date) : sequence = '생성일'"
                    >
                        <v-icon size="16" class="mr-1">{{align_date? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        생성일
                    </v-btn>

                    <!-- 이름 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-2"
                        style="font-size:10px;"
                        :color="sequence == '이름'? 'primary':'#B3B3C2'"
                        height="32"
                        width="70"
                        @click="sequence == '이름'? (sequence = '이름', align_name = !align_name) : sequence = '이름'"
                    >
                        <v-icon size="16" class="mr-1">{{align_name? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        이름
                    </v-btn>

                    <!-- 도전자 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold"
                        style="font-size:10px;"
                        :color="sequence == '도전자'? 'primary':'#B3B3C2'"
                        height="32"
                        width="70"
                        @click="sequence == '도전자'? (sequence = '도전자', align_challenger = !align_challenger) : sequence = '도전자'"
                    >
                        <v-icon size="16" class="mr-1">{{align_challenger? 'mdi-sort-reverse-variant':'mdi-sort-variant'}}</v-icon>
                        도전자
                    </v-btn>
                </div>
            </div>
        </div>


        <!-- 목록 (PC) -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            class="mt-2 px-4 pt-1 pb-2"
        >
            <v-sheet
                v-for="item in list" :key="item.id"
                class="mt-2 px-6 py-4 rounded-10 d-flex align-center"
                style="cursor:pointer;"
                outlined
                @click="$emit('ChallengeSelected', item.id)"
            >
                <!-- 이미지 -->
                <div>
                    <v-img
                        width="56"
                        height="56"
                        class="rounded-circle"
                        :src="item.image"
                    ></v-img>
                </div>

                <!-- 글자 -->
                <div
                    class="ml-4"
                >
                    <!-- 1단 (제목) -->
                    <div
                        class="d-flex"
                    >
                        <p
                            class="font-weight-bold text-subtitle-1 mb-1 mt-2"
                            style="line-height:16px;"
                        >
                            {{item.title}}
                        </p>
                        <div
                            v-if="item.writer == 'kakao_cyj5234'"
                            class="rounded-circle d-flex justify-center align-center mt-6px ml-2"
                            style="height:18px; width:18px; background:#F8468D;"
                        >
                            <div>
                                <v-img
                                    width="10"
                                    height="10"
                                    src="@/assets/star.svg"
                                ></v-img>
                            </div>
                        </div>
                    </div>

                    <!-- 2단 (상세정보) -->
                    <div
                        class="d-flex"
                    >
                        <!-- 공개여부 -->
                        <p
                            class="font-weight-medium text-subtitle-2 mb-0 mt-0"
                            style="color:#ADAFCA;"
                        >
                            {{item.is_open? '공개':'비공개'}}
                        </p>

                        <span class="mx-2 grey--text text--lighten-2">|</span>

                        <!-- 도전 수 -->
                        <p
                            class="font-weight-medium text-subtitle-2 mb-0 mt-0 primary--text"
                        >
                            {{item.apply_count}}명 도전 중
                        </p>

                        <span class="mx-2 grey--text text--lighten-2">|</span>

                        <!-- 장수 -->
                        <p
                            class="font-weight-medium text-subtitle-2 mb-0 mt-0"
                            style="color:#ADAFCA;"
                        >
                            {{item.card_amount}}장
                        </p>

                        <span class="mx-2 grey--text text--lighten-2">|</span>
                        
                        <div>
                            <!-- 프로필 -->
                            <ProfileForChallenge
                                :user="item.writer"
                                color="black"
                            />
                        </div>
                    </div>
                </div>

                <v-spacer></v-spacer>

                <!-- 수집률 그래프 -->
                <v-progress-linear
                    color="primary"
                    background-color="grey lighten-3"
                    rounded
                    style="width:200px;"
                    class="rounded-10 shrink"
                    height="10"
                    :value="calculateProbability(item.progress_amount, item.card_amount)"
                ></v-progress-linear>

                <!-- 수집률 -->
                <v-sheet
                    width="88"
                    height="32"
                    class="ml-12 d-flex justify-center align-center rounded-20 custom_shadow"
                >
                    <span
                        class="text-body-2 font-weight-medium"
                    >
                        {{calculateProbability(item.progress_amount, item.card_amount)}}%
                    </span>
                </v-sheet>

                <!-- 도전중 -->
                <v-icon
                    class="ml-6"
                    :color="item.is_challenging? 'primary':''"
                    @click.stop="challenging_update(item)"
                >
                    mdi-heart
                </v-icon>
            </v-sheet>
        </div>

        <!-- 목록 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mt-2"
        >
            <v-sheet
                v-for="item in list" :key="item.id"
                class="mt-2 px-3 py-3 rounded-10 d-flex align-center"
                outlined
                style="cursor:pointer;"
                @click="$router.push('/collection/challengebook/read?id='+item.id)"
            >
                <!-- 이미지 -->
                <div>
                    <v-img
                        width="48"
                        height="48"
                        class="rounded-circle"
                        :src="item.image"
                    ></v-img>
                </div>

                <!-- 글자 -->
                <div
                    class="ml-2 flex-grow-1"
                    style="width:60%;"
                >
                    <!-- 1단 (제목) -->
                    <div
                        class="d-flex"
                    >
                        <p
                            class="font-weight-bold mb-0 mt-0 text-truncate"
                            style="line-height:15px; font-size:15px;"
                        >
                            {{item.title}}
                        </p>
                        <div
                            v-if="item.writer == 'kakao_cyj5234'"
                            class="rounded-circle d-flex justify-center align-center ml-6px"
                            style="height:16px; width:16px; background:#F8468D;"
                        >
                            <div>
                                <v-img
                                    width="10"
                                    height="10"
                                    src="@/assets/star.svg"
                                ></v-img>
                            </div>
                        </div>
                    </div>

                    <!-- 2단 (상세정보) -->
                    <div
                        class="d-flex align-center mb-0"
                    >
                        <!-- 공개여부 -->
                        <!-- <p
                            class="mb-0 mt-0"
                            style="color:#ADAFCA; font-size:12px; letter-spacing:-0.4px;"
                        >
                            {{item.is_open? '공개':'비공개'}}
                        </p>

                        <span class="mx-1 grey--text text--lighten-2" style="font-size:12px;">|</span> -->

                        <!-- 도전 수 -->
                        <p
                            class="mb-0 mt-0 primary--text"
                            style="font-size:12px;"
                        >
                            {{item.apply_count}}명
                        </p>

                        <span class="mx-1 grey--text text--lighten-2" style="font-size:12px;">|</span>

                        <!-- 장수 -->
                        <p
                            class="mb-0 mt-0"
                            style="color:#ADAFCA; font-size:12px; letter-spacing:-0.4px;"
                        >
                            {{item.card_amount}}장
                        </p>

                        <span class="mx-1 grey--text text--lighten-2" style="font-size:12px;">|</span>
                        
                        <!-- 프로필 -->
                        <ProfileForChallenge
                            :user="item.writer"
                            color="black"
                        />
                    </div>

                    <!-- 수집률 그래프 -->
                    <v-progress-linear
                        color="primary"
                        background-color="grey lighten-3"
                        rounded
                        class="rounded-10"
                        style="font-size:9px;"
                        height="12"
                        :value="0"
                    >
                        {{0}}%
                    </v-progress-linear>
                </div>

                <v-spacer></v-spacer>

                <!-- 도전중 -->
                <v-icon
                    :color="item.is_challenging? 'primary':''"
                    @click.stop="challenging_update(item)"
                >
                    mdi-heart
                </v-icon>
            </v-sheet>
        </div>

        <v-dialog
            v-model="dialog.write"
            width="auto"
            content-class="rounded-15"
        >
            <CreateChallenge
                @close="dialog.write = false"
                @created="dialog.write = false; search()"
            />
        </v-dialog>
    </div>
</template>
<script>
import Header from "@/views/collection/components/Header"
import CreateChallenge from "@/views/collection/challengebook/list/CreateChallenge"
import ProfileForChallenge from "@/components/ProfileForChallenge"

export default {
    components: {
        Header,
        CreateChallenge,
        ProfileForChallenge
    },

    data: () => ({
        keyword: {
            is_challenging: false,
            is_open: true,
            is_yongstar: false,
            text: "",
        },

        list: [],

        // 순서
        sequence: "생성일",
        align_date: false,
        align_name: false,
        align_challenger: false,

        dialog: {
            write: false
        }
    }),

    watch: {
        // 순서
        sequence(){
            this.sortList()
        },

        // 순서
        align_date(){
            this.sortList()
        },

        // 순서
        align_name(){
            this.sortList()
        },

        // 순서
        align_challenger(){
            this.sortList()
        }
    },

    mounted(){
        this.search()
    },

    methods: {
        // 검색
        search(){
            this.$http.post("/api/challenge/select/search", {
                params: {
                    keyword: this.keyword,
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                console.log("search", res)
                this.list = res.data
            })
        },

        // 도전중 업데이트
        challenging_update(item){
            if(item.is_challenging){
                this.$http.post('/api/challenge/delete/apply', {
                    params: {
                        challenge_id: item.id,
                        user_id: this.$store.state.user_id
                    }
                }).then(res => {
                    if(res.data.affectedRows){
                        this.search()
                    }
                })
            }
            else{
                this.$http.post('/api/challenge/insert/apply', {
                    params: {
                        challenge_id: item.id,
                        user_id: this.$store.state.user_id
                    }
                }).then(res => {
                    if(res.data.affectedRows){
                        this.search()
                    }
                })
            }
        },

        // 순서
        sortList(){
            if(this.sequence == "생성일")
            {
                this.list = this.list.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                if(this.align_date){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "이름")
            {
                this.list.sort((a, b) => (typeof a.title !== 'string' ? 1 : (typeof b.title !== 'string' ? -1 : a.title.localeCompare(b.title))))
                if(this.align_name){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "도전자")
            {
                this.list = this.list.sort((a, b) => b.apply_count - a.apply_count)
                if(this.align_challenger){
                    this.list = this.list.reverse()
                }
            }
        },

        calculateProbability(count, totalNumber) {
            if (count == null || totalNumber == null || isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0 // 확률을 계산할 수 없으므로 0%로 표시
            }

            const probability = (count / totalNumber) * 100 // 확률 계산 (백분율로 표시)
            return parseInt(probability) // 소수점 두 자리까지 표시
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 4px #f0f0f0 !important;
}
</style>
<style scoped>
::v-deep input {
    font-weight:bold;
    margin-left:8px;
}
</style>