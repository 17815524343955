<template>
    <v-sheet
        color="transparent"
        class="d-flex align-center rounded-10"
        :class="$vuetify.breakpoint.xs? 'pl-2 py-2' : 'pl-4 py-4'"
    >
        <!-- 제목 -->
        <div>
            <p
                class="mt-0 mb-0 font-weight-bold"
                style="letter-spacing:-0.5px;"
                :style="$vuetify.breakpoint.xs? 'font-size:16px' : 'font-size:26px'"
            >
                {{$route.query.board_group}}
                <v-icon class="mb-1 mx-0" color="#424361">mdi-chevron-right</v-icon>
                {{$route.query.board_name}}
            </p>
            <p v-if="!$vuetify.breakpoint.xs" style="font-size:11px; margin-top:-4px; margin-bottom:0">
                공지사항과 이용약관을 준수하여 활동 부탁드립니다
            </p>
        </div>

        <v-spacer></v-spacer>

        <BannerView
            v-if="$route.path.startsWith('/board/read') && $store.state.ads_banner.community"
            type="community"
            width="360"
            height="46"
            :round="'rounded-10'"
        />
    </v-sheet>
</template>
<script>
import BannerView from '@/components/BannerView'

export default {
    components: {
        BannerView
    }
}
</script>