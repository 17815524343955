<template>
    <div class="pa-4 pr-0 d-flex align-center">
        <!-- 제목 -->
        <div
            v-if="!$route.path.startsWith('/board')"
        >
            <p style="font-size:26px; font-weight:bold; margin-bottom:4px; cursor:pointer;" @click="$router.push('/deck/list')">
                덱 레시피
                <span style="margin-left:4px; color:#F8468D; font-size:12px; vertical-align:super; font-weight:500;">BETA</span>
            </p>

            <p style="font-size:11px;" class="mb-0">나만의 덱을 구성하고 공유해보세요</p>
        </div>

        <!-- 제목 -->
        <div
            v-if="$route.path.startsWith('/board')"
        >
            <p style="font-size:24px; font-weight:bold; margin-bottom:-2px; margin-left:16px;">덱 레시피 불러오기</p>
        </div>
        <v-spacer></v-spacer>

        <!-- 덱 코드 출력 -->
        <v-sheet
            v-if="$route.path.startsWith('/deck/read')"
            class="mt-1 rounded-15 d-flex justify-space-between mr-2"
        >
            <v-btn
                height="56"
                width="180"
                class="px-0"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                @click="$emit('deckExport')"
            >
                <v-sheet height="30" class="d-flex mr-1">
                    <v-img contain src="@/assets/deck_export.svg"></v-img>
                </v-sheet>
                <span style="color:#424361;">덱 코드 출력</span>
            </v-btn>
        </v-sheet>

        <!-- 덱 코드 입력 -->
        <v-sheet
            v-if="$route.path.startsWith('/deck/write') || $route.path.startsWith('/deck/update') || $route.path.startsWith('/deck/copy')"
            class="mt-1 rounded-15 d-flex justify-space-between mr-2"
        >
            <v-btn
                height="56"
                width="180"
                class="px-0"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                @click="$emit('deckImport')"
            >
                <v-sheet height="30" class="d-flex mr-1">
                    <v-img contain src="@/assets/deck_export.svg"></v-img>
                </v-sheet>
                <span style="color:#424361;">덱 코드 입력</span>
            </v-btn>
        </v-sheet>

        <v-btn
            v-if="$route.path == '/deck/list'"
            height="56"
            class="px-4 mr-2 rounded-15"
            color="primary"
            style="font-size:18px; font-weight:bold; letter-spacing:-0.5px;"
            depressed
            :to="'/record?user_id='+$store.state.user_id"
        >
            전적관리
        </v-btn>
        
        <!-- 메뉴 -->
        <v-sheet
            v-if="!$route.path.startsWith('/deck/read')"
            class="mt-1 pt-1 px-9 rounded-15 d-flex justify-space-between"
        >
            <v-btn
                height="56"
                class="px-0 mr-8"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="card_nation == 'kr'? 'primary':''"
                :style="card_nation == 'kr'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                :dark="card_nation == 'kr'"
                @click="changeCardNation('kr')"
            >
                국내판
            </v-btn>
            <v-btn
                height="56"
                class="px-0 mr-8"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="card_nation == 'en'? 'primary':''"
                :style="card_nation == 'en'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                :dark="card_nation == 'en'"
                @click="changeCardNation('en')"
            >
                북미판
            </v-btn>
            <v-btn
                height="56"
                class="px-0"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                :color="card_nation == 'jp'? 'primary':''"
                :style="card_nation == 'jp'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                :dark="card_nation == 'jp'"
                @click="changeCardNation('jp')"
            >
                일본판
            </v-btn>
        </v-sheet>

        <!-- 덱 레시피 주소 복사 -->
        <v-sheet
            v-if="$route.path.startsWith('/deck/read')"
            class="mt-1 rounded-15 d-flex justify-space-between"
        >
            <v-btn
                height="56"
                width="239"
                class="px-0"
                style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                text
                tile
                depressed
                v-ripple="false"
                @click="copyUrl()"
            >
                <v-icon color="#424361" class="mr-2">mdi-content-copy</v-icon>
                <span style="color:#424361;">덱 레시피 주소 복사</span>
            </v-btn>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        card_nation: "kr"
    }),

    mounted(){
        if(this.$route.query.nation){
            this.card_nation = this.$route.query.nation
        }
    },

    computed: {
        url(){
            return (process.env.NODE_ENV == 'development'? 'localhost:8081':'https://icu.gg') + this.$route.fullPath
        }
    },

    methods: {
        copyUrl(){
            this.$copyText(this.url)
            alert("덱 레시피 주소가 복사 되었습니다")
        },

        changeCardNation(card_nation){
            if(this.$route.path.startsWith('/deck/write') || this.$route.path.startsWith('/deck/update'))
            {
                if(confirm("발매 국가를 변경하면 선택한 목록이 초기화됩니다.\n변경하시겠습니까?")){
                    this.card_nation = card_nation
                    this.$emit("nationUpdated", card_nation)
                }
            }
            else
            {
                this.card_nation = card_nation
                this.$emit("nationUpdated", card_nation)
            }
        }
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>