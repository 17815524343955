<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            width="780"
            class="rounded-15 px-16 pt-8 pb-4"
            color="#F8F7FB"
            style="border:2px solid #615DFA; position:relative;"
        >
            <!-- 제목 -->
            <p class="mb-5 font-weight-bold text-center" style="font-size:24px;">
                챌린지북 수정
            </p>

            <!-- 삭제 -->
            <v-btn
                style="position:absolute; top:12px; right:12px;"
                width="38"
                height="34"
                x-small
                dark
                depressed
                color="#f95596"
                class="font-weight-bold rounded-10"
                @click="deleteSubmit()"
            >
                <v-icon size="20">mdi-trash-can-outline</v-icon>
            </v-btn>

            <!-- 챌린지북 이름 -->
            <v-sheet class="d-flex align-center py-2 px-6 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">챌린지북 이름</span>
                <v-text-field
                    style="max-width:360px; margin-left:80px;"
                    placeholder="이름을 입력해주세요"
                    solo
                    flat
                    hide-details
                    v-model="title"
                ></v-text-field>
            </v-sheet>

            <!-- 공개여부 -->
            <v-sheet class="d-flex align-center py-3 px-6 rounded-10 mt-2">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">공개여부</span>
                <p
                    class="mb-0 flex-grow-1 text-center"
                    style="font-size:15px; font-weight:bold; margin-left:50px;"
                >
                    {{is_open? "공개":"비공개"}}
                </p>
            </v-sheet>

            <!-- 안내문구 -->
            <p class="text-center font-weight-bold my-2 primary--text" style="font-size:12px;">
                공개 여부는 한 번 선택하면 수정할 수 없습니다.
            </p>

            <!-- 커버 이미지 카드 -->
            <v-sheet class="d-flex align-center py-3 px-6 mt-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">커버 이미지 카드</span>
                <div class="flex-grow-1">
                    <v-img
                        width="56"
                        height="56"
                        class="rounded-circle grey lighten-4"
                        style="cursor:pointer; margin:0 auto;"
                        :src="image"
                        @click="dialog.cardList = true"
                    ></v-img>
                </div>
            </v-sheet>

            <!-- 설명 -->
            <v-sheet class="py-4 px-6 mt-3 rounded-10">
                <v-icon size="8" color="#ADAFCA" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">설명</span>
                <v-textarea
                    class="no_under"
                    rows="4"
                    hide-details
                    v-model="content"
                    placeholder="내용을 입력해주세요"
                ></v-textarea>
            </v-sheet>

            <!-- 버튼 -->
            <div class="mt-2 pa-2 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="110"
                    height="42"
                    dark
                    depressed
                    color="#615DFA"
                    class="font-weight-bold rounded-10 mr-2"
                    @click="submit()"
                >
                    수정
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="110"
                    height="42"
                    depressed
                    color="white"
                    class="font-weight-bold rounded-10"
                    @click="$emit('close')"
                >
                    취소
                </v-btn>
            </div>

            <!-- 카드선택 > 카드목록 (dialog) -->
            <v-dialog
                v-if="!$vuetify.breakpoint.mobile"
                v-model="dialog.cardList"
                width="1040"
                content-class="rounded-15 white"
            >
                <CardList
                    :isWrite="true"
                    @goToDetail="goToDetail"
                />
            </v-dialog>

            <!-- 카드선택 > 카드상세 (dialog) -->
            <v-dialog
                v-if="!$vuetify.breakpoint.mobile"
                v-model="dialog.cardDetail"
                width="1040"
                content-class="pa-4 rounded-15 white"
            >
                <CardDetail
                    :id="cardDetail_id"
                    :isBoardContent="false"
                    @CardSelected="CardSelected"
                    @Close="dialog.cardDetail = false"
                    :key="componentKey"
                />
            </v-dialog>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="rounded-15 px-3 pt-4 pb-2"
            color="#F8F7FB"
            style="border:2px solid #615DFA; position:relative;"
        >
            <!-- 제목 -->
            <p class="mb-3 font-weight-bold text-center" style="font-size:16px;">
                챌린지북 수정
            </p>

            <!-- 삭제 -->
            <v-btn
                style="position:absolute; top:10px; right:10px;"
                width="26"
                height="24"
                x-small
                dark
                depressed
                color="#f95596"
                class="font-weight-bold rounded-lg"
                @click="deleteSubmit()"
            >
                <v-icon size="14">mdi-trash-can-outline</v-icon>
            </v-btn>

            <!-- 챌린지북 이름 -->
            <v-sheet class="d-flex align-center py-2 px-3 rounded-10 mobile">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:10px; font-weight:bold; width:100px;">챌린지북 이름</span>
                <v-text-field
                    placeholder="이름을 입력해주세요"
                    style="font-size:10px !important;"
                    solo
                    flat
                    hide-details
                    v-model="title"
                ></v-text-field>
            </v-sheet>
            
            <!-- 공개여부 -->
            <v-sheet class="d-flex align-center py-3 px-3 rounded-10 mt-2">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:10px; font-weight:bold; width:100px;">공개여부</span>
                <p
                    class="mb-0 flex-grow-1 text-center"
                    style="font-size:10px; font-weight:bold; width:200px;"
                >
                    {{is_open? "공개":"비공개"}}
                </p>
            </v-sheet>

            <!-- 안내문구 -->
            <p class="text-center my-1 primary--text" style="font-size:8px;">
                공개 여부는 한 번 선택하면 수정할 수 없습니다.
            </p>

            <!-- 커버 이미지 카드 -->
            <v-sheet class="d-flex align-center py-3 px-3 mt-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:10px; font-weight:bold; width:100px;">커버 이미지 카드</span>
                <div class="flex-grow-1">
                    <v-img
                        width="40"
                        height="40"
                        class="rounded-circle grey lighten-4"
                        style="cursor:pointer; margin:0 auto;"
                        :src="image"
                        @click="dialog.cardList = true"
                    ></v-img>
                </div>
            </v-sheet>

            <!-- 설명 -->
            <v-sheet class="py-4 px-3 mt-3 rounded-10">
                <v-icon size="8" color="#ADAFCA" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:10px; font-weight:bold; width:100px;">설명</span>
                <v-textarea
                    class="no_under"
                    style="font-size:10px;"
                    rows="4"
                    hide-details
                    v-model="content"
                    placeholder="내용을 입력해주세요"
                ></v-textarea>
            </v-sheet>

            <!-- 버튼 -->
            <div class="mt-2 pa-2 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="80"
                    height="32"
                    dark
                    depressed
                    color="#615DFA"
                    class="font-weight-bold rounded-10 mr-2"
                    @click="submit()"
                >
                    수정
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="80"
                    height="32"
                    depressed
                    color="white"
                    class="font-weight-bold rounded-10"
                    @click="$emit('close')"
                >
                    취소
                </v-btn>
            </div>

            <!-- 카드목록 & 선택 (bottom-sheet) (Mobile) -->
            <v-bottom-sheet
                v-if="$vuetify.breakpoint.mobile"
                v-model="dialog.cardList"
                content-class="white"
                fullscreen
            >
                <CardListMobile
                    :isWrite="true"
                    @goToDetail="goToDetail"
                    @CardSelected="CardSelected"
                />
            </v-bottom-sheet>
        </v-sheet>
    </div>
</template>
<script>
import CardList from '@/components/card/List'
import CardListMobile from '@/components/card/ListForMobile'
import CardDetail from '@/components/card/detailForTrade/Detail'

export default {
    components: {
        CardList,
        CardListMobile,
        CardDetail
    },

    props: [
        "title_import",
        "is_open_import",
        "image_import",
        "content_import"
    ],

    data: () => ({
        title: "",
        image_name: "",
        image: "",
        is_open: true,
        content: "",

        dialog: {
            cardList:false,
            cardDetail: false,
        },

        cardDetail_id: null,

        componentKey: 0
    }),

    mounted(){
        this.title = this.title_import
        this.is_open = this.is_open_import
        this.image_name = this.image_import.split('/').pop()
        this.image = this.image_import
        this.content = this.content_import
    },

    methods: {
        // 카드 목록에서 카드 선택
        goToDetail(id){
            this.cardDetail_id = id
            this.dialog.cardDetail = true
            this.componentKey++
        },

        // 선택한 카드 정보
        CardSelected(card, nation){
            if(!this.$vuetify.breakpoint.mobile){
                if(nation=='kr'){
                    this.image_name = card.kr.image
                    this.image = "/upload/card_kr_image/" + card.kr.image
                }
                else if(nation=='en'){
                    this.image_name = card.en.image
                    this.image = "/upload/card_en_image/" + card.en.image
                }
                else if(nation=='jp'){
                    this.image_name = card.jp.image
                    this.image = "/upload/card_jp_image/" + card.jp.image
                }
            }else{
                if(nation=='kr'){
                    this.image = "/upload/card_kr_image/" + card.image_kr
                }
                else if(nation=='en'){
                    this.image = "/upload/card_en_image/" + card.image_en
                }
                else if(nation=='jp'){
                    this.image = "/upload/card_jp_image/" + card.image_jp
                }
            }

            this.dialog.cardList = false
            this.dialog.cardDetail = false
        },

        // 제출
        submit(){
            if(this.title == ""){
                alert("챌린지북 이름을 입력해주세요")
            }
            else if(this.image == ""){
                alert("커버 이미지 카드를 선택해주세요")
            }
            else if(this.content == ""){
                alert("설명을 입력해주세요")
            }
            else{
                this.$http.post("/api/challenge/update", {
                    params: {
                        id: this.$route.query.id,
                        title: this.title,
                        image: this.image,
                        content: this.content
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("챌린지북이 수정되었습니다.")
                        this.$emit("updated")
                    }
                })
            }
        },

        // 삭제
        deleteSubmit(){
            if(confirm("정말 해당 챌린지북을 삭제하겠습니까?")){
                this.$http.post("/api/challenge/select/apply", {
                    params: {
                        challenge_id: this.$route.query.id
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("현재 챌린지북에는 도전자가 있어 삭제할 수 없습니다.")
                    }
                    else{
                        this.$http.post("/api/challenge/delete", {
                            params: {
                                challenge_id: this.$route.query.id
                            }
                        }).then((res) => {
                            if(res.data.affectedRows){
                                alert("챌린지북이 삭제되었습니다.")
                                this.$router.push("/collection/challengebook/list")
                            }
                        })
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>