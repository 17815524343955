<template>
    <v-sheet
        :width="$vuetify.breakpoint.mobile? '':'360'"
        class="rounded-10"
        :class="$vuetify.breakpoint.mobile? 'pa-4':'pa-6'"
        style="position:relative;"
    >
        <!-- 제목 -->
        <p
            class="font-weight-bold text-center"
            :class="$vuetify.breakpoint.mobile? 'mb-4':'mb-5'"
            :style="$vuetify.breakpoint.mobile? 'font-size:20px;':'font-size:24px;'"
        >
            카드 관리
        </p>

        <!-- 전체 삭제 -->
        <v-btn
            v-if="!loading"
            style="position:absolute; top:10px; right:10px;"
            width="60"
            height="24"
            x-small
            dark
            depressed
            color="#f95596"
            class="font-weight-bold rounded-lg"
            @click="deleteSubmit()"
        >
            전체 삭제
        </v-btn>

        <!-- 로딩 -->
        <v-sheet
            v-if="loading"
            height="150"
            class="d-flex justify-center align-center mb-8"
        >
            <v-progress-circular
                indeterminate
                color="primary"
                size="80"
            ></v-progress-circular>
        </v-sheet>

        <!-- 카드 목록 -->
        <div
            v-if="!loading"
        >
            <v-sheet
                v-for="(item, index) in card_list"
                :key="'card_'+index"
                class="pa-2 mt-2 d-flex rounded-15"
                outlined
            >
                <!-- 이미지 -->
                <div>
                    <v-img
                        class="rounded-10 mr-2"
                        width="76"
                        height="106"
                        :src="'/upload/card_'+item.card_nation+'_image/'+item.card_image"
                    ></v-img>
                </div>

                <!-- 텍스트 -->
                <div>
                    <!-- 이름 & 제품명 & 발매국가 -->
                    <div
                        class="ml-1 pt-1"
                        style="height:68px;"
                    >
                        <!-- 이름 -->
                        <p
                            class="font-weight-bold mb-1"
                        >
                            {{item.card_name}}
                        </p>

                        <!-- 제품명 -->
                        <div>
                            <p
                                v-if="item.card_nation == 'kr'"
                                class="text-caption text-truncate mb-0"
                                :style="$vuetify.breakpoint.mobile? 'width:176px;':'width:200px;'"
                            >
                                {{item.set_kr}}
                            </p>
                            <p
                                v-if="item.card_nation == 'en'"
                                class="text-caption text-truncate mb-0"
                                :style="$vuetify.breakpoint.mobile? 'width:176px;':'width:200px;'"
                            >
                                {{item.set_en}}
                            </p>
                            <p
                                v-if="item.card_nation == 'jp'"
                                class="text-caption text-truncate mb-0"
                                :style="$vuetify.breakpoint.mobile? 'width:176px;':'width:200px;'"
                            >
                                {{item.set_jp}}
                            </p>
                        </div>

                        <!-- 발매국가 -->
                        <p
                            class="mb-0 text-caption text-uppercase"
                        >
                            {{item.card_nation}}
                        </p>
                    </div>

                    <!-- 버튼 (PC) -->
                    <div
                        v-if="!$vuetify.breakpoint.mobile"
                        class="d-flex mt-2"
                    >
                        <!-- 카드 변경 -->
                        <v-btn 
                            height="28"
                            class="rounded-10 px-3"
                            small
                            color="icu_purple"
                            dark
                            depressed
                            @click="searchCard(index)"
                        >
                            카드 변경
                        </v-btn>

                        <!-- 삭제 -->
                        <v-btn
                            height="28"
                            class="ml-1 rounded-10 px-3"
                            small
                            color="icu_purple"
                            dark
                            depressed
                            @click="card_list.splice(index,1)"
                        >
                            삭제
                        </v-btn>

                        <!-- 아래로 -->
                        <v-btn
                            height="28"
                            class="ml-1 rounded-10 px-1"
                            small
                            color="primary"
                            dark
                            depressed
                            @click="shiftDownField(index)"
                        >
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>

                        <!-- 위로 -->
                        <v-btn
                            height="28"
                            class="ml-1 rounded-10 px-1"
                            small
                            color="primary"
                            dark
                            depressed
                            @click="shiftUpField(index)"
                        >
                            <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>
                    </div>

                    <!-- 버튼 (Mobile) -->
                    <div
                        v-if="$vuetify.breakpoint.mobile"
                        class="d-flex mt-14px"
                    >
                        <!-- 카드 검색 버튼 -->
                        <v-btn 
                            height="24"
                            class="rounded-lg px-2 text-caption"
                            x-small
                            color="icu_purple"
                            dark
                            depressed
                            @click="searchCard(index)"
                        >
                            카드 변경
                        </v-btn>

                        <!-- 삭제 -->
                        <v-btn
                            height="24"
                            class="ml-1 rounded-lg px-2 text-caption"
                            x-small
                            color="icu_purple"
                            dark
                            depressed
                            @click="card_list.splice(index,1)"
                        >
                            삭제
                        </v-btn>

                        <!-- 아래로 -->
                        <v-btn
                            height="24"
                            width="28"
                            class="ml-1 rounded-lg"
                            x-small
                            color="primary"
                            dark
                            depressed
                            @click="shiftDownField(index)"
                        >
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>

                        <!-- 위로 -->
                        <v-btn
                            height="24"
                            width="28"
                            class="ml-1 rounded-lg"
                            x-small
                            color="primary"
                            dark
                            depressed
                            @click="shiftUpField(index)"
                        >
                            <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-sheet>
        </div>

        <!-- 하단 카드 추가 버튼 -->
        <v-sheet
            v-if="!loading"
            class="mt-2 d-flex justify-center align-center rounded-15"
            :width="$vuetify.breakpoint.mobile? '':313"
            height="125"
            style="border:1px solid #615DFA; color:#615DFA; cursor:pointer;"
            @click="searchCard()"
        >
            <v-icon size="18" class="pb-2px mr-1" color="icu_purple">mdi-plus</v-icon>
            <span style="color:#615DFA; font-weight:bold; font-size:14px;">카드 추가</span>
        </v-sheet>

        <!-- 수정 & 취소 -->
        <div
            v-if="!loading"
            class="mt-4 d-flex justify-center"
        >
            <!-- 제출 -->
            <v-btn
                width="110"
                height="42"
                dark
                depressed
                color="primary"
                class="font-weight-bold rounded-10 mr-2"
                @click="cardUpdate()"
            >
                수정
            </v-btn>

            <!-- 취소 -->
            <v-btn
                width="110"
                height="42"
                dark
                depressed
                color="#adafca"
                class="font-weight-bold rounded-10"
                @click="$emit('close')"
            >
                취소
            </v-btn>
        </div>

        <!-- 찾는 카드가 없나요? -->
        <v-sheet
            width="172"
            class="px-4 py-2 rounded-15 d-flex justify-space-between mx-auto mt-3"
            style="cursor:pointer; background:#f4f4f4; font-size:12px;"
            @click="$router.push('/board/list?board_group=너정다&board_name=카드/DB%20추가%20요청')"
        >
            <v-icon left size="16">mdi-help-circle-outline</v-icon>찾는 카드가 없나요?
        </v-sheet>

        <!-- 카드선택 > 카드목록 (dialog) -->
        <v-dialog
            v-if="!$vuetify.breakpoint.mobile"
            v-model="dialog.cardList"
            width="1040"
            content-class="rounded-15 white"
        >
            <CardList
                :isWrite="true"
                @goToDetail="goToDetail"
            />
        </v-dialog>

        <!-- 카드목록 & 선택 (bottom-sheet) (Mobile) -->
        <v-bottom-sheet
            v-if="$vuetify.breakpoint.mobile"
            v-model="dialog.cardList"
            content-class="white"
            fullscreen
        >
            <CardListMobile
                :isWrite="true"
                @goToDetail="goToDetail"
                @CardSelected="CardSelected"
            />
        </v-bottom-sheet>

        <!-- 카드선택 > 카드상세 (dialog) -->
        <v-dialog
            v-if="!$vuetify.breakpoint.mobile"
            v-model="dialog.cardDetail"
            width="1040"
            content-class="pa-4 rounded-15 white"
        >
            <CardDetail
                :id="cardDetail_id"
                :isBoardContent="false"
                @CardSelected="CardSelected"
                @Close="dialog.cardDetail = false"
                :key="componentKey"
            />
        </v-dialog>
    </v-sheet>
</template>
<script>
import CardList from '@/components/card/List'
import CardListMobile from '@/components/card/ListForMobile'
import CardDetail from '@/components/card/detailForTrade/Detail'

export default {
    components: {
        CardList,
        CardListMobile,
        CardDetail,
    },

    data: () => ({
        card_list: [],

        selectedIndex: 0,
        cardDetail_id: null,
        componentKey: 0,

        first_card_list: [],
        first_card_list_length: 0,

        loading: false,

        dialog: {
            cardList:false,
            cardDetail: false,
        },
    }),

    mounted(){
        // 카드목록 불러오기
        this.load()
    },

    methods: {
        // 카드목록 불러오기
        load(){
            this.loading = true
            this.$http.post('/api/challenge/select/card', {
                params: {
                    challenge_id : this.$route.query.id
                }
            }).then(res => {
                if(res.data.length){
                    this.card_list = res.data.map(e => {
                        let card = {...e}
                        if(card.card_nation == "en"){
                            card.card_name = card.name_en.replace(/"/g, '')
                            card.card_image = card.image_en
                        }
                        else if(card.card_nation == "kr"){
                            card.card_name = card.name_kr.replace(/"/g, '')
                            card.card_image = card.image_kr
                        }
                        else if(card.card_nation == "jp"){
                            card.card_name = card.name_jp.replace(/"/g, '')
                            card.card_image = card.image_jp
                        }
                        return card
                    })

                    this.first_card_list = JSON.parse(JSON.stringify(this.card_list))
                    this.first_card_list_length = res.data.length
                    // console.log(this.first_card_list)
                }
                this.loading = false
            })
        },

        // 카드 선택창 열기
        searchCard(index){
            if(index == undefined){
                this.selectedIndex = 99999
            }
            else{
                this.selectedIndex = index
            }
            this.dialog.cardList = true
        },

        // 카드 목록에서 카드 선택
        goToDetail(id){
            this.cardDetail_id = id
            this.dialog.cardDetail = true
            this.componentKey++
        },

        // 선택한 카드 정보
        CardSelected(card, nation){
            // console.log(card)
            // console.log(nation)

            let isCardInList = false
            if(!this.$vuetify.breakpoint.mobile){
                this.card_list.forEach(e => {
                    if (e.card_id === card.en.id && e.card_nation === nation) {
                        isCardInList = true
                    }
                })
            }else{
                this.card_list.forEach(e => {
                    if (e.card_id === card.id && e.card_nation === nation) {
                        isCardInList = true
                    }
                })
            }

            if (isCardInList) {
                alert('일치하는 카드가 이미 목록에 있습니다.')
                return
            }

            // 추가일 경우
            if(this.selectedIndex == 99999){
                // 추가
                this.card_list.push(
                    {
                        card_id: "",
                        card_image: null,
                        card_name: "",
                        card_nation: ""
                    }
                )

                // 마지막 인덱스 전달
                this.selectedIndex = this.card_list.length -1
            }

            if(!this.$vuetify.breakpoint.mobile){
                if(nation=='kr'){
                    this.card_list[this.selectedIndex].set_kr = card.kr.set_name
                    this.card_list[this.selectedIndex].card_name = card.kr.name
                    this.card_list[this.selectedIndex].card_image = card.kr.image
                }else if(nation=='en'){
                    this.card_list[this.selectedIndex].set_en = card.en.set.name
                    this.card_list[this.selectedIndex].card_name = card.en.name
                    this.card_list[this.selectedIndex].card_image = card.en.image
                }else if(nation=='jp'){
                    this.card_list[this.selectedIndex].set_jp = card.jp.set_name
                    this.card_list[this.selectedIndex].card_name = card.jp.name
                    this.card_list[this.selectedIndex].card_image = card.jp.image
                }

                this.card_list[this.selectedIndex].card_id = card.en.id
            }else{
                if(nation=='kr'){
                    this.card_list[this.selectedIndex].set_kr = card.set_kr
                    this.card_list[this.selectedIndex].card_name = card.name_kr
                    this.card_list[this.selectedIndex].card_image = card.image_kr
                }else if(nation=='en'){
                    this.card_list[this.selectedIndex].set_en = card.set_en
                    this.card_list[this.selectedIndex].card_name = card.name_en
                    this.card_list[this.selectedIndex].card_image = card.image_en
                }else if(nation=='jp'){
                    this.card_list[this.selectedIndex].set_jp = card.set_jp
                    this.card_list[this.selectedIndex].card_name = card.name_jp
                    this.card_list[this.selectedIndex].card_image = card.image_jp
                }

                this.card_list[this.selectedIndex].card_id = card.id
            }

            this.card_list[this.selectedIndex].card_nation = nation

            this.dialog.cardList = false
            this.dialog.cardDetail = false
        },

        // 카드 빼기
        subtractField(index){
            this.card_list.splice(index,1)
        },

        // 카드 순서 위로 올리기
        shiftUpField(index) {
            if (index > 0) {
                const temp = {...this.card_list[index], id: this.card_list[index].id}
                this.card_list[index] = {...this.card_list[index - 1], id: this.card_list[index].id}
                this.card_list[index - 1] = {...temp, id: this.card_list[index - 1].id}
                this.card_list = [...this.card_list]
            }
        },

        // 카드 순서 위로 올리기
        shiftDownField(index) {
            if (index < this.card_list.length - 1) {
                const temp = {...this.card_list[index], id: this.card_list[index].id}
                this.card_list[index] = {...this.card_list[index + 1], id: this.card_list[index].id}
                this.card_list[index + 1] = {...temp, id: this.card_list[index + 1].id}
                this.card_list = [...this.card_list]
            }
        },

        // 카드목록 수정
        async cardUpdate(){
            this.loading = true

            // 삭제된 카드 찾기 (로그 입력)
            for(let i=0; i<this.first_card_list.length; i++){
                if(!this.card_list.some(e => e.id === this.first_card_list[i].id)){

                    // 카드 삭제하기
                    this.$http.post('/api/challenge/delete/card/specific', {
                        params: {
                            id : this.first_card_list[i].id
                        }
                    })

                    // console.log("삭제됨")
                    this.$http.post("/api/challenge/insert/log", {
                        params: {
                            challenge_id: this.$route.query.id,
                            user_id: this.$store.state.user_id,
                            type: "minus",
                            message: this.$store.state.nickname + "님이 " + this.first_card_list[i].card_name + "("+this.first_card_list[i].card_id+")" + " 카드를 챌린지북에서 삭제했습니다",
                        }
                    })
                }
            }

            // 추가 & 변경된 카드 찾기 (로그 입력)
            for(let i=0; i<this.card_list.length; i++){
                if(
                    // 새로 추가된 것은 id가 없으므로 추가됨
                    !this.card_list[i].id
                ){
                    this.$http.post("/api/challenge/insert/log", {
                        params: {
                            challenge_id: this.$route.query.id,
                            user_id: this.$store.state.user_id,
                            type: "add",
                            message: this.$store.state.nickname + "님이 " + this.card_list[i].card_name + "("+this.card_list[i].card_id+")" + " 카드를 챌린지북에 추가했습니다",
                        }
                    })
                    // console.log(this.card_list[i].card_name + "추가됨")
                }
                else if(
                    // 기존 카드(id가 있는) 중에 바뀐 카드랑 완전히 일치하는게 하나라도 있는지
                    this.card_list[i].id
                    && 
                        this.first_card_list.some(
                            e =>
                            e.id == this.card_list[i].id
                            &&
                            e.card_id == this.card_list[i].card_id
                            &&
                            e.card_nation == this.card_list[i].card_nation
                        )
                ){
                    // console.log("완전일치 (PASS)")
                }
                else if(
                        // 기존 카드(id가 있는)와 id가 일치하면서 card_id랑 card_nation이 다른애가 하나라도 있는지
                        this.card_list[i].id
                    && 
                        this.first_card_list.some(
                            e =>
                                e.id == this.card_list[i].id
                                &&
                                (
                                    e.card_id != this.card_list[i].card_id
                                ||
                                    e.card_nation != this.card_list[i].card_nation
                                )
                        )
                ){
                    // 바뀐 카드 찾기
                    let updated_list = this.first_card_list.filter(
                        e =>
                            e.id == this.card_list[i].id
                            &&
                            (
                                e.card_id != this.card_list[i].card_id
                            ||
                                e.card_nation != this.card_list[i].card_nation
                            )
                    )

                    this.$http.post("/api/challenge/insert/log", {
                        params: {
                            challenge_id: this.$route.query.id,
                            user_id: this.$store.state.user_id,
                            type: "update",
                            message: this.$store.state.nickname + "님이 " + updated_list[0].card_name + "("+updated_list[0].card_id+")" + " 카드를 " + this.card_list[i].card_name + "("+this.card_list[i].card_id+")" + " 카드로 변경했습니다",

                        }
                    })
                    // console.log("변경됨")
                }
            }

            // 기존 카드목록 줄 수와 동일한 줄까지는 UPDATE
            let card_list_for_update = this.card_list.slice(0, this.first_card_list_length)

            // 처음과 완전히 일치하는 요소들은 제외
            card_list_for_update = card_list_for_update.filter((updateItem, index) => 
                !(this.first_card_list[index] && 
                this.first_card_list[index].id === updateItem.id && 
                this.first_card_list[index].card_id === updateItem.card_id && 
                this.first_card_list[index].card_nation === updateItem.card_nation)
            )

            // UPDATE
            for(let i=0; i<card_list_for_update.length; i++){
                await this.$http.post('/api/challenge/update/card', {
                    params: {
                        id : card_list_for_update[i].id,
                        challenge_id: this.$route.query.id,
                        card_id: card_list_for_update[i].card_id,
                        card_nation: card_list_for_update[i].card_nation,
                    }
                })
            }

            // 기존 카드목록 줄 수 이상의 줄부터는 INSERT
            let card_list_for_insert = this.card_list.slice(this.first_card_list_length)

            // INSERT
            for(let i=0; i<card_list_for_insert.length; i++){
                await this.$http.post('/api/challenge/insert/card', {
                    params: {
                        challenge_id: this.$route.query.id,
                        card_id: card_list_for_insert[i].card_id,
                        card_nation: card_list_for_insert[i].card_nation
                    }
                })
            }

            this.load()
            this.$emit('close')
        },

        // 전체 삭제
        deleteSubmit(){
            if(confirm("카드를 전체 삭제하시겠습니까?")){
                this.$http.post("/api/challenge/delete/card", {
                    params: {
                        challenge_id: this.$route.query.id,
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.$http.post("/api/challenge/insert/log", {
                            params: {
                                challenge_id: this.$route.query.id,
                                user_id: this.$store.state.user_id,
                                type: "minus",
                                message: this.$store.state.nickname + "님이 전체 카드를 챌린지북에서 삭제했습니다",
                            }
                        }).then((res) => {
                            if(res.data.affectedRows){
                                this.load()
                                this.$emit('delete_all')
                            }
                        })
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>