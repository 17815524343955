import axios from 'axios'

const GradeCheck = {
    compare: async (user_id, type, variance) => {

        // 사용자인지 관리자인지 확인 & 현재등급 확인(가입 일수 용)
        let currentGrade = 0
        let checkType = await axios.post('/api/user/select/specific', {
            params: {
                user_id: user_id
            }
        }).then((res) => {
            if(res.data[0].type!='사용자'){
                return false
            }else{
                currentGrade = res.data[0].grade
                return true
            }
        })

        console.log(currentGrade)

        // 관리자일 경우 스킵
        if(!checkType){
            return true;
        }

        console.log("HI")

        //  행동 이전 등급
        let beforeGrade = await axios.post('/api/user/select/specific/activity', {
            params: {
                user_id: user_id
            }
        }).then(res => {
            if(res.data.length)
            {
                console.log(res.data)
                let post_count = res.data[0].post_count
                let comment_count = res.data[0].comment_count + res.data[0].sub_comment_count + res.data[0].deck_review_count
                let fromCreated = Math.ceil((new Date(Date.now()) - new Date(res.data[0].created))/(24*3600*1000))

                console.log(post_count)
                console.log(comment_count)
                console.log(fromCreated)

                let post_grade = 0

                if ((1 <= post_count) && (post_count < 5)){
                    post_grade = 1
                }else if((5 <= post_count) && (post_count < 10)){
                    post_grade = 2
                }else if((10 <= post_count) && (post_count < 15)){
                    post_grade = 3
                }else if((15 <= post_count) && (post_count < 20)){
                    post_grade = 4
                }else if((20 <= post_count) && (post_count < 30)){
                    post_grade = 5
                }else if((30 <= post_count) && (post_count < 50)){
                    post_grade = 6
                }else if((50 <= post_count) && (post_count < 70)){
                    post_grade = 7
                }else if((70 <= post_count) && (post_count < 100)){
                    post_grade = 8
                }else if((100 <= post_count) && (post_count < 150)){
                    post_grade = 9
                }else if((150 <= post_count) && (post_count < 250)){
                    post_grade = 10
                }else if((250 <= post_count) && (post_count < 400)){
                    post_grade = 11
                }else if(post_count >= 400){
                    post_grade = 12
                }

                console.log("post_grade", post_grade)

                let comment_grade = 0

                if ((3 <= comment_count) && (comment_count < 10)){
                    comment_grade = 1
                }else if((10 <= comment_count) && (comment_count < 20)){
                    comment_grade = 2
                }else if((20 <= comment_count) && (comment_count < 30)){
                    comment_grade = 3
                }else if((30 <= comment_count) && (comment_count < 60)){
                    comment_grade = 4
                }else if((60 <= comment_count) && (comment_count < 80)){
                    comment_grade = 6
                }else if((80 <= comment_count) && (comment_count < 150)){
                    comment_grade = 7
                }else if((150 <= comment_count) && (comment_count < 200)){
                    comment_grade = 8
                }else if((200 <= comment_count) && (comment_count < 280)){
                    comment_grade = 9
                }else if((280 <= comment_count) && (comment_count < 370)){
                    comment_grade = 10
                }else if((370 <= comment_count) && (comment_count < 500)){
                    comment_grade = 11
                }else if(comment_count >= 500){
                    comment_grade = 12
                }

                console.log("comment_grade", comment_grade)

                let fromCreated_grade = 0

                if(fromCreated < 3){
                    fromCreated_grade = 1
                }else if((3 <= fromCreated) && (fromCreated < 5)){
                    fromCreated_grade = 2
                }else if((5 <= fromCreated) && (fromCreated < 10)){
                    fromCreated_grade = 3
                }else if((10 <= fromCreated) && (fromCreated < 30)){
                    fromCreated_grade = 4
                }else if((30 <= fromCreated) && (fromCreated < 50)){
                    fromCreated_grade = 6
                }else if((50 <= fromCreated) && (fromCreated < 70)){
                    fromCreated_grade = 7
                }else if((70 <= fromCreated) && (fromCreated < 100)){
                    fromCreated_grade = 8
                }else if((100 <= fromCreated) && (fromCreated < 150)){
                    fromCreated_grade = 9
                }else if((150 <= fromCreated) && (fromCreated < 200)){
                    fromCreated_grade = 10
                }else if((200 <= fromCreated) && (fromCreated < 365)){
                    fromCreated_grade = 11
                }else if(fromCreated > 365){
                    fromCreated_grade = 12
                }

                console.log("fromCreated_grade", fromCreated_grade)
                console.log("Math.min", Math.min(post_grade, comment_grade, fromCreated_grade))

                return Math.min(post_grade, comment_grade, fromCreated_grade)
            }
        })

        console.log("beforeGrade", beforeGrade);

        //  행동 이후 등급
        let afterGrade = await axios.post('/api/user/select/specific/activity', {
            params: {
                user_id: user_id
            }
        }).then(res => {
            if(res.data.length)
            {
                let post_count = res.data[0].post_count
                let comment_count = res.data[0].comment_count + res.data[0].sub_comment_count + res.data[0].deck_review_count
                let fromCreated = Math.ceil((new Date(Date.now()) - new Date(res.data[0].created))/(24*3600*1000))

                if(type == 'post' && variance > 0 ){
                    post_count += 1
                }else if(type == 'post' && variance < 0){
                    post_count -= 1
                }else if(type == 'comment' && variance > 0 ){
                    comment_count += 1
                }else if(type == 'comment' && variance < 0){
                    comment_count -= 1
                }else if(type == 'fromCreated' && variance > 0){
                    fromCreated += 1
                }else if(type == 'fromCreated' && variance < 0){
                    fromCreated -= 1
                }


                let post_grade = 0

                if ((1 <= post_count) && (post_count < 5)) {
                    post_grade = 1
                } else if ((5 <= post_count) && (post_count < 10)) {
                    post_grade = 2
                } else if ((10 <= post_count) && (post_count < 15)) {
                    post_grade = 3
                } else if ((15 <= post_count) && (post_count < 20)) {
                    post_grade = 4
                } else if ((20 <= post_count) && (post_count < 30)) {
                    post_grade = 5
                } else if ((30 <= post_count) && (post_count < 50)) {
                    post_grade = 6
                } else if ((50 <= post_count) && (post_count < 70)) {
                    post_grade = 7
                } else if ((70 <= post_count) && (post_count < 100)) {
                    post_grade = 8
                }else if((100 <= post_count) && (post_count < 150)){
                    post_grade = 9
                }else if((150 <= post_count) && (post_count < 250)){
                    post_grade = 10
                }else if((250 <= post_count) && (post_count < 400)){
                    post_grade = 11
                }else if(post_count >= 400){
                    post_grade = 12
                }

                console.log("post_grade", post_grade)

                let comment_grade = 0

                if ((3 <= comment_count) && (comment_count < 10)) {
                    comment_grade = 1
                } else if ((10 <= comment_count) && (comment_count < 20)) {
                    comment_grade = 2
                } else if ((20 <= comment_count) && (comment_count < 30)) {
                    comment_grade = 3
                } else if ((30 <= comment_count) && (comment_count < 60)) {
                    comment_grade = 4
                } else if ((60 <= comment_count) && (comment_count < 80)) {
                    comment_grade = 6
                } else if ((80 <= comment_count) && (comment_count < 150)) {
                    comment_grade = 7
                } else if ((150 <= comment_count) && (comment_count < 200)) {
                    comment_grade = 8
                }else if((200 <= comment_count) && (comment_count < 280)){
                    comment_grade = 9
                }else if((280 <= comment_count) && (comment_count < 370)){
                    comment_grade = 10
                }else if((370 <= comment_count) && (comment_count < 500)){
                    comment_grade = 11
                }else if(comment_count >= 500){
                    comment_grade = 12
                }

                console.log("comment_grade", comment_grade)

                let fromCreated_grade = 0

                if(fromCreated < 3){
                    fromCreated_grade = 1
                }else if((3 <= fromCreated) && (fromCreated < 5)){
                    fromCreated_grade = 2
                }else if((5 <= fromCreated) && (fromCreated < 10)){
                    fromCreated_grade = 3
                }else if((10 <= fromCreated) && (fromCreated < 30)){
                    fromCreated_grade = 5
                }else if((30 <= fromCreated) && (fromCreated < 50)){
                    fromCreated_grade = 6
                }else if((50 <= fromCreated) && (fromCreated < 70)){
                    fromCreated_grade = 7
                }else if((70 <= fromCreated) && (fromCreated < 100)){
                    fromCreated_grade = 8
                }else if((100 <= fromCreated) && (fromCreated < 150)){
                    fromCreated_grade = 9
                }else if((150 <= fromCreated) && (fromCreated < 200)){
                    fromCreated_grade = 10
                }else if((200 <= fromCreated) && (fromCreated < 365)){
                    fromCreated_grade = 11
                }else if(fromCreated > 365){
                    fromCreated_grade = 12
                }

                console.log("fromCreated_grade", fromCreated_grade)
                console.log("Math.min", Math.min(post_grade, comment_grade, fromCreated_grade))

                return Math.min(post_grade, comment_grade, fromCreated_grade)
            }
        })


        if(beforeGrade > currentGrade){
            beforeGrade = currentGrade
        }

        console.log("afterGrade", afterGrade);

        // 등급 비교 및 Alert
        if(type=='post'){
            if(variance > 0){
                if(beforeGrade < afterGrade){
                    axios.post('/api/user/update/grade', {
                        params: {
                            grade: afterGrade,
                            user_id: user_id
                        }
                    }).then(() => {
                        alert("글 작성 또는 가입후 일수에 따라 다음 레벨인 '레벨"+afterGrade+"'로 등급업하였습니다.")
                    })
                }
            }else if(variance < 0){
                if(beforeGrade > afterGrade){
                    axios.post('/api/user/update/grade', {
                        params: {
                            grade: afterGrade,
                            user_id: user_id
                        }
                    }).then(() => {
                        alert("글 삭제로 이전 레벨인 '레벨"+afterGrade+"'로 변경되었습니다.")
                    })
                }
            }
        }else if(type=='comment'){
            if(variance > 0){
                if(beforeGrade < afterGrade){
                    axios.post('/api/user/update/grade', {
                        params: {
                            grade: afterGrade,
                            user_id: user_id
                        }
                    }).then(() => {
                        alert("댓글 작성 또는 가입후 일수에 따라 다음 레벨인 '레벨"+afterGrade+"'로 등급업하였습니다.")
                    })
                }
            }else if(variance < 0){
                if(beforeGrade > afterGrade){
                    axios.post('/api/user/update/grade', {
                        params: {
                            grade: afterGrade,
                            user_id: user_id
                        }
                    }).then(() => {
                        alert("댓글 삭제로 이전 레벨인 '레벨"+afterGrade+"'로 변경되었습니다.")
                    })
                }
            }
        }else if(type=='fromCreated'){
            if(beforeGrade < afterGrade){
                axios.post('/api/user/update/grade', {
                    params: {
                        grade: afterGrade,
                        user_id: user_id
                    }
                }).then(() => {
                    alert("가입 후 일수에 따라 다음 레벨인 '레벨"+afterGrade+"'로 등급업하였습니다.")
                })
            }
        }
    }
}

export default GradeCheck