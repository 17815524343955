
<template>
    <div>
        <!-- 상단 -->
        <DefaultHeader />

        <!-- 게시글 목록 -->
        <v-sheet
            class="pa-4 rounded-10"
            style="position:relative;"
        >
            <!-- 카테고리, 제목 -->
            <div class="d-flex">
                <!-- 카테고리 -->
                <v-select
                    class="rounded-10 mr-2 shrink font-weight-bold"
                    height="48"
                    style="width:220px;"
                    hide-details
                    dense
                    outlined
                    placeholder="카테고리"
                    :items="category_list"
                    v-model="category"
                    :menu-props="{ bottom: true, offsetY: true }"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 제목 -->
                <v-text-field
                    class="rounded-10 font-weight-bold"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="제목을 입력해주세요"
                    v-model="title"
                ></v-text-field>
            </div>

            <div
                style="position:absolute; top:-60px; right:20px;"
            >
                <!-- 임시저장 -->
                <v-btn
                    height="40"
                    width="98"
                    class="mr-2 font-weight-bold rounded-10"
                    style="font-size:14px;"
                    color="primary"
                    dark
                    depressed
                    @click="save()"
                >
                    임시저장
                </v-btn>

                <!-- 불러오기 -->
                <v-btn
                    height="40"
                    width="98"
                    class="font-weight-bold rounded-10"
                    style="font-size:14px;"
                    color="primary"
                    dark
                    depressed
                    @click="saveImport()"
                >
                    불러오기
                </v-btn>
            </div>

            <!-- 본문 -->
            <TipTapWriter
                v-if="options.content"
                class="tiptap_style mt-2"
                :options="options"
                @emit_count="import_content_count"
                :key="componentKey"
            />

            <!-- 파일 첨부 (비활성화) -->
            <!-- <v-sheet
                v-if="attachment"
                class="my-2"
            >
                <v-btn
                    depressed
                    class="py-6 rounded-10"
                >
                    <v-icon size="20" class="mr-2" color="primary">mdi-file</v-icon>
                    {{attachment}}
                    <v-icon size="20" class="ml-4" color="primary" @click="attachment = ''">mdi-close</v-icon>
                </v-btn>
            </v-sheet>

            <v-file-input
                v-if="!attachment"
                outlined
                show-size
                hide-details
                prepend-icon
                color="blue"
                class="rounded-lg mt-2"
                label="파일 첨부"
                v-model="uploadFile"
            >
                <template v-slot:prepend-inner>
                    <v-icon class="mr-1" color="blue">mdi-file-chart-outline</v-icon>
                </template>
            </v-file-input> -->

            <!-- 공지사항 여부 -->
            <v-sheet v-if="$store.state.type=='관리자'" class="px-1 mt-3 mb-4">
                <v-checkbox
                    class="pa-0 ma-0"
                    dense
                    hide-details
                    color="primary"
                    label="이 글을 공지사항으로 설정하시겠습니까?"
                    v-model="notice"   
                ></v-checkbox>
            </v-sheet>

            <!-- 버튼 -->
            <v-sheet class="d-flex justify-center pa-1 mt-2">
                <v-btn
                    height="49"
                    width="140"
                    class="mx-2 font-weight-bold rounded-10"
                    style="font-size:16px;"
                    color="primary"
                    dark
                    depressed
                    @click="submit()"
                >
                    수정하기
                </v-btn>
                <v-btn
                    height="49"
                    width="140"
                    class="mx-2 font-weight-bold rounded-10"
                    style="font-size:16px;"
                    color="#ADAFCA"
                    dark
                    depressed
                    @click="cancel()"
                >
                    취소하기
                </v-btn>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import DefaultHeader from "@/views/board/components/DefaultHeader"
import TipTapWriter from "@/components/tiptap/Writer"

export default {
    components: {
        DefaultHeader,
        TipTapWriter
    },

    data: () => ({
        category: '',
        title: '',
        attachment: '',

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        uploadFile: {},

        category_list: [],
        notice: false,

        content_count:1,

        componentKey: 0
    }),

    mounted(){
        // 카테고리 select_list
        this.$http.post('/api/board/list/category', {
            params: {
                board_name : this.$route.query.board_name
            }
        }).then(res => {
            this.category_list = res.data.map(a => a.title)
        })
        
        // 게시글 정보 불러오기
        this.$http.post('/api/board/select/specific', {
            params: {
                board_name: this.$route.query.board_name,
                id: this.$route.query.id
            }
        }).then((res) => {
            this.post = res.data[0]
            this.category = res.data[0].category
            this.title = res.data[0].title
            this.attachment = res.data[0].attachment
            this.options.content = res.data[0].content
            this.notice = res.data[0].type=='공지'

            if(res.data[0].writer != this.$store.state.user_id && this.$store.state.type != "관리자"){
                alert("잘못된 접근입니다.")
                this.$router.go(-1);
            }
        })
    },

    methods: {
        // 임시저장
        save(){
            if(confirm("이전에 임시저장하신 게시글이 있다면 덮어쓰워집니다.\n임시저장하시겠습니까?")){
                if(!this.title.length)
                {
                    alert("제목을 입력해주세요")
                }
                else if(!this.content_count)
                {
                    alert("내용을 입력해주세요")
                }
                else
                {
                    this.$http.post('/api/board/save/insert', {
                        params: {
                            writer: this.$store.state.user_id,
                            title: this.title,
                            content: this.options.content,
                        }
                    }).then((res) => {
                        if(res.data.affectedRows){
                            alert("임시저장되었습니다.")
                        }
                    })
                }
            }
        },

        // 불러오기
        saveImport(){
            if(confirm("작성하고 계신 내용이 덮어씌워집니다.\n임시저장을 불러오시겠습니까?")){
                this.$http.post("/api/board/save/import", {
                    params: {
                        user_id: this.$store.state.user_id,
                    }
                }).then((res) => {
                    if(!res.data.length){
                        alert("임시저장한 게시글이 없습니다.")
                    }else{
                        this.title = res.data[0].title
                        this.options.content = res.data[0].content
                        this.componentKey++
                    }
                })
            }
        },
        
        // 내용 Count 값 import
        import_content_count(count){
            this.content_count = count
        },

        cancel(){
            if(confirm("지금 작성하고 계신 글이 저장되지 않을 수 있습니다.\n페이지를 이동하시겠습니까?")){
                this.$router.go(-1)
            }
        },

        submit(){
            if(!this.title.length){
                alert("제목을 입력해주세요")
            }else if(!this.content_count){
                alert("내용을 입력해주세요")
            }else{
                if(this.uploadFile.name)
                {
                    var file = this.uploadFile
                    var formData = new FormData()
                    formData.append("image", file)
                    console.log(formData)
                    this.$http.post('/api/image/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        params: {
                            type: "attachment"
                        }
                    }).then((res_attachment) => {
                        this.$http.post('/api/board/update/submit', {
                            params: {
                                category: this.category,
                                title: this.title,
                                content: this.options.content,
                                board_name: this.$route.query.board_name,
                                type: this.notice? '공지':'일반글',
                                attachment: res_attachment.data,
                                id: this.$route.query.id
                            }
                        }).then((res) => {
                            // DB 알림
                            this.$dbAlarm(
                                "커뮤니티 - 게시글",
                                this.$store.state.nickname,
                                this.$store.state.user_id
                            )
                            
                            this.$emit('submit')
                            this.$router.push("/board/read?board_group="+this.$route.query.board_group+"&board_name="+this.$route.query.board_name+"&id="+this.$route.query.id)
                        })
                    })
                }
                else
                {
                    this.$http.post('/api/board/update/submit', {
                        params: {
                            category: this.category,
                            title: this.title,
                            content: this.options.content,
                            board_name: this.$route.query.board_name,
                            type: this.notice? '공지':'일반글',
                            attachment: this.attachment,
                            id: this.$route.query.id
                        }
                    }).then((res) => {
                        // DB 알림
                        this.$dbAlarm(
                            "커뮤니티 - 게시글",
                            this.$store.state.nickname,
                            this.$store.state.user_id
                        )
                        
                        this.$emit('submit')
                        this.$router.push("/board/read?board_group="+this.$route.query.board_group+"&board_name="+this.$route.query.board_name+"&id="+this.$route.query.id)
                    })
                }
            }
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>