<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            width="780"
            class="rounded-15 px-16 pt-8 pb-4"
            color="#F8F7FB"
            style="border:2px solid #615DFA"
        >
            <!-- 제목 -->
            <p class="mb-5 font-weight-bold text-center" style="font-size:24px;">
                챌린지북 생성
            </p>

            <!-- 챌린지북 이름 -->
            <v-sheet class="d-flex align-center py-2 px-6 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">챌린지북 이름</span>
                <v-text-field
                    style="max-width:360px; margin-left:80px;"
                    placeholder="이름을 입력해주세요"
                    solo
                    flat
                    hide-details
                    v-model="title"
                ></v-text-field>
            </v-sheet>

            <!-- 공개여부 -->
            <v-sheet class="d-flex align-center py-2 px-6 rounded-10 mt-2">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">공개여부</span>
                <v-select
                    style="max-width:240px; margin-left:160px;"
                    placeholder="공개여부를 선택해주세요"
                    dense
                    hide-details
                    solo
                    flat
                    :items="select_list.is_open"
                    v-model="is_open"
                >
                    <template v-slot:selection="{ item }">
                        <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                    </template>
                    <template v-slot:append>
                        <v-icon color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </v-sheet>

            <!-- 안내문구 -->
            <p class="text-center font-weight-bold my-2 primary--text" style="font-size:12px;">
                공개 여부는 한 번 선택하면 수정할 수 없습니다.
            </p>

            <!-- 커버 이미지 카드 -->
            <v-sheet class="d-flex align-center py-2 px-6 mt-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">커버 이미지 카드</span>
                <div class="flex-grow-1">
                    <v-img
                        width="56"
                        height="56"
                        class="rounded-circle grey lighten-4"
                        style="cursor:pointer; margin:0 auto;"
                        :src="image"
                        @click="dialog.cardList = true"
                    ></v-img>
                </div>
            </v-sheet>

            <!-- 설명 -->
            <v-sheet class="py-4 px-6 mt-3 rounded-10">
                <v-icon size="8" color="#ADAFCA" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">설명</span>
                <v-textarea
                    class="no_under"
                    rows="4"
                    hide-details
                    v-model="content"
                    placeholder="내용을 입력해주세요"
                ></v-textarea>
            </v-sheet>

            <!-- 버튼 -->
            <div class="mt-2 pa-2 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="80"
                    height="32"
                    dark
                    depressed
                    color="#615DFA"
                    class="font-weight-bold rounded-10 mr-2"
                    @click="submit()"
                >
                    생성
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="80"
                    height="32"
                    depressed
                    color="white"
                    class="font-weight-bold rounded-10"
                    @click="$emit('close')"
                >
                    취소
                </v-btn>
            </div>

            <!-- 카드선택 > 카드목록 (dialog) -->
            <v-dialog
                v-if="!$vuetify.breakpoint.mobile"
                v-model="dialog.cardList"
                width="1040"
                content-class="rounded-15 white"
            >
                <CardList
                    :isWrite="true"
                    @goToDetail="goToDetail"
                />
            </v-dialog>

            <!-- 카드선택 > 카드상세 (dialog) -->
            <v-dialog
                v-if="!$vuetify.breakpoint.mobile"
                v-model="dialog.cardDetail"
                width="1040"
                content-class="pa-4 rounded-15 white"
            >
                <CardDetail
                    :id="cardDetail_id"
                    :isBoardContent="false"
                    @CardSelected="CardSelected"
                    @Close="dialog.cardDetail = false"
                    :key="componentKey"
                />
            </v-dialog>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="rounded-15 px-3 pt-4 pb-2"
            color="#F8F7FB"
            style="border:2px solid #615DFA"
        >
            <!-- 제목 -->
            <p class="mb-3 font-weight-bold text-center" style="font-size:16px;">
                챌린지북 생성
            </p>

            <!-- 챌린지북 이름 -->
            <v-sheet class="d-flex align-center py-2 px-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:10px; font-weight:bold; width:100px;">챌린지북 이름</span>
                <v-text-field
                    placeholder="이름을 입력해주세요"
                    style="font-size:10px;"
                    solo
                    flat
                    hide-details
                    v-model="title"
                ></v-text-field>
            </v-sheet>

            <!-- 공개여부 -->
            <v-sheet class="d-flex align-center py-2 px-3 rounded-10 mt-2">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:10px; font-weight:bold; width:100px;">공개여부</span>
                <v-select
                    placeholder="공개여부를 선택해주세요"
                    style="font-size:10px;"
                    dense
                    hide-details
                    solo
                    flat
                    :items="select_list.is_open"
                    v-model="is_open"
                >
                    <template v-slot:selection="{ item }">
                        <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                    </template>
                    <template v-slot:append>
                        <v-icon color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </v-sheet>

            <!-- 안내문구 -->
            <p class="text-center my-1 primary--text" style="font-size:8px;">
                공개 여부는 한 번 선택하면 수정할 수 없습니다.
            </p>

            <!-- 커버 이미지 카드 -->
            <v-sheet class="d-flex align-center py-2 px-3 mt-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:10px; font-weight:bold; width:100px;">커버 이미지 카드</span>
                <div class="flex-grow-1">
                    <v-img
                        width="40"
                        height="40"
                        class="rounded-circle grey lighten-4"
                        style="cursor:pointer; margin:0 auto;"
                        :src="image"
                        @click="dialog.cardList = true"
                    ></v-img>
                </div>
            </v-sheet>

            <!-- 설명 -->
            <v-sheet class="py-4 px-3 mt-3 rounded-10">
                <v-icon size="8" color="#ADAFCA" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:10px; font-weight:bold; width:100px;">설명</span>
                <v-textarea
                    class="no_under"
                    style="font-size:10px;"
                    rows="2"
                    hide-details
                    v-model="content"
                    placeholder="내용을 입력해주세요"
                ></v-textarea>
            </v-sheet>

            <!-- 버튼 -->
            <div class="mt-2 pa-2 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="80"
                    height="30"
                    small
                    dark
                    depressed
                    color="#615DFA"
                    class="font-weight-bold rounded-10 mr-2"
                    @click="submit()"
                >
                    생성
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="80"
                    height="30"
                    small
                    depressed
                    color="white"
                    class="font-weight-bold rounded-10"
                    @click="$emit('close')"
                >
                    취소
                </v-btn>
            </div>

            <!-- 카드목록 & 선택 (bottom-sheet) (Mobile) -->
            <v-bottom-sheet
                v-if="$vuetify.breakpoint.mobile"
                v-model="dialog.cardList"
                content-class="white"
                fullscreen
            >
                <CardListMobile
                    :isWrite="true"
                    @goToDetail="goToDetail"
                    @CardSelected="CardSelected"
                />
            </v-bottom-sheet>
        </v-sheet>
    </div>
</template>
<script>
import CardList from '@/components/card/List'
import CardListMobile from '@/components/card/ListForMobile'
import CardDetail from '@/components/card/detailForTrade/Detail'

export default {
    components: {
        CardList,
        CardListMobile,
        CardDetail
    },

    data: () => ({
        select_list: {
            is_open: [
                "공개",
                "비공개"
            ]
        },

        title: "",
        image_name: "",
        image: "",
        is_open: "공개",
        content: "",

        dialog: {
            cardList:false,
            cardDetail: false,
        },

        cardDetail_id: null,

        componentKey: 0

    }),

    methods: {
        // 카드 목록에서 카드 선택
        goToDetail(id){
            this.cardDetail_id = id
            this.dialog.cardDetail = true
            this.componentKey++
        },

        // 선택한 카드 정보
        CardSelected(card, nation){
            if(!this.$vuetify.breakpoint.mobile){
                if(nation=='kr'){
                    this.image_name = card.kr.image
                    this.image = "/upload/card_kr_image/" + card.kr.image
                }
                else if(nation=='en'){
                    this.image_name = card.en.image
                    this.image = "/upload/card_en_image/" + card.en.image
                }
                else if(nation=='jp'){
                    this.image_name = card.jp.image
                    this.image = "/upload/card_jp_image/" + card.jp.image
                }
            }else{
                if(nation=='kr'){
                    this.image = "/upload/card_kr_image/" + card.image_kr
                }
                else if(nation=='en'){
                    this.image = "/upload/card_en_image/" + card.image_en
                }
                else if(nation=='jp'){
                    this.image = "/upload/card_jp_image/" + card.image_jp
                }
            }

            this.dialog.cardList = false
            this.dialog.cardDetail = false
        },

        // 제출
        submit(){
            if(this.title == ""){
                alert("챌린지북 이름을 입력해주세요")
            }
            else if(this.image == ""){
                alert("커버 이미지 카드를 선택해주세요")
            }
            else if(this.content == ""){
                alert("설명을 입력해주세요")
            }
            else{
                this.$http.post("/api/challenge/insert", {
                    params: {
                        title: this.title,
                        writer: this.$store.state.user_id,
                        is_open: this.is_open == "공개"? true : false,
                        image: this.image,
                        content: this.content
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        this.$http.post("/api/challenge/insert/log", {
                            params: {
                                challenge_id: res.data.insertId,
                                user_id: this.$store.state.user_id,
                                type: "create",
                                message: this.$store.state.nickname+"님이 챌린지북을 생성했습니다",

                            }
                        }).then((res) => {
                            if(res.data.affectedRows){
                                alert("챌린지북이 생성되었습니다.")
                                this.$emit("created")
                            }
                        })
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>